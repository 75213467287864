import React, { Fragment, useContext, useState } from 'react';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useMutation, useQuery } from 'react-query';
import { IsLoadingResponse, ToastMessage } from '../../../../utils/Helper';
import {
   Submit,
   TYPE_FELDS,
   Store_,
   SLOT_OFF_TIME_DROP,
   Slot,
   SlotOfType,
   OffDate,
   SLOT_OFF_TIME
} from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { createDeliveryOffDaySchema } from '../../../../utils/validationSchema';
import {
   Create_Delivery_Off_Day,
   Put_Update_Delivery_Off_Day
} from '../../../../Apis/deliveryOffDayApi/DeliveryOffDayApi';
import { get_Store } from '../../../../Apis/storeApi/StoreApi';
import { Calendar } from 'primereact/calendar';
import { get_Delivery_Slot } from '../../../../Apis/deliverySlotApi/DeliverySlotApi';
import DeliveryOffDayContext from '../../../../context/DeliveryOffDayContext/DeliveryOffDayContext';

const DeliveryOffDayCreateFormPopup = (props) => {
   const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } = useContext(DeliveryOffDayContext);
   const { refetch, emptyField } = props;
   const [filterDrop, setFilterDrop] = useState({
      storeFilter: '',
      slotName: ''
   });

   /**
    *
    * @param {event } e
    * create delivery off day & update delivery off day
    */

   const { mutate: createDeliveryOffDay, isLoading: createDeliveryOffDayLoader } = useMutation(
      (formData) => Create_Delivery_Off_Day(formData),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               refetch();
               setdialogs(false);
               emptyField();
               setErrors({});
               ToastMessage(response.status, response.data.message);
            }
         }
      }
   );

   const { mutate: updateDeliveryOffDay, isLoading: updateDeliveryOffDayLoader } = useMutation(
      (formData) => Put_Update_Delivery_Off_Day(formData, id),
      {
         onSuccess: (response) => {
            refetch();
            setdialogs(false);
            emptyField();
            setErrors({});
            ToastMessage(response.status, response.data.message);
         }
      }
   );

   const handleDeliveryOffDayCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createDeliveryOffDaySchema({
            slotOfType: formData.slotOffType?.code
         });
         await schema.validate(formData, { abortEarly: false });
         if (id !== -1 && isEdit === false) {
            let newData = {
               ...formData,
               storeId: formData.storeId?.code || '',
               slotOffType: formData.slotOffType?.code || '',
               offDate: new Date(formData.offDate) || ''
            };
            if (formData.slotId?.code) {
               newData.slotId = formData.slotId?.code;
            }
            updateDeliveryOffDay(newData);
         } else {
            let newData = {
               ...formData,
               storeId: formData.storeId?.code || '',
               slotOffType: formData.slotOffType?.code || '',
               offDate: new Date(formData.offDate) || ''
            };
            if (formData.slotId?.code) {
               newData.slotId = formData.slotId?.code;
            }
            createDeliveryOffDay(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);
         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeDeliveryOffDay = (e) => {
      const { name, value } = e.target;
      if (name === 'slotOffType' && value?.code === SLOT_OFF_TIME.ALL) {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value,
               slotId: null
            };
         });
      } else {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value
            };
         });
      }
   };

   let paramsObj = {
      showAll: true,
      search: { name: filterDrop.storeFilter }
   };
   let { data: storeDrop } = useQuery(
      ['getStoreDropdownInDeliveryOff', paramsObj],
      async () => await get_Store(paramsObj),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false
      }
   );

   let paramsObjDeliverySlot = {
      showAll: true,
      search: { slotName: filterDrop.slotName }
   };
   let { data: DeliverySlotDrop } = useQuery(
      ['getDeliverySlotDropdownInDeliveryOff', paramsObjDeliverySlot],
      async () => await get_Delivery_Slot(paramsObjDeliverySlot),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false
      }
   );

   return (
      <Fragment>
         <IsLoadingResponse isLoading={createDeliveryOffDayLoader || updateDeliveryOffDayLoader} />
         <Row>
            <Col sm='12'>
               <Card>
                  <CardBody>
                     <Form className='needs-validation' onSubmit={(e) => handleDeliveryOffDayCreateAndUpdate(e)}>
                        <Row>
                           <Col md='4 mb-4'>
                              <FormDataFeild
                                 label={SlotOfType}
                                 name='slotOffType'
                                 id='slotOffType'
                                 placeholder={SlotOfType}
                                 value={formData.slotOffType}
                                 handleChange={handleChangeDeliveryOffDay}
                                 option={SLOT_OFF_TIME_DROP}
                                 dropdown={true}
                                 filter={false}
                              />
                              {errors.slotOffType && <span className='error-validation'>{errors.slotOffType}</span>}
                           </Col>
                           <Col md='4 mb-4'>
                              <FormDataFeild
                                 label={Store_}
                                 name='storeId'
                                 id='storeId'
                                 placeholder={Store_}
                                 value={formData.storeId}
                                 handleChange={handleChangeDeliveryOffDay}
                                 option={storeDrop?.payload?.data?.map((elem) => {
                                    return {
                                       name: elem?.name,
                                       code: elem?.storeId
                                    };
                                 })}
                                 dropdown={true}
                                 search={(e) => {
                                    setFilterDrop((prev) => {
                                       return {
                                          ...prev,
                                          storeFilter: e.filter
                                       };
                                    });
                                 }}
                              />
                              {errors.storeId && <span className='error-validation'>{errors.storeId}</span>}
                           </Col>
                           {formData.slotOffType?.code === SLOT_OFF_TIME.SPECIFIC && (
                              <Col md='4 mb-4'>
                                 <FormDataFeild
                                    label={Slot}
                                    name='slotId'
                                    id='slotId'
                                    placeholder={Slot}
                                    value={formData.slotId}
                                    handleChange={handleChangeDeliveryOffDay}
                                    option={DeliverySlotDrop?.payload?.data?.map((elem) => {
                                       return {
                                          name: elem?.slotName,
                                          code: elem?.slotId
                                       };
                                    })}
                                    dropdown={true}
                                    search={(e) => {
                                       setFilterDrop((prev) => {
                                          return {
                                             ...prev,
                                             slotName: e.filter
                                          };
                                       });
                                    }}
                                 />
                                 {errors.slotId && <span className='error-validation'>{errors.slotId}</span>}
                              </Col>
                           )}

                           <Col md='4 mb-4'>
                              <div className='flex flex-column gap-2 padding'>
                                 <label className='font-medium text-900' style={{ fontSize: '14px' }}>
                                    {OffDate}
                                 </label>
                                 <Calendar
                                    value={formData.offDate}
                                    onChange={handleChangeDeliveryOffDay}
                                    selectionMode='single'
                                    name='offDate'
                                    id='offDate'
                                    readOnlyInput
                                    touchUI
                                    placeholder={OffDate}
                                    dateFormat='dd/mm/yy'
                                    style={{ height: '44px' }}
                                 />
                                 {errors.offDate && <span className='error-validation'>{errors.offDate}</span>}
                              </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col md='12 mb-4' className='btn-style'>
                              <Button
                                 label={Submit}
                                 icon='pi pi-check'
                                 loading={createDeliveryOffDayLoader || updateDeliveryOffDayLoader}
                                 type={TYPE_FELDS.submit}
                              />
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      </Fragment>
   );
};

export default DeliveryOffDayCreateFormPopup;

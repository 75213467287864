import moment from 'moment';

export const Invoice = (data) => {
   const getQTY = data.orderedProducts.reduce((sum, cur) => {
      return sum + cur.qty;
   }, 0);
   return `<div style="max-width: 575px; margin: auto;">
    <h1 style="text-align: center; border-bottom: 2px dashed #444; font-size: 24px; padding: 10px 0;">MA House</h1>
    
    <table style="width: 100%; padding: 10px 0; font-size: 14px;">
        <tbody>
            <tr>
                <th style="text-align: left;">Name: ${data?.userDetail?.name}</th>
                <th style="text-align: right;">Invoice No: ${data.orderId}</th>
            </tr>
            <tr>
                <td style="text-align: left;">Mobile: ${data?.userDetail?.dialCode || ''} ${
      data?.userDetail?.phone || '-'
   }</td>
                <td style="text-align: right;">Date: ${moment(data.orderDate).format('DD-MM-YYYY')}</td>
            </tr>
        </tbody>
    </table>

    <table style="width: 100%; border-bottom: 2px dashed #444; border-top: 2px dashed #444; font-size: 12px;">
        <tbody>
            <tr>
                <th style="text-align: left; border-bottom: 2px dashed #444; padding: 5px 0;">No.</th>
                <th style="text-align: left; border-bottom: 2px dashed #444; padding: 5px 0;">Name</th>
                <th style="text-align: right; border-bottom: 2px dashed #444; padding: 5px 0;">Qty</th>
                <th style="text-align: right; border-bottom: 2px dashed #444; padding: 5px 0;">Price</th>
                <th style="text-align: right; border-bottom: 2px dashed #444; padding: 5px 0;">Amount</th>
            </tr>
            
                ${data.orderedProducts?.map((elem, i) => {
                   return `<tr>
                        <td>${i + 1}</td>
                        <td style='width:200px;'>${elem.productDetail.productName}</td>
                        <td style='text-align: right;'>${elem.qty}</td>
                        <td style='text-align: right;'>£ ${elem.productDetail.mrp}</td>
                        <td style='text-align: right;'>£ ${elem.productDetail.sellPrice * elem.qty}</td>
                        </tr>`;
                })}
            
                <tr >
                <td colspan="2" style="text-align: left; border-top: 2px dashed #444; padding: 5px 0;font-size:15px;font-weight:600" >
                Items: ${data.orderedProducts?.length || 0}
                </td>
                <td style="text-align: right; border-top: 2px dashed #444; padding: 5px 0; font-size:15px;font-weight:600" >
                Qty: ${getQTY}
                </td>
                 <td colspan="2" style="text-align: center; border-top: 2px dashed #444; padding: 5px 0; font-size:15px;font-weight:600" >
                 £${data.grandTotal}
                </td>
            </tr>
        </tbody>
    </table>
       
    </div>
  </div>`;
};

export const SERVICES = {
   E_COM_PUBLIC: '/e-comm/public',
   E_COM_ADMIN: '/e-comm/admin',
   E_COM: '/e-comm'
};

export const V1 = '/api/v1';

/**Auth And Public*/
export const LOGIN = `${SERVICES.E_COM_PUBLIC}${V1}/login`;
export const FILE = `${SERVICES.E_COM_PUBLIC}${V1}/file`;

/**Common End Points */
export const CRETE = '/create';
export const ENABLE_DISABLE = '/enableDisable';
export const DETAILS = '/detail';
export const CHECK_SLUG = '/checkSlugUrl';

/**End points */
export const DASHBOARD = '/dashboard';
export const USER = '/users';
export const BRAND_MASTER = '/brandMaster';
export const PRODUCTS = '/products';
export const PRODUCTS_STOCK = '/stock';
export const CATEGORY_MASTER = '/categoryMaster';
export const STORE = '/store';
export const WALLET_HISTORY = '/walletHistory';
export const DETAILS_PAGE = '/detailsPage';
export const FOR_ADD_UPDATE = '/forAddedUpdate';
export const IMAGE = '/image';
export const EXTRA = '/extra';
export const NOTIFICATION = '/notification';
export const BANNER_SLIDER = '/bannerSlider';
export const PROMOCODE = '/promoCode';
export const DEALOFTHEDAY = '/dealOfTheDay';
export const GLOBAL_SETTING = '/globalSetting';
export const PRODUCTS_TAG_MASTER = '/tagMaster';
export const FEATURE = '/feature';
export const INQUIRY = '/inquiry';
export const DELIVERY_SLOT = '/deliverySlot';
export const SCHEDULE_ORDER = '/scheduleOrder';
export const DELIVERY_OFF_DAY = '/deliveryOffDay';
export const RIDER_MASTER = '/riderMaster';
export const ONLINE_OFFLINE_HISTORY = '/onlineOfflineHistory';
export const WALLET = '/wallet';
export const ASSIGNED_ORDER = '/assignedOrder';
export const MULTI_ORDER_DELIVERY = '/multiOrderDelivery';
export const ATTRIBUTE_MASTER = '/attributeMaster';
export const ATTRIBUTE = '/attribute';
export const CATEGORY_ATTRIBUTE_MAP = '/categoryAttributeMap';
export const PINCODE_MASTER = '/pincodeMaster';
export const DELIVERY_PINCODE = '/deliveryPincode';
export const ASSIGN_ORDER = '/assignOrder';
export const MASTER = '/master';
export const ORDER = '/order';
export const CART = '/cart';

/**Dashboard */

export const GET_ADMIN_DASHBOARD = `${SERVICES.E_COM_ADMIN}${V1}${DASHBOARD}`;

/**User */

export const GET_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;
export const CREATE_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}${CRETE}`;
export const ENABLE_DISABLE_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}${ENABLE_DISABLE}`;
export const GET_ADMIN_USER_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${USER}${DETAILS}`;
export const EDIT_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;
export const DELETE_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;
export const CUSTOMER_CREATE = `${SERVICES.E_COM_ADMIN}${V1}${USER}/create/customer`;

/**Brand Master */

export const GET_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}`;
export const CREATE_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}`;
export const ENABLE_DISABLE_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}${ENABLE_DISABLE}`;
export const GET_BRAND_MASTER_DETAILS = `${SERVICES.E_COM}${V1}${BRAND_MASTER}${DETAILS}`;
export const EDIT_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}`;
export const DELETE_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}`;
export const CHECK_SLUG_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}${CHECK_SLUG}`;

/**Products */

export const GET_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}`;
export const GET_PRODUCTS_PUBLIC = `${SERVICES.E_COM_PUBLIC}${V1}${PRODUCTS}`;
export const CREATE_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}`;
export const ENABLE_DISABLE_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}${ENABLE_DISABLE}`;
export const EXCEL_PRODUCTS = `${SERVICES.E_COM_ADMIN}${V1}${PRODUCTS}/exportExcel`;
export const GET_PRODUCTS_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${DETAILS}`;
export const EDIT_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}`;
export const DELETE_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}`;
export const CHECK_SLUG_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}${CHECK_SLUG}`;

/**Products Image */

export const GET_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}`;
export const CREATE_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}`;
export const ENABLE_DISABLE_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}${ENABLE_DISABLE}`;
export const GET_IMAGE_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}${DETAILS}`;
export const EDIT_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}`;
export const DELETE_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}`;
export const CHECK_SLUG_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}${CHECK_SLUG}`;

/**Products Extra */

export const GET_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}`;
export const CREATE_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}`;
export const ENABLE_DISABLE_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}${ENABLE_DISABLE}`;
export const GET_PRODUCTS_EXTRA_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}${DETAILS}`;
export const EDIT_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}`;
export const DELETE_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}`;
export const CHECK_SLUG_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}${CHECK_SLUG}`;
export const NOTIFICATION_PRODUCT = `${SERVICES.E_COM_ADMIN}${V1}${PRODUCTS}${NOTIFICATION}`;

/**Category */

export const GET_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}`;
export const CREATE_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}`;
export const ENABLE_DISABLE_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}${ENABLE_DISABLE}`;
export const GET_CATEGORY_DETAILS = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}${DETAILS}`;
export const EDIT_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}`;
export const CATEGORY_NOTIFICATION = `${SERVICES.E_COM_ADMIN}${V1}${CATEGORY_MASTER}${NOTIFICATION}`;
export const DELETE_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}`;
export const CHECK_SLUG_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}${CHECK_SLUG}`;
export const GET_CATEGORY_PUBLIC = `${SERVICES.E_COM_PUBLIC}${V1}${CATEGORY_MASTER}`;

/**Store */

export const GET_STORE = `${SERVICES.E_COM}${V1}${STORE}`;
export const GET_STORE_WALLET_HISTORY = `${SERVICES.E_COM_ADMIN}${V1}${STORE}${WALLET_HISTORY}`;
export const GET_STORE_FOR_ADD_UPDATE = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}${FOR_ADD_UPDATE}`;
export const CREATE_STORE = `${SERVICES.E_COM}${V1}${STORE}`;
export const ENABLE_DISABLE_STORE = `${SERVICES.E_COM}${V1}${STORE}${ENABLE_DISABLE}`;
export const GET_STORE_DETAILS = `${SERVICES.E_COM}${V1}${STORE}${DETAILS}`;
export const EDIT_STORE = `${SERVICES.E_COM}${V1}${STORE}`;
export const DELETE_STORE = `${SERVICES.E_COM}${V1}${STORE}`;
export const CHECK_SLUG_STORE = `${SERVICES.E_COM}${V1}${STORE}${CHECK_SLUG}`;

/**Order setting */

export const GET_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}`;
export const GET_ORDER_SETTING_FOR_ADD_UPDATE = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}${FOR_ADD_UPDATE}`;
export const CREATE_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}`;
export const ENABLE_DISABLE_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}${ENABLE_DISABLE}`;
export const GET_ORDER_SETTING_DETAILS = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}${DETAILS}`;
export const EDIT_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}`;
export const DELETE_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}`;
export const CHECK_SLUG_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}${CHECK_SLUG}`;

/**Orders */

export const GET_ORDER = `${SERVICES.E_COM}${V1}/order`;
export const GET_ORDER_EXCEL = `${SERVICES.E_COM_ADMIN}${V1}/order/exportExcel`;
export const GET_ORDER_DETAIL = `${SERVICES.E_COM}${V1}/order/detail`;
export const CREATE_ORDER_ASSIGN_ORDER = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}${ASSIGN_ORDER}`;

/**Products Stock */

export const GET_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}`;
export const CREATE_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}`;
export const ENABLE_DISABLE_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}${ENABLE_DISABLE}`;
export const GET_PRODUCTS_STOCK_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}${DETAILS}`;
export const EDIT_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}`;
export const DELETE_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}`;
export const EXPORT_EXCEL_PRODUCTS_STOCK = `${SERVICES.E_COM_ADMIN}${V1}${PRODUCTS}${PRODUCTS_STOCK}/exportExcel`;
export const CHECK_SLUG_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${CHECK_SLUG}`;

/**Products Tag Master */

export const GET_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}`;
export const CREATE_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}`;
export const ENABLE_DISABLE_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}${ENABLE_DISABLE}`;
export const GET_PRODUCTS_TAG_MASTER_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}${DETAILS}`;
export const EDIT_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}`;
export const DELETE_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}`;
export const CHECK_SLUG_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${CHECK_SLUG}`;

/**Banner Slider */

export const GET_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}`;
export const CREATE_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}`;
export const ENABLE_DISABLE_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}${ENABLE_DISABLE}`;
export const GET_BANNER_SLIDER_DETAILS = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}${DETAILS}`;
export const EDIT_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}`;
export const DELETE_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}`;
export const CHECK_SLUG_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}${CHECK_SLUG}`;

/**Promo Code */

export const GET_PROMOCODE = `${SERVICES.E_COM}${V1}${PROMOCODE}`;
export const CREATE_PROMOCODE = `${SERVICES.E_COM}${V1}${PROMOCODE}`;
export const ENABLE_DISABLE_PROMOCODE = `${SERVICES.E_COM}${V1}${PROMOCODE}${ENABLE_DISABLE}`;
export const GET_PROMOCODE_DETAILS = `${SERVICES.E_COM}${V1}${PROMOCODE}${DETAILS}`;
export const EDIT_PROMOCODE = `${SERVICES.E_COM}${V1}${PROMOCODE}`;
export const PROMOCODE_NOTIFICATION = `${SERVICES.E_COM_ADMIN}${V1}${PROMOCODE}${NOTIFICATION}`;
export const DELETE_PROMOCODE = `${SERVICES.E_COM}${V1}${PROMOCODE}`;
export const CHECK_SLUG_PROMOCODE = `${SERVICES.E_COM}${V1}${PROMOCODE}${CHECK_SLUG}`;

/**Deal Of The Day */

export const GET_DEALOFTHEDAY = `${SERVICES.E_COM}${V1}${DEALOFTHEDAY}`;
export const CREATE_DEALOFTHEDAY = `${SERVICES.E_COM}${V1}${DEALOFTHEDAY}`;
export const ENABLE_DISABLE_DEALOFTHEDAY = `${SERVICES.E_COM}${V1}${DEALOFTHEDAY}${ENABLE_DISABLE}`;
export const GET_DEALOFTHEDAY_DETAILS = `${SERVICES.E_COM}${V1}${DEALOFTHEDAY}${DETAILS}`;
export const EDIT_DEALOFTHEDAY = `${SERVICES.E_COM}${V1}${DEALOFTHEDAY}`;
export const DEALOFTHEDAY_NOTIFICATION = `${SERVICES.E_COM_ADMIN}${V1}${DEALOFTHEDAY}${NOTIFICATION}`;
export const DELETE_DEALOFTHEDAY = `${SERVICES.E_COM}${V1}${DEALOFTHEDAY}`;
export const CHECK_SLUG_DEALOFTHEDAY = `${SERVICES.E_COM}${V1}${DEALOFTHEDAY}${CHECK_SLUG}`;

/**Details Page */

export const GET_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}`;
export const CREATE_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}`;
export const ENABLE_DISABLE_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${ENABLE_DISABLE}`;
export const GET_DETAILS_PAGE_DETAILS = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${DETAILS}`;
export const EDIT_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}`;
export const DELETE_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}`;

/**Details Master Page */

export const GET_DETAILS_MASTER_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${MASTER}`;
export const CREATE_DETAILS_MASTER_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${MASTER}`;
export const ENABLE_DISABLE_DETAILS_MASTER_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${MASTER}${ENABLE_DISABLE}`;
export const GET_DETAILS_MASTER_PAGE_DETAILS = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${MASTER}${DETAILS}`;
export const EDIT_DETAILS_MASTER_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${MASTER}`;
export const DELETE_DETAILS_MASTER_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${MASTER}`;

/**Feature */

export const GET_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}`;
export const CREATE_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}`;
export const ENABLE_DISABLE_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}${ENABLE_DISABLE}`;
export const GET_FEATURE_DETAILS = `${SERVICES.E_COM}${V1}${FEATURE}${DETAILS}`;
export const EDIT_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}`;
export const DELETE_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}`;

/**Inquiry */

export const GET_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}`;
export const CREATE_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}`;
export const ENABLE_DISABLE_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}${ENABLE_DISABLE}`;
export const GET_INQUIRY_DETAILS = `${SERVICES.E_COM}${V1}${INQUIRY}${DETAILS}`;
export const EDIT_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}`;
export const DELETE_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}`;

/**Delivery slot */

export const GET_DELIVERY_SLOT = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_SLOT}`;
export const CREATE_DELIVERY_SLOT = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_SLOT}`;
export const ENABLE_DISABLE_DELIVERY_SLOT = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_SLOT}${ENABLE_DISABLE}`;
export const GET_DELIVERY_SLOT_DETAILS = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_SLOT}${DETAILS}`;
export const EDIT_DELIVERY_SLOT = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_SLOT}`;
export const DELETE_DELIVERY_SLOT = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_SLOT}`;

/**Delivery off day */

export const GET_DELIVERY_OFF_DAY = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_OFF_DAY}`;
export const CREATE_DELIVERY_OFF_DAY = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_OFF_DAY}`;
export const ENABLE_DISABLE_DELIVERY_OFF_DAY = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_OFF_DAY}${ENABLE_DISABLE}`;
export const GET_DELIVERY_OFF_DAY_DETAILS = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_OFF_DAY}${DETAILS}`;
export const EDIT_DELIVERY_OFF_DAY = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_OFF_DAY}`;
export const DELETE_DELIVERY_OFF_DAY = `${SERVICES.E_COM}${V1}${SCHEDULE_ORDER}${DELIVERY_OFF_DAY}`;

/**Rider */

export const GET_RIDER = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}`;
export const GET_RIDER_ONLINE_OFFLINE = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}${ONLINE_OFFLINE_HISTORY}`;
export const GET_RIDER_WALLET_HISTORY = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}${WALLET}`;
export const GET_RIDER_DELIVERY_LOCATION = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}${MULTI_ORDER_DELIVERY}`;
export const GET_RIDER_DATE_WISE_DELIVERED = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}${ASSIGNED_ORDER}`;
export const GET_RIDER_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}/detail`;
export const CREATE_RIDER = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}`;
export const ENABLE_DISABLE_RIDER = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}/enableDisable`;
export const EDIT_RIDER = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}`;
export const EDIT_RIDER_LOCATION = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}/location`;
export const DELETE_RIDER = `${SERVICES.E_COM_ADMIN}${V1}${RIDER_MASTER}`;

/**Attribute Master */

export const GET_ATTRIBUTE_MASTER = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}`;
export const CREATE_ATTRIBUTE_MASTER = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}`;
export const ENABLE_DISABLE_ATTRIBUTE_MASTER = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${ENABLE_DISABLE}`;
export const GET_ATTRIBUTE_MASTER_DETAILS = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${DETAILS}`;
export const EDIT_ATTRIBUTE_MASTER = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}`;
export const DELETE_ATTRIBUTE_MASTER = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}`;

/**Attribute */

export const GET_ATTRIBUTE = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${ATTRIBUTE}`;
export const CREATE_ATTRIBUTE = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${ATTRIBUTE}`;
export const ENABLE_DISABLE_ATTRIBUTE = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${ATTRIBUTE}${ENABLE_DISABLE}`;
export const GET_ATTRIBUTE_DETAILS = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${ATTRIBUTE}${DETAILS}`;
export const EDIT_ATTRIBUTE = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${ATTRIBUTE}`;
export const DELETE_ATTRIBUTE = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${ATTRIBUTE}`;

/**Category Attribute Map */

export const GET_CATEGORY_ATTRIBUTE_MAP = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${CATEGORY_ATTRIBUTE_MAP}`;
export const CREATE_CATEGORY_ATTRIBUTE_MAP = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${CATEGORY_ATTRIBUTE_MAP}`;
export const ENABLE_DISABLE_CATEGORY_ATTRIBUTE_MAP = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${CATEGORY_ATTRIBUTE_MAP}${ENABLE_DISABLE}`;
export const GET_CATEGORY_ATTRIBUTE_MAP_DETAILS = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${CATEGORY_ATTRIBUTE_MAP}${DETAILS}`;
export const EDIT_CATEGORY_ATTRIBUTE_MAP = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${CATEGORY_ATTRIBUTE_MAP}`;
export const DELETE_CATEGORY_ATTRIBUTE_MAP = `${SERVICES.E_COM}${V1}${ATTRIBUTE_MASTER}${CATEGORY_ATTRIBUTE_MAP}`;

/**pincode master */

export const GET_PINCODE_MASTER = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}`;
export const CREATE_PINCODE_MASTER = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}`;
export const ENABLE_DISABLE_PINCODE_MASTER = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}${ENABLE_DISABLE}`;
export const GET_PINCODE_MASTER_DETAILS = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}${DETAILS}`;
export const EDIT_PINCODE_MASTER = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}`;
export const DELETE_PINCODE_MASTER = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}`;

/**pincode master */

export const GET_DELIVERY_PINCODE = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}${DELIVERY_PINCODE}`;
export const CREATE_DELIVERY_PINCODE = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}${DELIVERY_PINCODE}`;
export const ENABLE_DISABLE_DELIVERY_PINCODE = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}${DELIVERY_PINCODE}${ENABLE_DISABLE}`;
export const GET_DELIVERY_PINCODE_DETAILS = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}${DELIVERY_PINCODE}${DETAILS}`;
export const EDIT_DELIVERY_PINCODE = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}${DELIVERY_PINCODE}`;
export const DELETE_DELIVERY_PINCODE = `${SERVICES.E_COM}${V1}${PINCODE_MASTER}${DELIVERY_PINCODE}`;

export const ORDER_CART = `${SERVICES.E_COM_PUBLIC}${V1}${ORDER}${CART}`;
export const ORDER_ = `${SERVICES.E_COM}${V1}${ORDER}`;

/** repairing order */

export const GET_REPAIRING_ORDER = `${SERVICES.E_COM_ADMIN}${V1}/repair-orders/list`;
export const CREATE_REPAIRING_ORDER = `${SERVICES.E_COM_ADMIN}${V1}/repair-orders/create`;
export const GET_REPAIRING_ORDER_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}/repair-orders/detail`;
export const UPDATE_REPAIRING_ORDER = `${SERVICES.E_COM_ADMIN}${V1}/repair-orders/update`;

import React, { Fragment, useContext, useMemo, useState } from 'react';
import { FormDataFeild, FormDataFeildTextArea } from '../../../../reuseable/Inputs';
import { useMutation, useQuery } from 'react-query';
import { IsLoadingResponse } from '../../../../utils/Helper';
import {
   Submit,
   TYPE_FELDS,
   SlugUrl,
   SEO_Title,
   SEO_Desc,
   SEO_Keyword,
   Close,
   Name,
   pCatId,
   HomeOrder,
   Description,
   FileType
} from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { createCategorySchema } from '../../../../utils/validationSchema';
import { CATEGORY_, CATEGORY_ADD_, SLACE } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import { InputSwitch } from 'primereact/inputswitch';
import {
   Create_Category_Master,
   Get_Check_Slug_Url,
   Get_Details_Category_Master,
   Put_Update_Category_Master,
   get_Category_Master
} from '../../../../Apis/categoryApi/CategoryApi';
import { useNavigate, useParams } from 'react-router-dom';
import { REDIRECT_PATH } from '../../../../Routes';
import CategoryContext from '../../../../context/CategoryContext/CategoryContext';
import CropImagePopup from '../../../CropImagePopup';
import { Upload_Image } from '../../../../Apis/userApi/UserApi';
import { BackButton } from '../../../../reuseableComponents/buttons';

const CategoryMasterCreateFormPopup = ({ isModal = false, refetch, setIsCategoryAdded, setCategoryModal }) => {
   const {
      setFormData,
      id,
      isEdit,
      formData,
      errors,
      setErrors,
      setId,
      setIsEdit,

      crop,
      setCrop,
      completedCrop,
      setCompletedCrop,
      scale,
      rotate,
      aspect,
      dialogModal,
      setdialogsModal,
      selectedParentCategory,
      setSelectedParentCategory,
      setSelectedSubCategory
   } = useContext(CategoryContext);
   const history = useNavigate();
   const [filterDrop, setFilterDrop] = useState({
      brandFilter: '',
      categoryFilter: ''
   });

   let { catId } = useParams();

   const emptyData = () => {
      setFilterDrop((prev) => {
         return {
            brandFilter: '',
            categoryFilter: ''
         };
      });
      setIsEdit(true);
      setFormData((prev) => {
         return {
            ...prev,
            name: '',
            pCatId: '',
            desc: '',
            image: '',
            slugUrl: '',
            seoTitle: '',
            seoDesc: '',
            seoKeyword: '',
            schemaTag: '1',
            order: '',
            isHome: false,
            homeOrder: 1
         };
      });
   };

   let paramsCategoryObj = {
      showAll: true,
      search: { name: filterDrop.categoryFilter }
   };
   let { data: categoryMasterDrop } = useQuery(
      ['getAddCategoryMasterDrop', paramsCategoryObj],
      async () => await get_Category_Master(paramsCategoryObj),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false
      }
   );

   /**
    *
    * @param {event } e
    * create category & update category
    */

   const { mutate: createCategory, isLoading: createCategoryLoader } = useMutation(
      (formData) => Create_Category_Master(formData, history, emptyData, setIsCategoryAdded, refetch),
      {
         onSuccess: (response) => {
            console.log('response >>', response);
            if (response.status === 200) {
               if (setCategoryModal) {
                  setCategoryModal(false);
               }
            }
         }
      }
   );

   const { mutate: updateCategory, isLoading: updateCategoryLoader } = useMutation((formData) =>
      Put_Update_Category_Master(formData, id, history, emptyData)
   );

   const handleCategoryCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         if (formData.subCatId != null) {
            formData[pCatId] = formData.subCatId;
         } else {
            formData[pCatId] = formData.pCatId;
         }
         let schema = createCategorySchema();
         await schema.validate(formData, { abortEarly: false });
         if (catId !== 'add' && catId !== -1 && isEdit === false && catId) {
            let newData = {
               ...formData,
               schemaTag: '1',
               pCatId: formData?.pCatId ? formData?.pCatId?.code : null,
               image: formData?.image ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop() : null
            };
            updateCategory(newData);
         } else {
            let newData = {
               ...formData,
               order: categoryMasterDrop?.payload?.data?.length + 1,
               schemaTag: '1',
               pCatId: formData?.pCatId ? formData?.pCatId?.code : null,
               image: formData?.image ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop() : null
            };
            createCategory(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);

         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   const { mutate: uploadImage } = useMutation((file) =>
      Upload_Image(file, setdialogsModal, setFormData, FileType.CATEGORY)
   );

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeCategory = (e) => {
      const { name, value } = e.target;
      if (name === 'pCatId') {
         setSelectedParentCategory(value);
         setSelectedSubCategory(null);
         const selectedCategory = categoryMasterDrop?.payload?.data?.find((category) => category.catId === value);
         setFormData((prev) => ({
            ...prev,
            [name]: value,
            subCatId: selectedCategory?.subCategories?.length > 0 ? null : value
         }));
      } else if (name === 'subCatId') {
         setSelectedSubCategory(value);
         setFormData((prev) => ({
            ...prev,
            [name]: value
         }));
      } else if (name === 'name') {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value,
               seoTitle: value,
               seoDesc: value,
               seoKeyword: value,
               slugUrl: value.replace(/\s+/g, '-').toLowerCase()
            };
         });
      } else if (name === 'slugUrl') {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value.replace(/\s+/g, '-').toLowerCase()
            };
         });
      } else {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value
            };
         });
      }
   };
   /**
    * if cate id is available to to refresh page to field fill api call
    */
   const { mutate: getCategoryDetails } = useMutation((catId) =>
      Get_Details_Category_Master(catId, history, setFormData, setSelectedParentCategory)
   );

   useMemo(() => {
      let valueOfNumber = Number(catId);
      if (catId !== 'add' && typeof valueOfNumber === 'number' && formData.name === '') {
         getCategoryDetails(catId);
         setId(catId);
         setIsEdit(false);
      } else if (catId === 'add') {
         emptyData();
      }
   }, [catId]);

   /**
    * check slug url
    */
   const { mutate: checkCategorySlug } = useMutation((newData) => Get_Check_Slug_Url(newData));

   const handleFocusChangeSlug = async () => {
      if (formData.slugUrl) {
         let newData = { slugUrl: formData.slugUrl };
         checkCategorySlug(newData);
      }
   };

   /**
    * go to parent page
    */
   const redirectPrevious = () => {
      emptyData();
      history(REDIRECT_PATH.CATEGORY);
   };

   const openImageUploadModal = (e) => {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_event) => {
         setFormData((prev) => {
            return {
               ...prev,
               image: reader.result
            };
         });
      };
      setdialogsModal(true);
   };

   return (
      <Fragment>
         {isModal !== true && <Breadcrumb name={CATEGORY_} slace={SLACE} name1={CATEGORY_ADD_} />}
         <IsLoadingResponse isLoading={createCategoryLoader || updateCategoryLoader} />
         {isModal !== true && <BackButton history={history} path={REDIRECT_PATH.CATEGORY} otherFunc={emptyData} />}
         <div className='card-div'>
            <Row>
               <Col sm='12'>
                  <Card>
                     <CardBody>
                        <Form className='needs-validation' onSubmit={(e) => handleCategoryCreateAndUpdate(e)}>
                           <Row>
                              <div className='field mb-4 col-12 md:col-12'>
                                 <div
                                    style={{
                                       display: 'flex',
                                       justifyContent: 'center',
                                       flexWrap: 'wrap',
                                       marginBottom: '15px'
                                    }}>
                                    <div
                                       style={{
                                          height: '150px',
                                          width: '185px',
                                          display: 'flex',
                                          justifyContent: 'center'
                                       }}>
                                       <img
                                          src={formData?.image}
                                          alt=''
                                          style={{
                                             width: '80%',
                                             height: '98%',
                                             objectFit: 'cover',
                                             borderRadius: '50%'
                                          }}
                                       />
                                    </div>
                                 </div>
                                 <div
                                    style={{
                                       display: 'flex',
                                       justifyContent: 'center',
                                       flexWrap: 'wrap'
                                    }}>
                                    <input
                                       className='custom-file-input'
                                       type='file'
                                       onChange={(e) => openImageUploadModal(e)}
                                    />
                                    <Button
                                       className='p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1'
                                       style={{ height: '40px' }}>
                                       <span className={`p-button-icon p-c p-button-icon-left pi pi-camera `}></span>
                                       <span className='p-button-label p-c' style={{ fontWeight: '100' }}>
                                          {'Choose'}
                                       </span>
                                    </Button>
                                 </div>
                              </div>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={Name}
                                    name='name'
                                    id='name'
                                    type={TYPE_FELDS.text}
                                    placeholder={Name}
                                    value={formData.name}
                                    handleChange={handleChangeCategory}
                                 />
                                 {errors.name && <span className='error-validation'>{errors.name}</span>}
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={pCatId}
                                    name='pCatId'
                                    id='pCatId'
                                    placeholder={pCatId}
                                    value={formData.pCatId}
                                    handleChange={handleChangeCategory}
                                    dropdown={true}
                                    option={categoryMasterDrop?.payload?.data?.map((elem) => {
                                       return {
                                          code: elem?.catId,
                                          name: elem?.name
                                       };
                                    })}
                                    search={(e) =>
                                       setFilterDrop((prev) => {
                                          return {
                                             ...prev,
                                             brandFilter: e.filter
                                          };
                                       })
                                    }
                                    disabled={isEdit === false && !formData.pCatId?.code}
                                 />
                                 {errors.pCatId && <span className='error-validation'>{errors.pCatId}</span>}
                              </Col>
                              <Row>
                                 {selectedParentCategory &&
                                    categoryMasterDrop?.payload?.data?.find(
                                       (category) =>
                                          category.catId === selectedParentCategory.code &&
                                          category.subCategories?.length > 0
                                    ) && (
                                       <Col md='6 mb-4'>
                                          <FormDataFeild
                                             label='Subcategory'
                                             name='subCatId'
                                             id='subCatId'
                                             placeholder='Select Subcategory'
                                             value={formData.subCatId}
                                             handleChange={handleChangeCategory}
                                             dropdown={true}
                                             option={
                                                categoryMasterDrop?.payload?.data
                                                   ?.find((category) => category.catId === selectedParentCategory.code)
                                                   ?.subCategories.map((subCategory) => ({
                                                      code: subCategory?.catId,
                                                      name: subCategory?.name
                                                   })) || []
                                             }
                                          />
                                       </Col>
                                    )}
                              </Row>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={`${SlugUrl}`}
                                    name='slugUrl'
                                    id='slugUrl'
                                    type={TYPE_FELDS.text}
                                    placeholder={`${SlugUrl}`}
                                    value={formData.slugUrl}
                                    handleChange={handleChangeCategory}
                                    handleFocusChange={handleFocusChangeSlug}
                                    blur={true}
                                 />
                                 {errors.slugUrl && <span className='error-validation'>{errors.slugUrl}</span>}
                              </Col>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={`${SEO_Title} *`}
                                    name='seoTitle'
                                    id='seoTitle'
                                    type={TYPE_FELDS.text}
                                    placeholder={`${SEO_Title} *`}
                                    value={formData.seoTitle}
                                    handleChange={handleChangeCategory}
                                 />
                                 {errors.seoTitle && <span className='error-validation'>{errors.seoTitle}</span>}
                              </Col>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={`${SEO_Desc} *`}
                                    name='seoDesc'
                                    id='seoDesc'
                                    type={TYPE_FELDS.text}
                                    placeholder={`${SEO_Desc} *`}
                                    value={formData.seoDesc}
                                    handleChange={handleChangeCategory}
                                 />
                                 {errors.seoDesc && <span className='error-validation'>{errors.seoDesc}</span>}
                              </Col>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={`${SEO_Keyword} *`}
                                    name='seoKeyword'
                                    id='seoKeyword'
                                    type={TYPE_FELDS.text}
                                    placeholder={SEO_Keyword}
                                    value={formData.seoKeyword}
                                    handleChange={handleChangeCategory}
                                 />
                                 {errors.seoKeyword && <span className='error-validation'>{errors.seoKeyword}</span>}
                              </Col>
                              <Col md='6 mb-4' className='flex justify-content-center '>
                                 <div className='flex flex-column gap-2 padding'>
                                    <label
                                       htmlFor='username'
                                       className='font-medium text-900'
                                       style={{ fontSize: '14px' }}>
                                       {'IS Home'}
                                    </label>
                                    <InputSwitch
                                       checked={formData.isHome}
                                       onChange={(e) => handleChangeCategory(e)}
                                       name='isHome'
                                       id='isHome'
                                    />
                                 </div>
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={HomeOrder}
                                    name='homeOrder'
                                    id='homeOrder'
                                    type={TYPE_FELDS.number}
                                    placeholder={HomeOrder}
                                    value={formData.homeOrder}
                                    handleChange={handleChangeCategory}
                                 />
                                 {errors.homeOrder && <span className='error-validation'>{errors.homeOrder}</span>}
                              </Col>
                              <Col md='12 mb-4'>
                                 <FormDataFeildTextArea
                                    label={Description}
                                    name='desc'
                                    id='desc'
                                    type={TYPE_FELDS.text}
                                    placeholder={Description}
                                    value={formData.desc}
                                    handleChange={handleChangeCategory}
                                 />
                                 {errors.desc && <span className='error-validation'>{errors.desc}</span>}
                              </Col>
                           </Row>
                           <Row>
                              <Col md='12 mb-4' className='btn-style flex justify-content-between '>
                                 {isModal !== true && (
                                    <Button
                                       label={Close}
                                       severity='danger'
                                       icon='pi pi-times'
                                       onClick={() => redirectPrevious()}
                                    />
                                 )}
                                 <Button
                                    label={Submit}
                                    icon='pi pi-check'
                                    loading={createCategoryLoader || updateCategoryLoader}
                                    type={TYPE_FELDS.submit}
                                 />
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </div>
         <CropImagePopup
            dialogModal={dialogModal}
            setdialogsModal={setdialogsModal}
            image={formData?.image}
            aspect={aspect}
            crop={crop}
            setCrop={setCrop}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
            scale={scale}
            rotate={rotate}
            setFormData={setFormData}
            uploadImage={uploadImage}
         />
      </Fragment>
   );
};

export default CategoryMasterCreateFormPopup;

export const ROUTING_PATH = {
   ORGANIZATION: '/',
   ABOUT: '/about',
   CONTACT: '/contact'
};

export const ROUTING_NAME = {
   ORGANIZATION: 'Organization',
   ABOUT: 'About',
   CONTACT: 'Contact'
};

export const PROFILE_NAME = {
   PROFILE: 'Profile',
   ACCOUNT: 'Account',
   DESHBOARD: 'Dashboard',
   LOGOUT: 'Logout'
};

export const TABEL_UNIQUE_ID = {
   userId: 'userId',
   brandId: 'brandId',
   productId: 'productId',
   catId: 'catId',
   sliderBannerId: 'sliderBannerId',
   promoCodeId: 'promoCodeId',
   storeId: 'storeId',
   stockId: 'stockId',
   globalSettingId: 'globalSettingId',
   tagId: 'tagId',
   pageId: 'pageId',
   featureId: 'featureId',
   inquiryId: 'inquiryId',
   riderId: 'riderId',
   riderOnlineHistoryId: 'riderOnlineHistoryId',
   riderWalletHistoryId: 'riderWalletHistoryId',
   storeWalletId: 'storeWalletId',
   deliveryOrderId: 'deliveryOrderId',
   orderId: 'orderId',
   dealId: 'dealId',
   slotId: 'slotId',
   dayOffId: 'dayOffId',
   attributeMasterId: 'attributeMasterId',
   attributeId: 'attributeId',
   categoryAttributeMapId: 'categoryAttributeMapId',
   pincodeMasterId: 'pincodeMasterId',
   deliveryPincodeId: 'deliveryPincodeId',
   detailsMasterId: 'detailsMasterId',
   repairOrderId: 'repairOrderId'
};
export const NAVBAR_MODUAL = ['Organization', 'About', 'Contact'];

export const NAVBAR_PROFILE_DROPDOWN = ['Profile', 'Account', 'Dashboard', 'Logout'];

export const MuiTabelStyle = {
   '.MuiTablePagination-selectLabel': {
      margin: '0px'
   },
   '.MuiTablePagination-displayedRows': {
      margin: '0px'
   },

   '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
   },
   '.MuiDataGrid-columnHeader:focus': {
      outline: 'none !important',
      border: 'none !important'
   },
   '&.MuiDataGrid-root': {
      height: 'auto',
      border: 'none !important',
      margin: '0.5rem 2.5rem 1.5rem 2.5rem'
   },
   '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontFamily: 'Open Sans'
      // fontSize: "initial",
   },
   '.MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '100%',
      height: '6px'
   },
   '.MuiDataGrid-virtualScroller::-webkit-scrollbar-track ': {
      backgroundColor: '#e4e4e4',
      borderRadius: '100px'
   },
   '.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      backgroundColor: '#725f5f',
      borderRadius: '100px'
   },
   '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
      background: '#f8f9fa',
      color: 'gray'
   },
   '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontWeight: '700',
      fontFamily: 'Open Sans',
      fontSize: '15px'
   }
};

export const DarkTabelMui = {
   '.MuiTablePagination-selectLabel': {
      margin: '0px'
   },
   '.MuiTablePagination-displayedRows': {
      margin: '0px'
   },

   '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
   },
   '.MuiDataGrid-columnHeader:focus': {
      outline: 'none !important',
      border: 'none !important'
   },
   '&.MuiDataGrid-root': {
      height: 'auto',
      border: 'none !important',
      margin: '0.5rem 2.5rem 1.5rem 2.5rem'
   },
   '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontFamily: 'Open Sans'
      // fontSize: "initial",
   },
   '.MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '100%',
      height: '6px'
   },
   '.MuiDataGrid-virtualScroller::-webkit-scrollbar-track ': {
      backgroundColor: '#e4e4e4',
      borderRadius: '100px'
   },
   '.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      backgroundColor: '#725f5f',
      borderRadius: '100px'
   },
   '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontWeight: '700',
      fontFamily: 'Open Sans',
      fontSize: '17px'
   },
   '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
      background: 'none',
      color: 'white'
   },
   '.MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid #0b213f !important'
   },
   '.MuiDataGrid-cell--withRenderer': {
      borderBottom: '1px solid #0b213f !important',
      color: 'rgba(255, 255, 255, 0.788) !important'
   },
   '.MuiTablePagination-root': {
      color: 'rgba(255, 255, 255, 0.788) !important'
   },
   '.MuiInputBase-root > svg': {
      color: 'rgba(255, 255, 255, 0.788) !important'
   },
   '.MuiTablePagination-actions > button': {
      color: 'rgba(255, 255, 255, 0.788) '
   },

   '.Mui-disabled': {
      color: 'rgb(139 139 139 / 79%)!important'
   },
   '.MuiDataGrid-footerContainer': {
      border: 'none'
   }
};

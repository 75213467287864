import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  RIDER_,
  RIDER_DELIVERY_LOCATION,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useLoadScript,
} from "@react-google-maps/api";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../../../reuseableComponents/buttons";
import { REDIRECT_PATH } from "../../../../Routes";
import { get_Rider_Delivery_Location } from "../../../../Apis/riderApi/RiderApi";
import { useQuery } from "react-query";

const RiderDeliveryLocation = () => {
  const { riderId } = useParams();
  const [address, setAddress] = useState("");
  const [infoWindowData, setInfoWindowData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const history = useNavigate();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAp5NJa0SAKm2pvfb3OaO7Q8asUfV_yGFs",
  });

  const handleAddress = (e, index) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    // Reverse Geocoding to get the address
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setAddress(results[0].formatted_address);
        }
      }
    });
    setInfoWindowData(index);
    setIsOpen(true);
  };

  let paramsObj = {
    riderId,
    showAll: true,
  };
  let { data: riderDeliveryLocation } = useQuery(
    ["getRiderDeliveryLocation", paramsObj],
    async () => await get_Rider_Delivery_Location(paramsObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    // Get the user's current location using the Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
      });
    }
  }, []);

  return (
    <>
      <Breadcrumb name={RIDER_} slace={SLACE} name1={RIDER_DELIVERY_LOCATION} />
      <BackButton history={history} path={REDIRECT_PATH.RIDER} />
      <div className="tabel-contant">
        <div className={`tabelContent`}>
          {!isLoaded ? (
            <p>loading ... </p>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              mapContainerStyle={{ width: "100%", height: "70vh" }}
              zoom={15}
              center={userLocation}
            >
              {riderDeliveryLocation?.map((elem, index) => {
                return (
                  <Marker
                    key={index}
                    position={{
                      lat: +elem?.deliveryLocation?.coordinates[0],
                      lng: +elem?.deliveryLocation?.coordinates[1],
                    }}
                    onClick={(e) => handleAddress(e, index)}
                  >
                    {isOpen && infoWindowData === index && (
                      <InfoWindow
                        onCloseClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <>
                          <p>
                            <b style={{ fontWeight: "700" }}>Address :</b>{" "}
                            {address}
                          </p>
                          <p>
                            <b style={{ fontWeight: "700" }}>Order Id :</b>{" "}
                            {elem.orderId}
                          </p>
                        </>
                      </InfoWindow>
                    )}
                    <Polyline
                      path={riderDeliveryLocation.map((elem) => {
                        return {
                          lat: +elem?.deliveryLocation?.coordinates[0],
                          lng: +elem?.deliveryLocation?.coordinates[1],
                        };
                      })}
                      options={{
                        strokeColor: "#FF0000",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                      }}
                    />
                  </Marker>
                );
              })}
            </GoogleMap>
          )}
        </div>
      </div>
    </>
  );
};

export default RiderDeliveryLocation;

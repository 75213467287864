import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import FeaturesDataTabelContainer from "./FeaturesDataTabelContainer";
import FeaturesCreateFormPopup from "./FeaturesCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  FEATURE,
  FEATURE_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import { CreateFeature, UpdateFeature } from "../../../../constants";
import FeatureContext from "../../../../context/FeatureContext/FeatureContext";
import { get_Feature } from "../../../../Apis/featureApi/FeatureApi";

const Feature = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //brand master edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
    setErrors,
  } = useContext(FeatureContext);

  const history = useNavigate();

  const emptyField = () => {
    setFormData({
      title: "",
      subTitle: "",
      order: "",
      image: "",
      imageUrl: "",
    });
  };

  /**
   * get brand master List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { title: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getFeature", paramsObj],
    async () => await get_Feature(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup feature
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyField();
      setErrors({});
    } else {
      setdialogs(condition);
      emptyField();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={FEATURE} slace={SLACE} name1={FEATURE_LIST} />
      <FeaturesDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateFeature : UpdateFeature}
        Component={
          <FeaturesCreateFormPopup refetch={refetch} emptyField={emptyField} />
        }
      />
    </>
  );
};

export default Feature;

import moment from "moment";
import React, { Fragment, useContext, useState } from "react";
import { useQuery } from "react-query";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import { useNavigate, useParams } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import RiderContext from "../../../../context/RiderContext/RiderContext";
import { get_Rider_DATE_WISE_DELIVERED } from "../../../../Apis/riderApi/RiderApi";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  RIDER_,
  RIDER_DATE_WISE_DELIVERED_ITEM,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { BackButton } from "../../../../reuseableComponents/buttons";
import {
  ORDER_STATUS,
  ORDER_STATUS_DROP,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
} from "../../../../constants";

const RiderDateWiseDeliveredDataTabelContainer = () => {
  const { filter, setFilter } = useContext(RiderContext);
  const history = useNavigate();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [select, setSelect] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const { riderId } = useParams();

  /**
   * get rider date wise delivered List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
    search: {
      riderId,
    },
  };
  if (select !== null) {
    paramsObj.search = {
      ...paramsObj.search,
      orderStatus: select?.code,
    };
  }

  const handleChangeDate = (e) => {
    const { value } = e.target;
    setDateValue(value);
  };

  if (dateValue !== null && dateValue[0] !== null && dateValue[1] !== null) {
    paramsObj["search"] = {
      ...paramsObj.search,
      startDate: moment(dateValue[0]).format(),
      endDate: moment(dateValue[1]).format(),
    };
  }

  let {
    data: riderDateWiseDelivered,
    isLoading: riderDateWiseDeliveredLoader,
    error: riderDateWiseDeliveredError,
  } = useQuery(
    ["getRiderDateWiseDelivered", paramsObj],
    async () => await get_Rider_DATE_WISE_DELIVERED(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (riderDateWiseDeliveredError) {
    const { status, message } =
      riderDateWiseDeliveredError?.response?.data ||
      riderDateWiseDeliveredError;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const handleOpenandClosePopup = () => {
    history(`${REDIRECT_PATH.RIDER}/add`);
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "orderStatus",
      headerName: "Order Status",
      renderCell: (params) =>
        params.row.orderStatus
          ? params.row.orderStatus === ORDER_STATUS.INITIATED
            ? "Initiated"
            : params.row.orderStatus === ORDER_STATUS.ALLOTTED
            ? "Allocated"
            : params.row.orderStatus === ORDER_STATUS.ACCEPTED
            ? "Accepted"
            : params.row.orderStatus === ORDER_STATUS.ARRIVED
            ? "Arrived"
            : params.row.orderStatus === ORDER_STATUS.COLLECTED
            ? "Collected"
            : params.row.orderStatus === ORDER_STATUS.ARRIVED_CUSTOMER_DOORSTEP
            ? "Arrived customer doorstep"
            : params.row.orderStatus === ORDER_STATUS.DELIVERED
            ? "Delivered"
            : params.row.orderStatus === ORDER_STATUS.CANCELLED_BY_VENDOR
            ? "Cancelled by vender"
            : params.row.orderStatus === ORDER_STATUS.UNDELIVERED
            ? "Undelivered"
            : params.row.orderStatus === ORDER_STATUS.ASSIGNED_ADMIN
            ? "Admin assigned"
            : params.row.orderStatus === ORDER_STATUS.RETURNING_SELLER
            ? "Returning seller"
            : params.row.orderStatus === ORDER_STATUS.RETURNED_SELLER
            ? "Returned seller"
            : params.row.orderStatus === ORDER_STATUS.INITIATED_TRIP
            ? "Initiated trip"
            : params.row.orderStatus === ORDER_STATUS.CLOSED_STORE
            ? "Closed store"
            : params.row.orderStatus === ORDER_STATUS.OUT_FOR_DELIVERY
            ? "Out of delivery"
            : "--"
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "paymentMethod",
      headerName: "Payment Method",
      renderCell: (params) =>
        params.row.paymentMethod
          ? params.row.paymentMethod === PAYMENT_METHOD.COD
            ? "Case on delivery"
            : params.row.paymentMethod === PAYMENT_METHOD.RAZOR_PAY
            ? "Razor Pay"
            : params.row.paymentMethod === PAYMENT_METHOD.APPLE_PAY
            ? "Apple Pay"
            : "--"
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "paymentStatus",
      headerName: "Payment Status",
      renderCell: (params) =>
        params.row?.paymentStatus
          ? params.row?.paymentStatus === PAYMENT_STATUS.PAID
            ? "Paid"
            : params.row?.paymentStatus === PAYMENT_STATUS.PENDING
            ? "Pending"
            : params.row?.paymentStatus === PAYMENT_STATUS.FAILED
            ? "Failed"
            : params.row?.paymentStatus === PAYMENT_STATUS.DECLINED
            ? "Declined"
            : "--"
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "totalAmount",
      headerName: "Total Amount",
      renderCell: (params) =>
        params.row?.totalAmount ? params.row?.totalAmount : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "tipAmount",
      headerName: "Tip Amount",
      renderCell: (params) =>
        params.row?.tipAmount ? params.row?.tipAmount : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "deliveryDate",
      headerName: "Delivery Date",
      renderCell: (params) =>
        params.row?.deliveryDate
          ? moment(params.row.deliveryDate).format("DD MMM, YYYY hh:mm")
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY hh:mm"),
    },
  ];
  return (
    <Fragment>
      <Breadcrumb
        name={RIDER_}
        slace={SLACE}
        name1={RIDER_DATE_WISE_DELIVERED_ITEM}
      />
      <BackButton history={history} path={REDIRECT_PATH.RIDER} />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={riderDateWiseDelivered}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={riderDateWiseDeliveredLoader}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.deliveryOrderId}
        addButton={false}
        search={false}
        dropdownFilter={true}
        options={ORDER_STATUS_DROP}
        select={select}
        setSelect={setSelect}
        dateFilter={true}
        date={dateValue}
        handleChangeDate={handleChangeDate}
        isRange={true}
        dateFilterPlaceholder={"Start Date - End Date"}
      />
    </Fragment>
  );
};

export default RiderDateWiseDeliveredDataTabelContainer;

import React, { Fragment, useContext } from 'react';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useMutation } from 'react-query';
import { IsLoadingResponse, ToastMessage, setValueNull } from '../../../../utils/Helper';
import { Submit, TYPE_FELDS, BrandName, BrandDesc, SlugUrl, FileType } from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import BrandMasterContext from '../../../../context/BrandMasterContext/BrandMasterContext';
import {
   Create_Brand_Master,
   Get_Check_Slug_Url,
   Put_Update_Brand_Master
} from '../../../../Apis/brandMasterApi/BrandMasterApi';
import { createBrandMasterSchema } from '../../../../utils/validationSchema';
import CropImagePopup from '../../../CropImagePopup';
import { Upload_Image } from '../../../../Apis/userApi/UserApi';

const BrandMasterCreateFormPopup = (props) => {
   const {
      setFormData,
      id,
      isEdit,
      setdialogs,
      formData,
      errors,
      setErrors,

      crop,
      setCrop,
      completedCrop,
      setCompletedCrop,
      scale,
      rotate,
      aspect,
      dialogModal,
      setdialogsModal
   } = useContext(BrandMasterContext);
   const { refetch, setIsBrandAdded } = props;

   /**
    *
    * @param {event } e
    * create brand master & update brand master
    */

   const { mutate: createBrandMaster, isLoading: createBrandMasterLoader } = useMutation(
      (formData) => Create_Brand_Master(formData),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               refetch();
               setdialogs(false);
               setFormData((prev) => setValueNull(prev));
               setErrors({});
               ToastMessage(response.status, response.data.message);
               if (setIsBrandAdded) {
                  setIsBrandAdded(true);
               }
            }
         }
      }
   );

   const { mutate: updateBrandMaster, isLoading: updateBrandMasterLoader } = useMutation(
      (formData) => Put_Update_Brand_Master(formData, id),
      {
         onSuccess: (response) => {
            refetch();
            setdialogs(false);
            setFormData((prev) => setValueNull(prev));
            setErrors({});
            ToastMessage(response.status, response.data.message);
         }
      }
   );

   const handleUserCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createBrandMasterSchema();
         await schema.validate(formData, { abortEarly: false });
         if (id !== -1 && isEdit === false) {
            let newData = {
               ...formData,
               logo: formData?.image ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop() : null
            };
            updateBrandMaster(newData);
         } else {
            let newData = {
               ...formData,
               logo: formData?.image ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop() : null
            };
            createBrandMaster(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);

         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   const { mutate: uploadImage } = useMutation((file) =>
      Upload_Image(file, setdialogsModal, setFormData, FileType.USER)
   );

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeOrgAdmin = (e) => {
      const { name, value } = e.target;
      if (name === 'brandName') {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value,
               slugUrl: value.replace(/\s+/g, '-').toLowerCase()
            };
         });
      } else if (name === 'slugUrl') {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value.replace(/\s+/g, '-').toLowerCase()
            };
         });
      } else {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value
            };
         });
      }
   };

   /**
    * check slug url
    */
   const { mutate: checkBrandMasterSlug } = useMutation((newData) => Get_Check_Slug_Url(newData));

   const handleFocusChangeSlug = async () => {
      if (formData.slugUrl) {
         let newData = { slugUrl: formData.slugUrl };
         checkBrandMasterSlug(newData);
      }
   };

   const openImageUploadModal = (e) => {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_event) => {
         setFormData((prev) => {
            return {
               ...prev,
               image: reader.result
            };
         });
      };
      setdialogsModal(true);
   };
   return (
      <Fragment>
         <IsLoadingResponse isLoading={createBrandMasterLoader || updateBrandMasterLoader} />
         <Row>
            <Col sm='12'>
               <Card>
                  <CardBody>
                     <Form className='needs-validation' onSubmit={(e) => handleUserCreateAndUpdate(e)}>
                        <Row>
                           <div className='field mb-4 col-12 md:col-12'>
                              <div
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    marginBottom: '15px'
                                 }}>
                                 <div
                                    style={{
                                       height: '150px',
                                       width: '185px',
                                       display: 'flex',
                                       justifyContent: 'center'
                                    }}>
                                    <img
                                       src={formData?.image}
                                       alt=''
                                       style={{
                                          width: '80%',
                                          height: '98%',
                                          objectFit: 'cover',
                                          borderRadius: '50%'
                                       }}
                                    />
                                 </div>
                              </div>
                              <div
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap'
                                 }}>
                                 <input
                                    className='custom-file-input'
                                    type='file'
                                    onChange={(e) => openImageUploadModal(e)}
                                 />
                                 <Button
                                    className='p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1'
                                    style={{ height: '40px' }}>
                                    <span className={`p-button-icon p-c p-button-icon-left pi pi-camera `}></span>
                                    <span className='p-button-label p-c' style={{ fontWeight: '100' }}>
                                       {'Choose'}
                                    </span>
                                 </Button>
                              </div>
                           </div>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={BrandName}
                                 name='brandName'
                                 id='brandName'
                                 type={TYPE_FELDS.text}
                                 placeholder={BrandName}
                                 value={formData.brandName}
                                 handleChange={handleChangeOrgAdmin}
                              />
                              {errors.brandName && <span className='error-validation'>{errors.brandName}</span>}
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={BrandDesc}
                                 name='brandDesc'
                                 id='brandDesc'
                                 type={TYPE_FELDS.text}
                                 placeholder={BrandDesc}
                                 value={formData.brandDesc}
                                 handleChange={handleChangeOrgAdmin}
                              />
                              {errors.brandDesc && <span className='error-validation'>{errors.brandDesc}</span>}
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={SlugUrl}
                                 name='slugUrl'
                                 id='slugUrl'
                                 type={TYPE_FELDS.text}
                                 placeholder={SlugUrl}
                                 value={formData.slugUrl}
                                 handleChange={handleChangeOrgAdmin}
                                 handleFocusChange={handleFocusChangeSlug}
                                 blur={true}
                              />
                              {errors.slugUrl && <span className='error-validation'>{errors.slugUrl}</span>}
                           </Col>
                        </Row>
                        <Row>
                           <Col md='12 mb-4' className='btn-style'>
                              <Button
                                 label={Submit}
                                 icon='pi pi-check'
                                 loading={createBrandMasterLoader || updateBrandMasterLoader}
                                 type={TYPE_FELDS.submit}
                              />
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
         </Row>
         <CropImagePopup
            dialogModal={dialogModal}
            setdialogsModal={setdialogsModal}
            image={formData?.image}
            aspect={aspect}
            crop={crop}
            setCrop={setCrop}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
            scale={scale}
            rotate={rotate}
            setFormData={setFormData}
            uploadImage={uploadImage}
         />
      </Fragment>
   );
};

export default BrandMasterCreateFormPopup;

import React, { Fragment, useContext, useMemo, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  Close,
  Product,
  START_END_DATE,
  DISCOUNT_TYPE,
  Store_,
  DiscountType,
  DiscountValue,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import { createDealOfTheDaySchema } from "../../../../utils/validationSchema";
import {
  DEALOFTHEDAY_,
  DEALOFTHEDAY_ADD,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { useNavigate, useParams } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import CropImagePopup from "../../../CropImagePopup";
import { Upload_Image } from "../../../../Apis/userApi/UserApi";
import { get_Products } from "../../../../Apis/productsApi/ProductsApi";
import { Calendar } from "primereact/calendar";
import { get_Store } from "../../../../Apis/storeApi/StoreApi";
import DealOfTheDayContext from "../../../../context/DealOfTheDayContext/DealOfTheDayContext";
import {
  Create_Deal_Of_The_Day,
  Get_Details_Deal_Of_The_Day,
  Put_Update_Deal_Of_The_Day,
} from "../../../../Apis/dealOfTheDayApi/DealOfTheDayApi";
import { BackButton } from "../../../../reuseableComponents/buttons";

const DealOfTheDayCreateFormPopup = () => {
  const {
    setFormData,
    id,
    isEdit,
    formData,
    errors,
    setErrors,
    setId,
    setIsEdit,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
    dialogModal,
    setdialogsModal,
    setAspect,
    fileType,
    setFileType,
  } = useContext(DealOfTheDayContext);
  const history = useNavigate();
  const [filterDrop, setFilterDrop] = useState({
    categoryFilter: "",
    productFilter: "",
  });

  let { dealOfTheDayId } = useParams();

  const emptyData = () => {
    setFilterDrop((prev) => {
      return {
        categoryFilter: "",
        productFilter: "",
      };
    });
    setFormData((prev) => {
      return {
        ...prev,
        productId: "",
        storeId: "",
        discountType: "",
        discountValue: "",
        startDate: "",
        endDate: "",
        createdBy: "",
        assignStores: "",
      };
    });
    setFileType("");
  };

  let paramsProductObj = {
    showAll: true,
    search: { name: filterDrop.productFilter },
  };
  let { data: productDrop } = useQuery(
    ["getAddBannerProductDrop", paramsProductObj],
    async () => await get_Products(paramsProductObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  /**
   *
   * @param {event } e
   * create deal of the day & update deal of the day
   */

  const { mutate: createDealOfTheDay, isLoading: createDealOfTheDayLoader } =
    useMutation((formData) =>
      Create_Deal_Of_The_Day(formData, history, emptyData)
    );

  const { mutate: updateDealOfTheDay, isLoading: updateDealOfTheDayLoader } =
    useMutation((formData) =>
      Put_Update_Deal_Of_The_Day(formData, id, history, emptyData)
    );

  const handleDealOfTheDayCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createDealOfTheDaySchema();
      await schema.validate(formData, { abortEarly: false });
      if (
        dealOfTheDayId !== "add" &&
        dealOfTheDayId !== -1 &&
        isEdit === false
      ) {
        let newData = {
          ...formData,
          storeId: formData?.assignStores?.code ?? null,
          applyType: formData?.applyType?.code,
          productId: formData?.productId?.code ?? null,
          discountType: formData?.discountType?.code ?? null,
          startDate: new Date(formData?.startDate[0]),
          endDate: new Date(formData?.startDate[1]),
        };
        delete newData.assignStores;
        updateDealOfTheDay(newData);
      } else {
        let newData = {
          ...formData,
          storeId: formData?.assignStores?.code ?? null,
          productId: formData?.productId?.code ?? null,
          discountType: formData?.discountType?.code ?? null,
          startDate: new Date(formData?.startDate[0]),
          endDate: new Date(formData?.startDate[1]),
          createdBy: localStorage.getItem("user"),
        };

        delete newData.assignStores;

        createDealOfTheDay(newData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);

      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, fileType)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeDealOfTheDay = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * if cate id is available to to refresh page to field fill api call
   */
  const { mutate: getDealOfTheDayDetails } = useMutation(
    (dealOfTheDayId) =>
      Get_Details_Deal_Of_The_Day(
        dealOfTheDayId,
        history,
        setFormData,
        setAspect
      ),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setId(dealOfTheDayId);
          setIsEdit(false);
        }
      },
    }
  );

  useMemo(() => {
    if (
      dealOfTheDayId !== undefined &&
      dealOfTheDayId !== "add" &&
      (formData.assignStores === "" || formData.assignStores === null)
    ) {
      getDealOfTheDayDetails(dealOfTheDayId);
    } else if (dealOfTheDayId === "add") {
      emptyData();
    }
    // eslint-disable-next-line
  }, [dealOfTheDayId]);

  /**
   * go to parent page
   */
  const redirectPrevious = () => {
    history(REDIRECT_PATH.DEALOFTHEDAY);
    emptyData();
  };

  let paramsObj = {
    showAll: true,
    search: { name: filterDrop.storeFilter },
  };
  let { data: storeDrop } = useQuery(
    ["getStoreDropdown", paramsObj],
    async () => await get_Store(paramsObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Fragment>
      <Breadcrumb name={DEALOFTHEDAY_} slace={SLACE} name1={DEALOFTHEDAY_ADD} />
      <IsLoadingResponse
        isLoading={createDealOfTheDayLoader || updateDealOfTheDayLoader}
      />
      <BackButton
        history={history}
        path={REDIRECT_PATH.DEALOFTHEDAY}
        otherFunc={emptyData}
      />
      <div className="card-div">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleDealOfTheDayCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={Store_}
                        name="assignStores"
                        id="assignStores"
                        placeholder={Store_}
                        value={formData.assignStores}
                        handleChange={handleChangeDealOfTheDay}
                        dropdown={true}
                        option={storeDrop?.payload?.data?.map((elem) => {
                          return {
                            name: elem?.name,
                            code: elem?.storeId,
                          };
                        })}
                        search={(e) => {
                          setFilterDrop((prev) => {
                            return {
                              ...prev,
                              storeFilter: e.filter,
                            };
                          });
                        }}
                      />
                      {errors.assignStores && (
                        <span className="error-validation">
                          {errors.assignStores}
                        </span>
                      )}
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={Product}
                        name="productId"
                        id="productId"
                        placeholder={Product}
                        value={formData.productId}
                        handleChange={handleChangeDealOfTheDay}
                        dropdown={true}
                        option={productDrop?.payload?.data?.map((elem) => {
                          return {
                            code: elem?.productId,
                            name: elem?.productName,
                          };
                        })}
                        search={(e) =>
                          setFilterDrop((prev) => {
                            return {
                              ...prev,
                              productFilter: e.filter,
                            };
                          })
                        }
                      />
                      {errors.productId && (
                        <span className="error-validation">
                          {errors.productId}
                        </span>
                      )}
                    </Col>

                    <Col md="4 mb-2">
                      <FormDataFeild
                        label={DiscountType}
                        name="discountType"
                        id="discountType"
                        placeholder={DiscountType}
                        value={formData.discountType}
                        handleChange={handleChangeDealOfTheDay}
                        dropdown={true}
                        filter={false}
                        option={DISCOUNT_TYPE}
                      />
                      {errors.discountType && (
                        <span className="error-validation">
                          {errors.discountType}
                        </span>
                      )}
                    </Col>
                    <Col md="4 mb-2">
                      <FormDataFeild
                        label={DiscountValue}
                        name="discountValue"
                        id="discountValue"
                        type={TYPE_FELDS.number}
                        placeholder={DiscountValue}
                        value={formData.discountValue}
                        handleChange={handleChangeDealOfTheDay}
                      />
                      {errors.discountValue && (
                        <span className="error-validation">
                          {errors.discountValue}
                        </span>
                      )}
                    </Col>
                    <Col md="8 mb-4">
                      <div className="flex flex-column gap-2 padding">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {START_END_DATE}
                        </label>
                        <Calendar
                          value={formData.startDate}
                          onChange={(e) => handleChangeDealOfTheDay(e)}
                          selectionMode="range"
                          name="startDate"
                          readOnlyInput
                          touchUI
                          placeholder={START_END_DATE}
                          dateFormat="dd/mm/yy"
                          style={{ height: "44px" }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      md="12 mb-4"
                      className="btn-style flex justify-content-between "
                    >
                      <Button
                        label={Close}
                        severity="danger"
                        icon="pi pi-times"
                        onClick={() => redirectPrevious()}
                      />
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={
                          createDealOfTheDayLoader || updateDealOfTheDayLoader
                        }
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <CropImagePopup
        dialogModal={dialogModal}
        setdialogsModal={setdialogsModal}
        image={formData?.image}
        aspect={aspect}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        setFormData={setFormData}
        uploadImage={uploadImage}
      />
    </Fragment>
  );
};

export default DealOfTheDayCreateFormPopup;

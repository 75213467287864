import React, { useState } from 'react';
import { PRODUCT, SLACE, POS as Pos } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { Col, Row } from 'reactstrap';
import ProductList from './ProductList';
import { Create_Order_Cart, delete_Order_Cart, get_Order_Cart } from '../../../../Apis/POSApis/POSApi';
import { useMutation, useQuery } from 'react-query';
import Checkout from './Checkout';
import { ToastMessage } from '../../../../utils/Helper';

const POS = () => {
   const [userId, setUserId] = useState('');

   let paramsObj = {
      showAll: true,
      userId
   };

   let { data: cartData, refetch: cartRefetch } = useQuery(
      ['get_Order_Cart', paramsObj],
      async () => await get_Order_Cart(paramsObj),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false
      }
   );

   /**
    * create order cart
    */
   const { mutate: createOrderCart, isLoading: createOrderLoader } = useMutation(
      (formData) => Create_Order_Cart(formData),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               cartRefetch();
            }
         },
         onError: (error) => {
            const { status, message } = error?.response?.data || error;
            ToastMessage(status || 1, message);
         }
      }
   );

   /**
    * delete order cart
    */
   const { mutate: deleteOrderCart } = useMutation((formData) => delete_Order_Cart(formData), {
      onSuccess: (response) => {
         if (response.status === 200) {
            cartRefetch();
            ToastMessage(response.status || 1, response.data.message);
         }
      },
      onError: (error) => {
         const { status, message } = error?.response?.data || error;
         ToastMessage(status || 1, message);
      }
   });

   return (
      <>
         <Breadcrumb name={PRODUCT} slace={SLACE} name1={Pos} />
         <Row>
            <Col md={7}>
               <ProductList createOrderCart={createOrderCart} createOrderLoader={createOrderLoader} userId={userId} />
            </Col>
            <Col md={5}>
               <Checkout
                  cartData={cartData}
                  createOrderCart={createOrderCart}
                  deleteOrderCart={deleteOrderCart}
                  createOrderLoader={createOrderLoader}
                  cartRefetch={cartRefetch}
                  userId={userId}
                  setUserId={setUserId}
               />
            </Col>
         </Row>
      </>
   );
};

export default POS;

import React, { Fragment, useContext, useMemo, useState } from 'react';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useMutation, useQuery } from 'react-query';
import { IsLoadingResponse, removeAllSpacesInString } from '../../../../utils/Helper';
import {
   Submit,
   TYPE_FELDS,
   Close,
   Product,
   Category_Name,
   START_END_DATE,
   PromoCode,
   DISCOUNT_TYPE,
   Store_,
   DiscountType,
   ApplyType,
   DISCOUNT_APPLY_TYPE,
   DiscountValue,
   MaxDiscountAmount,
   MinOrderAmount_,
   PROMO_CODE_APPLY_TYPE
} from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { createPromoCodeSchema } from '../../../../utils/validationSchema';
import { PROMOCODE_, PROMOCODE_ADD, SLACE } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import { get_Category_Master } from '../../../../Apis/categoryApi/CategoryApi';
import { useNavigate, useParams } from 'react-router-dom';
import { REDIRECT_PATH } from '../../../../Routes';
import PromoCodeContext from '../../../../context/PromoCodeContext/PromoCodeContext';
import { get_Products } from '../../../../Apis/productsApi/ProductsApi';
import { Calendar } from 'primereact/calendar';
import { get_Store } from '../../../../Apis/storeApi/StoreApi';
import {
   Create_Promo_Code,
   Get_Details_Promo_Code,
   Put_Update_Promo_Code
} from '../../../../Apis/promoCodeApi/PromoCodeApi';
import moment from 'moment';
import { BackButton } from '../../../../reuseableComponents/buttons';

const PromoCodeCreateFormPopup = () => {
   const {
      setFormData,
      id,
      isEdit,
      formData,
      errors,
      setErrors,
      setId,
      setIsEdit,

      setAspect,
      setFileType
   } = useContext(PromoCodeContext);
   const history = useNavigate();
   const [filterDrop, setFilterDrop] = useState({
      categoryFilter: '',
      productFilter: ''
   });

   let { promoCodeId } = useParams();

   const emptyData = () => {
      setFilterDrop((prev) => {
         return {
            categoryFilter: '',
            productFilter: ''
         };
      });
      setFormData((prev) => {
         return {
            productId: '',
            catId: '',
            storeId: '',
            applyType: '',
            discountType: '',
            discountValue: '',
            promoCode: '',
            startDate: '',
            endDate: '',
            maxDiscountAmount: '',
            minOrderAmount: ''
         };
      });
      setFileType('');
   };

   let paramsProductObj = {
      showAll: true,
      search: { name: filterDrop.productFilter }
   };
   let { data: productDrop } = useQuery(
      ['getAddBannerProductDrop', paramsProductObj],
      async () => await get_Products(paramsProductObj),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false
      }
   );

   let paramsCategoryObj = {
      showAll: true,
      search: { name: filterDrop.categoryFilter }
   };
   let { data: categoryDrop } = useQuery(
      ['getAddBannerCategoryDrop', paramsCategoryObj],
      async () => await get_Category_Master(paramsCategoryObj),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false
      }
   );

   /**
    *
    * @param {event } e
    * create promo code & update promo code
    */

   const { mutate: createPromoCode, isLoading: createPromoCodeLoader } = useMutation((formData) =>
      Create_Promo_Code(formData, history, emptyData)
   );

   const { mutate: updatePromoCode, isLoading: updatePromoCodeLoader } = useMutation((formData) =>
      Put_Update_Promo_Code(formData, id, history, emptyData)
   );

   const handlePromoCodeCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createPromoCodeSchema(selectedType);
         await schema.validate(formData, { abortEarly: false });
         if (promoCodeId !== 'add' && promoCodeId !== -1 && isEdit === false) {
            let newData = {
               ...formData,
               storeId: formData?.assignStores?.code ?? null,
               catId: formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.CATEGORY ? formData?.catId?.code ?? null : '',
               applyType: formData?.applyType?.code,
               productId:
                  formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.PRODUCT ? formData?.productId?.code ?? null : '',
               discountType: formData?.discountType?.code ?? null,
               startDate: moment(formData?.startDate[0]).startOf('day').toISOString(),
               endDate: moment(formData?.startDate[1]).endOf('day').toISOString(),
               minOrderAmount: +formData?.minOrderAmount ?? null,
               maxDiscountAmount: +formData?.maxDiscountAmount ?? null,
               discountValue: +formData?.discountValue ?? null
            };
            if (formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.PRODUCT) {
               delete newData.catId;
            }
            if (formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.CATEGORY) {
               delete newData.productId;
            }

            if (formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.ALL) {
               delete newData.productId;
               delete newData.catId;
            }
            delete newData.assignStores;
            updatePromoCode(newData);
         } else {
            let newData = {
               ...formData,
               storeId: formData?.assignStores?.code ?? null,
               catId: formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.CATEGORY ? formData?.catId?.code ?? null : '',
               applyType: formData?.applyType?.code,
               productId:
                  formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.PRODUCT ? formData?.productId?.code ?? null : '',
               discountType: formData?.discountType?.code ?? null,
               startDate: moment(formData?.startDate[0]).startOf('day').toISOString(),
               endDate: moment(formData?.startDate[1]).endOf('day').toISOString(),
               minOrderAmount: +formData?.minOrderAmount ?? null,
               maxDiscountAmount: +formData?.maxDiscountAmount ?? null,
               discountValue: +formData?.discountValue ?? null
            };

            if (formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.PRODUCT) {
               delete newData.catId;
            }
            if (formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.CATEGORY) {
               delete newData.productId;
            }

            if (formData?.applyType?.code === PROMO_CODE_APPLY_TYPE.ALL) {
               delete newData.productId;
               delete newData.catId;
            }

            delete newData.assignStores;

            createPromoCode(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);

         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangePromoCode = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => {
         return {
            ...prev,
            [name]: name === 'promoCode' ? removeAllSpacesInString(value.toUpperCase()) : value
         };
      });
   };

   /**
    * if cate id is available to to refresh page to field fill api call
    */
   const { mutate: getPromoCodeDetails } = useMutation(
      (promoCodeId) => Get_Details_Promo_Code(promoCodeId, history, setFormData, setAspect),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               setId(promoCodeId);
               setIsEdit(false);
            }
         }
      }
   );

   useMemo(() => {
      if (
         promoCodeId !== undefined &&
         promoCodeId !== 'add' &&
         (formData.assignStores === null || formData.assignStores === '')
      ) {
         getPromoCodeDetails(promoCodeId);
      } else if (promoCodeId === 'add') {
         emptyData();
      }
      // eslint-disable-next-line
   }, [promoCodeId]);

   /**
    * go to parent page
    */
   const redirectPrevious = () => {
      emptyData();
      history(REDIRECT_PATH.PROMOCODE);
   };

   const [selectedType, setSelectedType] = useState('1');

   const handleChangeType = (e) => {
      const { name, value } = e.target;
      if (value) setSelectedType(value.code.toString());
      else setSelectedType('1');
      setFormData((prev) => {
         return {
            ...prev,
            [name]: value
         };
      });
   };

   let paramsObj = {
      showAll: true,
      search: { name: filterDrop.storeFilter }
   };
   let { data: storeDrop } = useQuery(['getStoreDropdown', paramsObj], async () => await get_Store(paramsObj), {
      keepPreviousData: true,
      refetchOnWindowFocus: false
   });

   return (
      <Fragment>
         <Breadcrumb name={PROMOCODE_} slace={SLACE} name1={PROMOCODE_ADD} />
         <IsLoadingResponse isLoading={createPromoCodeLoader || updatePromoCodeLoader} />
         <BackButton history={history} path={REDIRECT_PATH.PROMOCODE} otherFunc={emptyData} />
         <div className='card-div'>
            <Row>
               <Col sm='12'>
                  <Card>
                     <CardBody>
                        <Form className='needs-validation' onSubmit={(e) => handlePromoCodeCreateAndUpdate(e)}>
                           <Row>
                              <Col md='4 mb-4'>
                                 <FormDataFeild
                                    label={Store_}
                                    name='assignStores'
                                    id='assignStores'
                                    placeholder={Store_}
                                    value={formData.assignStores}
                                    handleChange={handleChangePromoCode}
                                    dropdown={true}
                                    option={storeDrop?.payload?.data?.map((elem) => {
                                       return {
                                          name: elem?.name,
                                          code: elem?.storeId
                                       };
                                    })}
                                    search={(e) => {
                                       setFilterDrop((prev) => {
                                          return {
                                             ...prev,
                                             storeFilter: e.filter
                                          };
                                       });
                                    }}
                                 />
                                 {errors.assignStores && (
                                    <span className='error-validation'>{errors.assignStores}</span>
                                 )}
                              </Col>
                              <Col md='4 mb-4'>
                                 <FormDataFeild
                                    label={PromoCode}
                                    name='promoCode'
                                    id='promoCode'
                                    type={TYPE_FELDS.text}
                                    placeholder={PromoCode}
                                    value={formData.promoCode}
                                    handleChange={handleChangePromoCode}
                                 />
                                 {errors.promoCode && <span className='error-validation'>{errors.promoCode}</span>}
                              </Col>
                              <Col md='4 mb-2'>
                                 <FormDataFeild
                                    label={ApplyType}
                                    name='applyType'
                                    id='applyType'
                                    placeholder={ApplyType}
                                    value={formData.applyType}
                                    handleChange={(e) => handleChangeType(e)}
                                    dropdown={true}
                                    filter={false}
                                    option={DISCOUNT_APPLY_TYPE}
                                 />
                                 {errors.applyType && <span className='error-validation'>{errors.applyType}</span>}
                              </Col>

                              {formData.applyType?.code === PROMO_CODE_APPLY_TYPE.PRODUCT ? (
                                 <Col md='4 mb-4'>
                                    <FormDataFeild
                                       label={Product}
                                       name='productId'
                                       id='productId'
                                       placeholder={Product}
                                       value={formData.productId}
                                       handleChange={handleChangePromoCode}
                                       dropdown={true}
                                       option={productDrop?.payload?.data?.map((elem) => {
                                          return {
                                             code: elem?.productId,
                                             name: elem?.productName
                                          };
                                       })}
                                       search={(e) =>
                                          setFilterDrop((prev) => {
                                             return {
                                                ...prev,
                                                productFilter: e.filter
                                             };
                                          })
                                       }
                                    />
                                    {errors.productId && <span className='error-validation'>{errors.productId}</span>}
                                 </Col>
                              ) : formData.applyType?.code === PROMO_CODE_APPLY_TYPE.CATEGORY ? (
                                 <Col md='4 mb-4'>
                                    <FormDataFeild
                                       label={Category_Name}
                                       name='catId'
                                       id='catId'
                                       placeholder={Category_Name}
                                       value={formData.catId}
                                       handleChange={handleChangePromoCode}
                                       dropdown={true}
                                       option={categoryDrop?.payload?.data?.map((elem) => {
                                          return {
                                             code: elem?.catId,
                                             name: elem?.name
                                          };
                                       })}
                                       search={(e) =>
                                          setFilterDrop((prev) => {
                                             return {
                                                ...prev,
                                                categoryFilter: e.filter
                                             };
                                          })
                                       }
                                    />
                                    {errors.catId && <span className='error-validation'>{errors.catId}</span>}
                                 </Col>
                              ) : null}
                              <Col md='4 mb-2'>
                                 <FormDataFeild
                                    label={DiscountType}
                                    name='discountType'
                                    id='discountType'
                                    placeholder={DiscountType}
                                    value={formData.discountType}
                                    handleChange={handleChangePromoCode}
                                    dropdown={true}
                                    filter={false}
                                    option={DISCOUNT_TYPE}
                                 />
                                 {errors.discountType && (
                                    <span className='error-validation'>{errors.discountType}</span>
                                 )}
                              </Col>
                              <Col md='4 mb-2'>
                                 <FormDataFeild
                                    label={DiscountValue}
                                    name='discountValue'
                                    id='discountValue'
                                    type={TYPE_FELDS.number}
                                    placeholder={DiscountValue}
                                    value={formData.discountValue}
                                    handleChange={handleChangePromoCode}
                                 />
                                 {errors.discountValue && (
                                    <span className='error-validation'>{errors.discountValue}</span>
                                 )}
                              </Col>
                              <Col md='4 mb-2'>
                                 <FormDataFeild
                                    label={MaxDiscountAmount}
                                    name='maxDiscountAmount'
                                    id='maxDiscountAmount'
                                    type={TYPE_FELDS.number}
                                    placeholder={MaxDiscountAmount}
                                    value={formData.maxDiscountAmount}
                                    handleChange={handleChangePromoCode}
                                 />
                                 {errors.maxDiscountAmount && (
                                    <span className='error-validation'>{errors.maxDiscountAmount}</span>
                                 )}
                              </Col>
                              <Col md='4 mb-2'>
                                 <FormDataFeild
                                    label={MinOrderAmount_}
                                    name='minOrderAmount'
                                    id='minOrderAmount'
                                    type={TYPE_FELDS.number}
                                    placeholder={MinOrderAmount_}
                                    value={formData.minOrderAmount}
                                    handleChange={handleChangePromoCode}
                                 />
                                 {errors.minOrderAmount && (
                                    <span className='error-validation'>{errors.minOrderAmount}</span>
                                 )}
                              </Col>
                              <Col md='8 mb-4'>
                                 <div className='flex flex-column gap-2 padding'>
                                    <label
                                       htmlFor='username'
                                       className='font-medium text-900'
                                       style={{ fontSize: '14px' }}>
                                       {START_END_DATE}
                                    </label>
                                    <Calendar
                                       value={formData.startDate}
                                       onChange={(e) => handleChangePromoCode(e)}
                                       selectionMode='range'
                                       name='startDate'
                                       readOnlyInput
                                       touchUI
                                       placeholder={START_END_DATE}
                                       dateFormat='dd/mm/yy'
                                       style={{ height: '44px' }}
                                    />
                                 </div>
                              </Col>
                           </Row>
                           <Row>
                              <Col md='12 mb-4' className='btn-style flex justify-content-between '>
                                 <Button
                                    label={Close}
                                    severity='danger'
                                    icon='pi pi-times'
                                    onClick={() => redirectPrevious()}
                                 />
                                 <Button
                                    label={Submit}
                                    icon='pi pi-check'
                                    loading={createPromoCodeLoader || updatePromoCodeLoader}
                                    type={TYPE_FELDS.submit}
                                 />
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </div>
      </Fragment>
   );
};

export default PromoCodeCreateFormPopup;

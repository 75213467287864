import React, { useContext } from 'react';
import { maxLengthCheck } from '../utils/Helper';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import CssContext from '../context/css_context/CssContext/CssContext';
import { Calendar } from 'primereact/calendar';
import { Password } from 'primereact/password';

const Inputs = (props) => {
   const { Label, type, name, value, onChange } = props;
   return (
      <>
         <label for='exampleInputEmail1' className='form-label'>
            {Label}
         </label>
         {type === 'number' ? (
            <input
               type={type}
               name={name}
               className='form-control'
               aria-describedby='emailHelp'
               value={value}
               min={'0'}
               maxLength={10}
               onInput={maxLengthCheck}
               onChange={(e) => onChange(e)}
               autoComplete='off'
            />
         ) : (
            <input
               type={type}
               name={name}
               className='form-control'
               aria-describedby='emailHelp'
               value={value}
               onChange={(e) => onChange(e)}
               autoComplete='off'
            />
         )}
      </>
   );
};

export default Inputs;

export const LoginInput = (props) => {
   const { Label, type, name, value, onChange, maxlangth } = props;
   return maxlangth ? (
      <span className='p-input-icon-left w-full mb-3'>
         <i className='pi pi-phone' />
         <InputText
            placeholder={Label}
            type={type}
            name={name}
            value={value}
            onChange={(e) => onChange(e)}
            onInput={(e) => maxLengthCheck(e, maxlangth)}
            className='p-inputtext p-component w-full md:w-25rem'
            style={{ height: '44px' }}
            autoComplete='off'
         />
      </span>
   ) : (
      <span className='p-input-icon-left w-full mb-3'>
         <i className='pi pi-lock' />
         <InputText
            placeholder={Label}
            type={type}
            name={name}
            value={value}
            onChange={(e) => onChange(e)}
            className='p-inputtext p-component w-full md:w-25rem'
            style={{ height: '44px' }}
            autoComplete='off'
         />
      </span>
   );
};

export const SearchFilterFeild = (props) => {
   const { filter, setFilter, type, placeholder } = props;
   const { darkMode } = useContext(CssContext);
   return (
      <span className='p-input-icon-left w-full sm:w-auto '>
         <i className='pi pi-search' />
         <InputText
            placeholder={placeholder}
            type={type}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className={` w-full sm:w-auto  ${darkMode ? 'dark-input' : ''}`}
            style={{ height: '45px' }}
            autoComplete='off'
         />
      </span>
   );
};

export const FormDataFeild = (props) => {
   const {
      label,
      name,
      placeholder,
      value,
      handleChange,
      handleFocusChange,
      type,
      id,
      maxlangth,
      option,
      search,
      dropdown,
      multiDropdown,
      filter,
      multiOption,
      blur,
      max,
      disabled,
      time,
      showClear,
      customClear,
      clearDropdown
   } = props;
   const { darkMode } = useContext(CssContext);
   return time ? (
      <div className='flex flex-column gap-2 padding w-100 '>
         <label htmlFor='username' className='font-medium text-900' style={{ fontSize: '14px' }}>
            {label}
         </label>
         <Calendar
            value={value}
            onChange={(e) => handleChange(e)}
            timeOnly
            id={id}
            name={name}
            placeholder={placeholder}
            className={`w-full p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            style={{ color: 'black', fontWeight: disabled ? 900 : 'normal' }}
            autoComplete='off'
         />
      </div>
   ) : filter === false ? (
      <div className='flex flex-column gap-2 padding w-100 '>
         <label htmlFor='username' className='font-medium text-900' style={{ fontSize: '14px' }}>
            {label}
         </label>
         <Dropdown
            value={value}
            onChange={(e) => handleChange(e)}
            options={option}
            optionLabel='name'
            id={id}
            name={name}
            placeholder={placeholder}
            className={`w-full p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            showClear
            disabled={disabled}
            style={{ color: 'black', fontWeight: disabled ? 900 : 'normal' }}
            autoComplete='off'
         />
      </div>
   ) : multiDropdown ? (
      <div className='flex flex-column gap-2 padding w-100 '>
         <label htmlFor='username' className='font-medium text-900' style={{ fontSize: '14px' }}>
            {label}
         </label>
         <MultiSelect
            value={value}
            onChange={(e) => handleChange(e)}
            options={multiOption}
            optionLabel='name'
            name={name}
            filter
            id={id}
            placeholder={placeholder}
            // maxSelectedLabels={3}
            className={`w-full p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            onFilter={(e) => search(e)}
            showClear
            disabled={disabled}
            style={{
               color: 'black',
               fontWeight: disabled ? 900 : 'normal',
               height: '44px'
            }}
            autoComplete='off'
         />
      </div>
   ) : option && dropdown ? (
      <div className='flex flex-column gap-2 padding w-100 position-relative '>
         <label htmlFor='username' className='font-medium text-900' style={{ fontSize: '14px' }}>
            {`${label}`}
         </label>
         <Dropdown
            value={value}
            onChange={(e) => handleChange(e)}
            options={option}
            optionLabel='name'
            name={name}
            id={id}
            placeholder={placeholder}
            filter
            className={`w-full p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            onFilter={(e) => search(e)}
            resetFilterOnHide
            showFilterClear
            filterBy='name' // Filter by label property
            showClear={true}
            disabled={disabled}
            filterInputAutoFocus={false}
            style={{ color: 'black', fontWeight: disabled ? 900 : 'normal' }}
         />

         {customClear && value?.name && (
            <div
               className='position-absolute'
               style={{
                  top: '55%',
                  right: '47px',
                  textAlign: 'end',
                  zIndex: '9',
                  cursor: 'pointer',
                  color: 'gray'
               }}
               onClick={() => clearDropdown(value)}>
               <i className='pi pi-times' />
            </div>
         )}
      </div>
   ) : maxlangth ? (
      <div className='flex flex-column gap-2 padding w-100 '>
         <label htmlFor='username' className='font-medium text-900' style={{ fontSize: '14px' }}>
            {label}
         </label>
         <InputText
            id={id}
            type={type}
            name={name}
            aria-describedby='username-help'
            className={`p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e)}
            onInput={(e) => maxLengthCheck(e, maxlangth)}
            disabled={disabled}
            style={{ color: 'black', fontWeight: disabled ? 900 : 'normal' }}
            autoComplete='off'
            step='any'
         />
      </div>
   ) : blur ? (
      <div className='flex flex-column gap-2 padding w-100 '>
         <label htmlFor='username' className='font-medium text-900' style={{ fontSize: '14px' }}>
            {label}
         </label>
         <InputText
            id={id}
            type={type}
            name={name}
            aria-describedby='username-help'
            className={`p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            placeholder={placeholder}
            value={value}
            onBlur={() => handleFocusChange()}
            onChange={(e) => handleChange(e)}
            disabled={disabled}
            style={{ color: 'black', fontWeight: disabled ? 900 : 'normal' }}
            autoComplete='off'
            step='any'
         />
      </div>
   ) : max ? (
      <div className='flex flex-column gap-2 padding w-100 '>
         <label htmlFor='username' className='font-medium text-900' style={{ fontSize: '14px' }}>
            {label}
         </label>
         <InputText
            id={id}
            type={type}
            name={name}
            aria-describedby='username-help'
            className={`p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            placeholder={placeholder}
            value={value}
            min={0}
            max={max}
            onChange={(e) => handleChange(e)}
            disabled={disabled}
            style={{ color: 'black', fontWeight: disabled ? 900 : 'normal' }}
            autoComplete='off'
            step='any'
         />
      </div>
   ) : (
      <div className='flex flex-column gap-2 padding w-100 '>
         <label htmlFor='username' className='font-medium text-900' style={{ fontSize: '14px' }}>
            {label}
         </label>
         <InputText
            id={id}
            type={type}
            name={name}
            aria-describedby='username-help'
            className={`p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e)}
            min={0}
            disabled={disabled}
            style={{ color: 'black', fontWeight: disabled ? 900 : 'normal' }}
            autoComplete='off'
            step='any'
         />
      </div>
   );
};

export const PasswordFeild = (props) => {
   const { label, name, placeholder, value, handleChange, id } = props;
   const { darkMode } = useContext(CssContext);
   return (
      <div className='flex flex-column gap-2 padding'>
         <label className='font-medium text-900' style={{ fontSize: '14px' }}>
            {label}
         </label>
         <Password
            id={id}
            name={name}
            className={`p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e)}
            toggleMask
            autoComplete='off'
         />
      </div>
   );
};
export const FormDataFeildTextArea = (props) => {
   const { label, name, placeholder, value, handleChange, type, id } = props;
   const { darkMode } = useContext(CssContext);
   return (
      <div className='flex flex-column gap-2 padding'>
         <label htmlFor='username' className='font-medium text-900' style={{ fontSize: '14px' }}>
            {label}
         </label>
         <InputTextarea
            id={id}
            autoResize
            type={type}
            name={name}
            aria-describedby='username-help'
            className={`p-inputtext-sm ${darkMode ? 'dark-input' : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e)}
            rows={5}
            cols={30}
            autoComplete='off'
         />
      </div>
   );
};

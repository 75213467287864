import axios from "axios";
import {
  CREATE_ORDER_ASSIGN_ORDER,
  GET_ORDER,
  GET_ORDER_DETAIL,
  GET_ORDER_EXCEL,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get order list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Order = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ORDER}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get order excel
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Order_Excel = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ORDER_EXCEL}`, {
      headers: { Authorization: jwt_token, Accept: "text/csv" },
      params: paramsObj,
      responseType: "blob",
    })
    .then(async (response) => {
      const data = response.data;
      const downloadLink = document.createElement("a");
      const blobUrl = window.URL.createObjectURL(data);
      downloadLink.href = blobUrl;
      downloadLink.download = "order.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(blobUrl);
      ToastMessage(response.status, response);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * get order detail data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Order_Detail = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ORDER_DETAIL}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data.payload.data);
};

/**
 * add user api call
 * @param {form data} formData
 * @returns
 */

export const Create_Order_Assign_Rider = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${CREATE_ORDER_ASSIGN_ORDER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

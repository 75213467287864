import { ImgaeBaseUri, RoleDrop, STATUS_CODE } from '../constants';
import { toast } from 'react-toastify';
import { ScaleLoader as Loader } from 'react-spinners';
import moment from 'moment';

/**
 *
 * @param {value} object
 * @param {number of length} length
 * input max length
 */

export const maxLengthCheck = (object, length) => {
   if (object.target.value.length > length) {
      object.target.value = object.target.value.slice(0, length);
   }
};

/**
 *
 * @param {redirect function} history
 * @param {status code} status
 * Logout function
 */
export const Logout = (history, status) => {
   switch (status) {
      case 401:
         history('/auth');
         break;
      default:
   }
};

/**
 * click to redirect function
 * @param {redirect function} history
 * @param {redirect path} path
 */
export const Redirect = (history, path) => {
   history(path);
};

/**
 *
 * @param {status code} status
 * @param {api pass message} message
 * @returns return message success or error
 */

export const ToastMessage = (status, message) => {
   return STATUS_CODE.SUCCESS === status
      ? toast.success(message, {
           position: toast.POSITION.BOTTOM_RIGHT,
           autoClose: 1000,
           theme: 'dark'
        })
      : toast.error(message, {
           position: toast.POSITION.BOTTOM_RIGHT,
           autoClose: 1000,
           theme: 'dark'
        });
};

/**
 *
 *
 * @param {object} obj
 * @returns return null object
 */

export const setValueNull = (obj) => {
   for (let key in obj) {
      if (Array.isArray(obj[key])) {
         let isObj = false;
         obj[key].forEach((val, index, arr) => {
            if (typeof val == 'object') {
               isObj = true;
               setValueNull(val);
            }
         });
         if (!isObj) obj[key] = [];
      } else if (['string', 'number', 'boolean'].includes(typeof obj[key])) {
         obj[key] = null;
         // obj[key] = "";
      } else if (typeof obj[key] == 'object') {
         setValueNull(obj[key]);
      }
   }
   return obj;
};

/**
 *
 *
 * @param {object} obj
 * @returns return null object
 */

export const setValueNullRedirect = (obj) => {
   for (let key in obj) {
      if (Array.isArray(obj[key])) {
         let isObj = false;
         obj[key].forEach((val, index, arr) => {
            if (typeof val == 'object') {
               isObj = true;
               setValueNull(val);
            }
         });
         if (!isObj) obj[key] = [];
      } else if (['string', 'number', 'boolean'].includes(typeof obj[key])) {
         // obj[key] = null;
         obj[key] = '';
      } else if (typeof obj[key] == 'object') {
         setValueNull(obj[key]);
      }
   }
   return obj;
};

/**
 *
 * @param {Parameters} props
 * @returns loader hide & show and daynamicly body css change
 */

export const IsLoadingResponse = (props) => {
   const { isLoading } = props;
   if (isLoading) {
      document.body.style.overflow = 'hidden';
   } else {
      document.body.style.overflow = null;
   }
   return (
      isLoading && (
         <div className='p-blockui p-component-overlay p-blockui-document' style={{ zIndex: '11010' }}>
            <Loader color='white' height={45} width={11} radius={31} margin={10} loading={true} />
         </div>
      )
   );
};

/**
 * asending and desending fucntion
 * @param {col} col
 * @param {sort order as ASD DASC} sortOrder
 * @param {sort by as example (name)} setSortBy
 * @param {orer change function} setSortOrder
 */

export const tabelSorting = (col, sortOrder, setSortBy, setSortOrder) => {
   if (sortOrder === '') {
      let found = col.find((obj) => {
         return obj.field;
      });
      setSortBy(found.field);
      setSortOrder('ASC');
   }
   if (sortOrder === 'ASC') {
      let found = col.find((obj) => {
         return obj.field;
      });
      setSortBy(found.field);
      setSortOrder('DSC');
   }
   if (sortOrder === 'DSC') {
      setSortBy();
      setSortOrder('');
   }
};

/**
 * create json formater
 * @param {json data} jsonData
 * @param {object to json} multideopdown
 */

export function convertJSONToFormData(jsonData, multideopdown) {
   if (jsonData && typeof jsonData === 'object') {
      Object.entries(jsonData).forEach(([key, value]) => {
         if (value && typeof value === 'object') {
            jsonData[key] = value.code;
         }
         if (key === 'image' || key === 'idProof') {
            jsonData[key] = value?.split(ImgaeBaseUri)?.pop();
         }
         if (multideopdown && (key === 'assignAdminIds' || key === 'assignHouse')) {
            jsonData[key] = value.map(({ code }) => code);
         }
         // if (key === "dob") {
         //   jsonData[key] = moment(value).format("DD-MM-YYYY");
         // }
      });
   }
}

/**
 * Given Value is null or ! or undefined function
 */

export const isNullish = (value) => {
   if (typeof value == 'undefined' || value == null) {
      return true;
   } else {
      return false;
   }
};

export const isEmpty = (value) => {
   let temp = {};
   Object.keys(value).forEach((key) => {
      if (!isNullish(value[key])) {
         temp[key] = value[key];
      }
   });
   return temp;
};

export const checkImageType = (variable) => {
   // Regular expressions to match base64 and URL patterns
   const base64Pattern = /^data:image\/(png|jpeg|jpg|gif);base64,/;
   const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

   if (typeof variable === 'string') {
      if (variable.match(base64Pattern)) {
         return 'base64';
      } else if (variable.match(urlPattern)) {
         return 'url';
      } else {
         return false;
      }
   } else {
      return false;
   }
};

export const randomStr = (length) => {
   let randomText = '';
   const randomString = 'ABCDEFGHIJKLMNPPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
   for (let i = 0; i < length; i++) {
      randomText += randomString.charAt(Math.floor(Math.random() * randomString.length));
   }
   return randomText;
};

export const GetRole = ({ role }) => {
   const [getRole] = RoleDrop.filter((elem) => elem.code === +role);
   return getRole?.name;
};

export const truncateHelper = (str, length) => {
   return str.length > length ? str.substring(0, length) + '...' : str;
};

export const getLocalStringTime = (time) => {
   // Input time string

   const inputTime = time.split(':');

   // Input date string
   const inputDate = moment({ hour: inputTime[0], minute: inputTime[1] })._d;
   // Create a new Date object with the combined date and time
   return inputDate;
};

export const isBase64 = (str) => {
   try {
      return btoa(atob(str)) === str;
   } catch (err) {
      return false;
   }
};

export const slugUrlConvert = ({ value }) => {
   let newValue = value.replace(/\s+/g, '-').toLowerCase();
   return newValue;
};

export const currentStartDate = ({ startDate }) => {
   const currentDate = startDate;

   // Set the time to '00:00:00.000Z'
   currentDate.setUTCHours(0, 0, 0, 0);

   // Convert the dates to ISO strings
   const currentDateString = currentDate.toISOString();

   return currentDateString;
};

export const endDateFormator = ({ endDate }) => {
   // Get the end date
   const EndDate = endDate;

   // Set the time to '23:59:59.000Z'
   EndDate.setUTCHours(23, 59, 59, 0);
   const endDateString = EndDate.toISOString();
   return endDateString;
};

/**
 * remove all spaces
 * @param {string} value
 * @returns
 */
export const removeAllSpacesInString = (value) => {
   return value.replace(/\s+/g, '');
};

export const getRandomNumbers = (length = 12) => {
   let result = '';
   for (let i = 0; i < length; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      result += randomDigit.toString();
   }
   return result;
};

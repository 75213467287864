import moment from "moment";
import React, { Fragment, useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  Logout,
  ToastMessage,
  currentStartDate,
  endDateFormator,
} from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import { useNavigate, useParams } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import RiderContext from "../../../../context/RiderContext/RiderContext";
import { get_Rider_Online_Offline } from "../../../../Apis/riderApi/RiderApi";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  RIDER_,
  RIDER_ONLINE_OFFLINE_ITEM,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { BackButton } from "../../../../reuseableComponents/buttons";

const RiderOnlineOfflineDataTabelContainer = () => {
  const { filter, setFilter } = useContext(RiderContext);
  const history = useNavigate();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [dateValue, setDateValue] = useState(null);
  const { riderId } = useParams();

  /**
   * get rider online offline List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
    search: {
      riderId,
      startDate: currentStartDate({ startDate: new Date() }),
      endDate: endDateFormator({ endDate: new Date() }),
    },
  };

  const handleChangeDate = (e) => {
    const { value } = e.target;
    setDateValue(value);
  };

  if (dateValue !== null && dateValue[0] !== null && dateValue[1] !== null) {
    paramsObj["search"] = {
      riderId,
      startDate: moment(dateValue[0]).format(),
      endDate: moment(dateValue[1]).format(),
    };
  }

  let {
    data: riderOnlineOfflineData,
    isLoading: riderOnlineOfflineLoader,
    error: riderOnlineOfflineError,
  } = useQuery(
    ["getRiderOnlineOffline", paramsObj],
    async () => await get_Rider_Online_Offline(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (riderOnlineOfflineError) {
    const { status, message } =
      riderOnlineOfflineError?.response?.data || riderOnlineOfflineError;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const handleOpenandClosePopup = () => {
    history(`${REDIRECT_PATH.RIDER}/add`);
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "startTime",
      headerName: "Start Time",
      renderCell: (params) =>
        params.row.startTime
          ? moment(params.row.startTime).format("DD MMM, YYYY hh:mm")
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "endTime",
      headerName: "End Time",
      renderCell: (params) =>
        params.row.endTime
          ? moment(params.row.endTime).format("DD MMM, YYYY hh:mm")
          : "Running",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "totalTime",
      headerName: "Total Time",
      renderCell: (params) =>
        params.row?.totalTime ? params.row?.totalTime : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY hh:mm"),
    },
  ];
  return (
    <Fragment>
      <Breadcrumb
        name={RIDER_}
        slace={SLACE}
        name1={RIDER_ONLINE_OFFLINE_ITEM}
      />
      <BackButton history={history} path={REDIRECT_PATH.RIDER} />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={riderOnlineOfflineData}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={riderOnlineOfflineLoader}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.riderOnlineHistoryId}
        addButton={false}
        search={false}
        dateFilter={true}
        date={dateValue}
        handleChangeDate={handleChangeDate}
        isRange={true}
        dateFilterPlaceholder={"Start Date - End Date"}
      />
    </Fragment>
  );
};

export default RiderOnlineOfflineDataTabelContainer;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Role } from '../constants';

const PrivateRoute = () => {
   const removeLocalstorage = () => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('role');
   };
   return localStorage.getItem('authToken') && +localStorage.getItem('role') === Role.SuperAdmin ? (
      <>
         <Outlet />
      </>
   ) : localStorage.getItem('authToken') && +localStorage.getItem('role') === Role.Sales ? (
      <>
         <Outlet />
      </>
   ) : (
      <>
         {removeLocalstorage()}
         <Navigate to='/auth' />
      </>
   );
};

export default PrivateRoute;

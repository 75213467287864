import React, { useContext } from 'react';
import { Logout, ToastMessage } from '../../../../utils/Helper';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { REPAIRING, REPAIRING_LIST, SLACE } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import { get_Deal_Of_The_Day } from '../../../../Apis/dealOfTheDayApi/DealOfTheDayApi';
import RepairingTableContainer from './RepairingDataTabelContainer';
import RepairingContext from '../../../../context/RepairingContext/RepairingContext';
import { getRepairOrder } from '../../../../Apis/repairingApi/RepairingApi';

const RepairingList = () => {
   const {
      //pagination state variables
      pageSize,
      page,
      setRecord,
      sortOrder,
      sortBy,
      filter
   } = useContext(RepairingContext);

   const history = useNavigate();

   /**
    * get repairing List
    */

   let paramsObj = {
      pageNumber: page,
      recordsPerPage: pageSize,
      sortOrder,
      sortBy
   };

   if (filter) {
      paramsObj['search'] = { productName: filter };
   }

   let { data, refetch, isLoading, error } = useQuery(
      ['getRepairOrder', paramsObj],
      async () => await getRepairOrder(paramsObj),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               setRecord(response.pager.totalRecords);
            }
         },
         refetchOnWindowFocus: false,
         keepPreviousData: true
      }
   );

   if (error) {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
      Logout(history, status);
   }

   return (
      <>
         <Breadcrumb name={REPAIRING} slace={SLACE} name1={REPAIRING_LIST} />

         <RepairingTableContainer isLoading={isLoading} data={data} refetch={refetch} />
      </>
   );
};

export default RepairingList;

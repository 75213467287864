import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Login_ } from '../Apis/AuthApi';
import { DIAL_CODE, Email, LOGIN, NUMBER, PASSWORD, TypeNumber } from '../constants';
import { LoginInput } from '../reuseable/Inputs';
import { SubmitTypeButton } from '../reuseableComponents/buttons';
import '../assets/css/Login.css';
import 'react-toastify/dist/ReactToastify.css';
import { IsLoadingResponse } from '../utils/Helper';
import { Form } from 'reactstrap';
import { useMutation } from 'react-query';
import { REDIRECT_PATH } from '../Routes';

const Login = () => {
   const history = useNavigate();

   const [formData, setformData] = useState({
      // dialCode: "",
      // phone: "",
      email: '',
      password: ''
   });

   const handleChange = (e) => {
      const { name, value } = e.target;

      setformData((prev) => {
         return {
            ...prev,
            [name]: value
         };
      });
   };

   const { mutate: Login, isLoading: LoginLoading } = useMutation((formData) => Login_(formData, history), {
      onSuccess: (response) => {
         if (response.status === 'empty') {
            setformData({
               // dialCode: "",
               // phone: "",
               email: '',
               password: ''
            });
         }
      }
   });

   const LoginToWebsite = async (e) => {
      e.preventDefault();
      // if (formData.phone.length !== 0 && formData.password.length !== 0) {
      Login(formData);
      // }
   };

   return (
      <>
         <IsLoadingResponse isLoading={LoginLoading} />
         <div className='login_background'>
            <div className='login_main'>
               <div className='login_center_container'>
                  <div className='login_content_container'>
                     <div>
                        <div className='header'>
                           <div>{LOGIN}</div>
                           <span>Please enter your details</span>
                        </div>
                        <Form onSubmit={(e) => LoginToWebsite(e)}>
                           <div className='flex flex-column'>
                              <LoginInput
                                 Label={Email}
                                 type='string'
                                 name='email'
                                 value={formData.email}
                                 onChange={handleChange}
                                 // maxlangth={3}
                              />
                              {/**  <LoginInput
                      Label={NUMBER}
                      type="number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      maxlangth={TypeNumber.PHONE}
                    />*/}
                              <LoginInput
                                 Label={PASSWORD}
                                 type='password'
                                 name='password'
                                 value={formData.password}
                                 onChange={handleChange}
                              />
                              <Link
                                 className='text-600 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors transition-duration-300 mb-3 '
                                 to={REDIRECT_PATH.FORGOT_PASSWORD}>
                                 Forgot password ?
                              </Link>
                              <SubmitTypeButton name='Submit' />
                           </div>
                        </Form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Login;

import { Dialog } from 'primereact/dialog';
import React, { useEffect } from 'react';
import { repairingPrint } from './RepairingPrint';

const ReceptModal = ({ open, searchParams, setSearchParams, repairingOrderDetails }) => {
   useEffect(() => {
      if (!Object.keys(repairingOrderDetails).length) {
         searchParams.delete('openModal');
         setSearchParams(searchParams);
      }
   }, [repairingOrderDetails]);
   console.log('repairingOrderDetails >>', repairingOrderDetails);
   return (
      <Dialog
         header={''}
         className='modal_style'
         visible={open}
         position='top'
         draggable={false}
         resizable={false}
         onHide={() => {
            searchParams.delete('openModal');
            setSearchParams(searchParams);
         }}>
         {Object.keys(repairingOrderDetails).length && (
            <div id='receptModal' dangerouslySetInnerHTML={{ __html: repairingPrint(repairingOrderDetails) }} />
         )}
      </Dialog>
   );
};

export default ReceptModal;

import axios from "axios";
import {
  CREATE_FEATURE,
  DELETE_FEATURE,
  EDIT_FEATURE,
  ENABLE_DISABLE_FEATURE,
  GET_FEATURE,
  GET_FEATURE_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get feature list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Feature = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_FEATURE}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add feature api call
 * @param {form data} formData
 * @returns
 */

export const Create_Feature = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_FEATURE}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable feature api call
 * @param {form data as isActive & userId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Feature = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_FEATURE}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { userId: formData.userId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details feature
 * @param {*} featureId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Feature = (featureId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_FEATURE_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { featureId },
    })
    .then((res) => {
      let data = res.data.payload.data;
      setFormData({
        title: data ? data?.title : null,
        subTitle: data ? data?.subTitle : null,
        order: data ? data?.order : null,
        image: data ? data?.imageUrl : null,
        imageUrl: data ? data?.imageUrl : null,
      });
      setdialogs(true);
    });
};

/**
 * update feature api call
 * @param {form data} formData
 * @param {*} featureId
 * @returns
 */

export const Put_Update_Feature = (formData, featureId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_FEATURE}`, formData, {
      headers: { Authorization: jwt_token },
      params: { featureId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete feature api call
 * @param {form data} formData
 * @param {*} featureId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Feature = (featureId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_FEATURE}`, {
      headers: { Authorization: jwt_token },
      params: { featureId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

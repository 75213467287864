import React, { Fragment, useContext, useState } from 'react';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useMutation } from 'react-query';
import { IsLoadingResponse } from '../../../../utils/Helper';
import {
   Submit,
   TYPE_FELDS,
   TaxLabel,
   NumberOfImagesPerProduct,
   TImeZoneArr,
   DefaultTimeZone,
   DateFormate,
   DefaultDateFormat,
   CompanyName,
   Address_,
   Email_,
   Mobile,
   optionsDialCode,
   WhatsApp,
   City,
   State,
   Country,
   Pin_Code,
   TaxId,
   MinOrderQty,
   MinOrderAmount,
   DeliveryCharge,
   currency,
   Currency,
   AdminPercentage,
   SkipLogin,
   StockShow,
   DefaultSortListing,
   DefaultSortOrder,
   AndroidKey,
   IosKey,
   InvoiceTerms,
   OrderOnWhatsapp,
   websiteTitle,
   seoTitle,
   seoDesc,
   seoKeyword,
   googleTag,
   facebookTag,
   schema,
   facebook,
   instagram,
   youtube,
   playStoreLink,
   appStoreLink,
   serviceOrderAmt,
   serviceLessThenCharge,
   serviceGreaterThenCharge,
   codeOnOff,
   scheduleOnOff,
   nextSlot,
   scheduleEnableDays,
   scheduledDeliveryAssignBefore,
   FileType
} from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { createOrderSettingSchema } from '../../../../utils/validationSchema';
import OrderSettingContext from '../../../../context/OrderSettingContext/OrderSettingContext';
import { Create_Order_Setting, Put_Update_Order_Setting } from '../../../../Apis/orderSettingApi/OrderSettingApi';
import CropImagePopup from '../../../CropImagePopup';
import { Upload_Image_Order_Setting } from '../../../../Apis/userApi/UserApi';
import { InputSwitch } from 'primereact/inputswitch';

const OrderSettingCreateFormPopup = (props) => {
   const {
      setFormData,
      id,
      isEdit,
      setdialogs,
      formData,
      errors,
      setErrors,
      setFilterDefaultTime,
      setFilterDefaultDate,
      setFilterDefaultMobile,
      setFilterDefaultWhatsapp,
      setFilterCurrency,
      setFilterDefaultShort,

      crop,
      setCrop,
      completedCrop,
      setCompletedCrop,
      scale,
      rotate,
      aspect
   } = useContext(OrderSettingContext);
   const { refetch, emptyData } = props;
   const [dialogModal, setdialogsModal] = useState(false);

   /**
    *
    * @param {event } e
    * create order setting & update order setting
    */

   const { mutate: createOrderSetting, isLoading: createOrderSettingLoader } = useMutation((formData) =>
      Create_Order_Setting(formData, refetch, setdialogs, emptyData)
   );

   const { mutate: updateOrderSetting, isLoading: updateOrderSettingLoader } = useMutation((formData) =>
      Put_Update_Order_Setting(formData, id, refetch, setdialogs, emptyData)
   );

   const handleOrderSettingCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createOrderSettingSchema();
         await schema.validate(formData, { abortEarly: false });
         if (id !== -1 && isEdit === false) {
            let newData = {
               ...formData,
               defaultTimeZone: formData?.defaultTimeZone ? formData?.defaultTimeZone?.code : null,
               defaultDateFormat: formData?.defaultDateFormat ? formData?.defaultDateFormat?.code : null,
               mobile: formData?.mobile ? formData?.mobile?.code : null,
               currency: formData?.currency ? formData?.currency?.code : null,
               defaultSortListing: formData?.defaultSortListing ? formData?.defaultSortListing?.code : null,
               whatsapp: formData?.whatsapp ? formData?.whatsapp?.code : null,
               companyLogo: formData?.companyLogo
                  ? formData?.companyLogo.split(process.env.REACT_APP_FILE_FOLDER).pop()
                  : null
            };
            delete newData.image;
            updateOrderSetting(newData);
         } else {
            let newData = {
               ...formData,
               defaultTimeZone: formData?.defaultTimeZone ? formData?.defaultTimeZone?.code : null,
               defaultDateFormat: formData?.defaultDateFormat ? formData?.defaultDateFormat?.code : null,
               mobile: formData?.mobile ? formData?.mobile?.code : null,
               currency: formData?.currency ? formData?.currency?.code : null,
               defaultSortListing: formData?.defaultSortListing ? formData?.defaultSortListing?.code : null,
               whatsapp: formData?.whatsapp ? formData?.whatsapp?.code : null,
               companyLogo: formData?.companyLogo
                  ? formData?.companyLogo.split(process.env.REACT_APP_FILE_FOLDER).pop()
                  : null
            };
            delete newData.image;
            createOrderSetting(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);
         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeOrderSetting = (e) => {
      const { name, value } = e.target;
      if (name === 'nextSlot') {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value > 12 ? 12 : value
            };
         });
      } else {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value
            };
         });
      }
   };

   const { mutate: uploadImage } = useMutation((file) =>
      Upload_Image_Order_Setting(file, setdialogsModal, setFormData, FileType.FEATURES)
   );

   const openImageUploadModal = (e) => {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_event) => {
         setFormData((prev) => {
            return {
               ...prev,
               image: reader.result
            };
         });
      };
      setdialogsModal(true);
   };

   return (
      <Fragment>
         <IsLoadingResponse isLoading={createOrderSettingLoader || updateOrderSettingLoader} />
         <Row>
            <Col sm='12'>
               <Card>
                  <CardBody>
                     <Form className='needs-validation' onSubmit={(e) => handleOrderSettingCreateAndUpdate(e)}>
                        <Row>
                           <div className='field mb-4 col-12 md:col-12'>
                              <div
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    marginBottom: '15px'
                                 }}>
                                 <div
                                    style={{
                                       height: '150px',
                                       width: '185px',
                                       display: 'flex',
                                       justifyContent: 'center'
                                    }}>
                                    <img
                                       src={formData.image}
                                       alt=''
                                       style={{
                                          width: '80%',
                                          height: '98%',
                                          objectFit: 'cover',
                                          borderRadius: '50%'
                                       }}
                                    />
                                 </div>
                              </div>
                              <div
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap'
                                 }}>
                                 <input
                                    className='custom-file-input'
                                    type='file'
                                    onChange={(e) => openImageUploadModal(e)}
                                 />
                                 <Button
                                    className='p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1'
                                    style={{ height: '40px' }}>
                                    <span className={`p-button-icon p-c p-button-icon-left pi pi-camera `}></span>
                                    <span className='p-button-label p-c' style={{ fontWeight: '100' }}>
                                       {'Choose'}
                                    </span>
                                 </Button>
                              </div>
                           </div>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={TaxLabel}
                                 name='taxLabel'
                                 id='taxLabel'
                                 type={TYPE_FELDS.text}
                                 placeholder={TaxLabel}
                                 value={formData.taxLabel}
                                 handleChange={handleChangeOrderSetting}
                              />
                              {errors.taxLabel && <span className='error-validation'>{errors.taxLabel}</span>}
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={NumberOfImagesPerProduct}
                                 name='numberOfImagesPerProduct'
                                 id='numberOfImagesPerProduct'
                                 type={TYPE_FELDS.number}
                                 placeholder={NumberOfImagesPerProduct}
                                 value={formData.numberOfImagesPerProduct}
                                 handleChange={handleChangeOrderSetting}
                              />
                              {errors.numberOfImagesPerProduct && (
                                 <span className='error-validation'>{errors.numberOfImagesPerProduct}</span>
                              )}
                           </Col>

                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={DefaultTimeZone}
                                 name='defaultTimeZone'
                                 id='defaultTimeZone'
                                 placeholder={DefaultTimeZone}
                                 value={formData.defaultTimeZone}
                                 handleChange={handleChangeOrderSetting}
                                 dropdown={true}
                                 option={TImeZoneArr}
                                 search={(e) => setFilterDefaultTime(e.filter)}
                              />
                              {errors.defaultTimeZone && (
                                 <span className='error-validation'>{errors.defaultTimeZone}</span>
                              )}
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={DefaultDateFormat}
                                 name='defaultDateFormat'
                                 id='defaultDateFormat'
                                 placeholder={DefaultDateFormat}
                                 value={formData.defaultDateFormat}
                                 handleChange={handleChangeOrderSetting}
                                 dropdown={true}
                                 option={DateFormate}
                                 search={(e) => setFilterDefaultDate(e.filter)}
                              />
                              {errors.defaultDateFormat && (
                                 <span className='error-validation'>{errors.defaultDateFormat}</span>
                              )}
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={CompanyName}
                                 name='companyName'
                                 id='companyName'
                                 type={TYPE_FELDS.text}
                                 placeholder={CompanyName}
                                 value={formData.companyName}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={Email_}
                                 name='email'
                                 id='email'
                                 type={TYPE_FELDS.email}
                                 placeholder={Email_}
                                 value={formData.email}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={Mobile}
                                 name='mobile'
                                 id='mobile'
                                 placeholder={Mobile}
                                 value={formData.mobile}
                                 handleChange={handleChangeOrderSetting}
                                 dropdown={true}
                                 option={optionsDialCode}
                                 search={(e) => setFilterDefaultMobile(e.filter)}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={WhatsApp}
                                 name='whatsapp'
                                 id='whatsapp'
                                 placeholder={WhatsApp}
                                 value={formData.whatsapp}
                                 handleChange={handleChangeOrderSetting}
                                 dropdown={true}
                                 option={optionsDialCode}
                                 search={(e) => setFilterDefaultWhatsapp(e.filter)}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={City}
                                 name='city'
                                 id='city'
                                 type={TYPE_FELDS.text}
                                 placeholder={City}
                                 value={formData.city}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={State}
                                 name='state'
                                 id='state'
                                 type={TYPE_FELDS.text}
                                 placeholder={State}
                                 value={formData.state}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={Country}
                                 name='country'
                                 id='country'
                                 type={TYPE_FELDS.text}
                                 placeholder={Country}
                                 value={formData.country}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={Pin_Code}
                                 name='pincode'
                                 id='pincode'
                                 type={TYPE_FELDS.number}
                                 placeholder={Pin_Code}
                                 value={formData.pincode}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={Address_}
                                 name='address'
                                 id='address'
                                 type={TYPE_FELDS.text}
                                 placeholder={Address_}
                                 value={formData.address}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={TaxId}
                                 name='taxID'
                                 id='taxID'
                                 type={TYPE_FELDS.text}
                                 placeholder={TaxId}
                                 value={formData.taxID}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={MinOrderQty}
                                 name='maxOrderQty'
                                 id='maxOrderQty'
                                 type={TYPE_FELDS.number}
                                 placeholder={MinOrderQty}
                                 value={formData.maxOrderQty}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={MinOrderAmount}
                                 name='minOrderAmount'
                                 id='minOrderAmount'
                                 type={TYPE_FELDS.number}
                                 placeholder={MinOrderAmount}
                                 value={formData.minOrderAmount}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={DeliveryCharge}
                                 name='deliveryCharge'
                                 id='deliveryCharge'
                                 type={TYPE_FELDS.number}
                                 placeholder={DeliveryCharge}
                                 value={formData.deliveryCharge}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={currency}
                                 name='currency'
                                 id='currency'
                                 placeholder={currency}
                                 value={formData.currency}
                                 handleChange={handleChangeOrderSetting}
                                 dropdown={true}
                                 option={Currency}
                                 search={(e) => setFilterCurrency(e.filter)}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={AdminPercentage}
                                 name='adminPercentage'
                                 id='adminPercentage'
                                 type={TYPE_FELDS.number}
                                 placeholder={AdminPercentage}
                                 value={formData.adminPercentage}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <div className='flex flex-column gap-2 padding'>
                                 <label
                                    htmlFor='username'
                                    className='font-medium text-900'
                                    style={{ fontSize: '14px' }}>
                                    {SkipLogin}
                                 </label>
                                 <InputSwitch
                                    checked={formData.skipLogin}
                                    onChange={(e) => handleChangeOrderSetting(e)}
                                    name='skipLogin'
                                    id='skipLogin'
                                 />
                              </div>
                           </Col>
                           <Col md='3 mb-4'>
                              <div className='flex flex-column gap-2 padding'>
                                 <label
                                    htmlFor='username'
                                    className='font-medium text-900'
                                    style={{ fontSize: '14px' }}>
                                    {StockShow}
                                 </label>
                                 <InputSwitch
                                    checked={formData.stockShow}
                                    onChange={(e) => handleChangeOrderSetting(e)}
                                    name='stockShow'
                                    id='stockShow'
                                 />
                              </div>
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={DefaultSortListing}
                                 name='defaultSortListing'
                                 id='defaultSortListing'
                                 placeholder={DefaultSortListing}
                                 value={formData.defaultSortListing}
                                 handleChange={handleChangeOrderSetting}
                                 dropdown={true}
                                 option={DefaultSortOrder}
                                 search={(e) => setFilterDefaultShort(e.filter)}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={AndroidKey}
                                 name='androidKey'
                                 id='androidKey'
                                 type={TYPE_FELDS.number}
                                 placeholder={AndroidKey}
                                 value={formData.androidKey}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={IosKey}
                                 name='iosKey'
                                 id='iosKey'
                                 type={TYPE_FELDS.number}
                                 placeholder={IosKey}
                                 value={formData.iosKey}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={InvoiceTerms}
                                 name='invoiceTerms'
                                 id='invoiceTerms'
                                 type={TYPE_FELDS.text}
                                 placeholder={InvoiceTerms}
                                 value={formData.invoiceTerms}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={OrderOnWhatsapp}
                                 name='orderOnWhatsapp'
                                 id='orderOnWhatsapp'
                                 type={TYPE_FELDS.number}
                                 placeholder={OrderOnWhatsapp}
                                 value={formData.orderOnWhatsapp}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={websiteTitle}
                                 name='websiteTitle'
                                 id='websiteTitle'
                                 type={TYPE_FELDS.text}
                                 placeholder={websiteTitle}
                                 value={formData.websiteTitle}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={seoTitle}
                                 name='seoTitle'
                                 id='seoTitle'
                                 type={TYPE_FELDS.text}
                                 placeholder={seoTitle}
                                 value={formData.seoTitle}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={seoDesc}
                                 name='seoDesc'
                                 id='seoDesc'
                                 type={TYPE_FELDS.text}
                                 placeholder={seoDesc}
                                 value={formData.seoDesc}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={seoKeyword}
                                 name='seoKeyword'
                                 id='seoKeyword'
                                 type={TYPE_FELDS.text}
                                 placeholder={seoKeyword}
                                 value={formData.seoKeyword}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={googleTag}
                                 name='googleTag'
                                 id='googleTag'
                                 type={TYPE_FELDS.text}
                                 placeholder={googleTag}
                                 value={formData.googleTag}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={facebookTag}
                                 name='facebookTag'
                                 id='facebookTag'
                                 type={TYPE_FELDS.text}
                                 placeholder={facebookTag}
                                 value={formData.facebookTag}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={schema}
                                 name='schema'
                                 id='schema'
                                 type={TYPE_FELDS.text}
                                 placeholder={schema}
                                 value={formData.schema}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <FormDataFeild
                                 label={facebook}
                                 name='facebook'
                                 id='facebook'
                                 type={TYPE_FELDS.text}
                                 placeholder={facebook}
                                 value={formData.facebook}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='4 mb-4'>
                              <FormDataFeild
                                 label={instagram}
                                 name='instagram'
                                 id='instagram'
                                 type={TYPE_FELDS.text}
                                 placeholder={instagram}
                                 value={formData.instagram}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='4 mb-4'>
                              <FormDataFeild
                                 label={'X'}
                                 name='x'
                                 id='x'
                                 type={TYPE_FELDS.text}
                                 placeholder={'X'}
                                 value={formData.x}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='4 mb-4'>
                              <FormDataFeild
                                 label={youtube}
                                 name='youtube'
                                 id='youtube'
                                 type={TYPE_FELDS.text}
                                 placeholder={youtube}
                                 value={formData.youtube}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={playStoreLink}
                                 name='playStoreLink'
                                 id='playStoreLink'
                                 type={TYPE_FELDS.text}
                                 placeholder={playStoreLink}
                                 value={formData.playStoreLink}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={appStoreLink}
                                 name='appStoreLink'
                                 id='appStoreLink'
                                 type={TYPE_FELDS.text}
                                 placeholder={appStoreLink}
                                 value={formData.appStoreLink}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={serviceOrderAmt}
                                 name='serviceOrderAmt'
                                 id='serviceOrderAmt'
                                 type={TYPE_FELDS.number}
                                 placeholder={serviceOrderAmt}
                                 value={formData.serviceOrderAmt}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={serviceLessThenCharge}
                                 name='serviceLessThenCharge'
                                 id='serviceLessThenCharge'
                                 type={TYPE_FELDS.number}
                                 placeholder={serviceLessThenCharge}
                                 value={formData.serviceLessThenCharge}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={serviceGreaterThenCharge}
                                 name='serviceGreaterThenCharge'
                                 id='serviceGreaterThenCharge'
                                 type={TYPE_FELDS.number}
                                 placeholder={serviceGreaterThenCharge}
                                 value={formData.serviceGreaterThenCharge}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='3 mb-4'>
                              <div className='flex flex-column gap-2 padding'>
                                 <label
                                    htmlFor='username'
                                    className='font-medium text-900'
                                    style={{ fontSize: '14px' }}>
                                    {codeOnOff}
                                 </label>
                                 <InputSwitch
                                    checked={formData.codeOnOff}
                                    onChange={(e) => handleChangeOrderSetting(e)}
                                    name='codeOnOff'
                                    id='codeOnOff'
                                 />
                              </div>
                           </Col>
                           <Col md='3 mb-4'>
                              <div className='flex flex-column gap-2 padding'>
                                 <label
                                    htmlFor='username'
                                    className='font-medium text-900'
                                    style={{ fontSize: '14px' }}>
                                    {scheduleOnOff}
                                 </label>
                                 <InputSwitch
                                    checked={formData.scheduleOnOff}
                                    onChange={(e) => handleChangeOrderSetting(e)}
                                    name='scheduleOnOff'
                                    id='scheduleOnOff'
                                 />
                              </div>
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={nextSlot}
                                 name='nextSlot'
                                 id='nextSlot'
                                 type={TYPE_FELDS.number}
                                 placeholder={nextSlot}
                                 value={formData.nextSlot}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={scheduleEnableDays}
                                 name='scheduleEnableDays'
                                 id='scheduleEnableDays'
                                 type={TYPE_FELDS.number}
                                 placeholder={scheduleEnableDays}
                                 value={formData.scheduleEnableDays}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={scheduledDeliveryAssignBefore}
                                 name='scheduledDeliveryAssignBefore'
                                 id='scheduledDeliveryAssignBefore'
                                 type={TYPE_FELDS.number}
                                 placeholder={scheduledDeliveryAssignBefore}
                                 value={formData.scheduledDeliveryAssignBefore}
                                 handleChange={handleChangeOrderSetting}
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col md='12 mb-4' className='btn-style'>
                              <Button
                                 label={Submit}
                                 icon='pi pi-check'
                                 loading={createOrderSettingLoader || updateOrderSettingLoader}
                                 type={TYPE_FELDS.submit}
                              />
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
         </Row>
         <CropImagePopup
            dialogModal={dialogModal}
            setdialogsModal={setdialogsModal}
            image={formData?.image}
            aspect={aspect}
            crop={crop}
            setCrop={setCrop}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
            scale={scale}
            rotate={rotate}
            setFormData={setFormData}
            uploadImage={uploadImage}
         />
      </Fragment>
   );
};

export default OrderSettingCreateFormPopup;

import React from 'react';
import { UploadCloud } from 'react-feather';
import { Alt, TYPE_FELDS } from '../../../../constants';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { Button } from 'primereact/button';

const ProductImageUpload = ({
   onChange,
   index, //image arr index
   indexAttribute, //main index
   image,
   imgAltText,
   isEdit,
   length,
   id, //image index
   handleRemove,
   handleChange,
   imgAltTextShow,
   images,
   attributeMasterId,
   attributeMasterIndex
}) => {
   return (
      <>
         <div
            className='aj-uploader flex flex-wrap justify-content-center  align-items-center  mb-3 position-relative '
            style={{ cursor: 'pointer' }}>
            <input
               className='custom-file-input-product'
               type='file'
               onChange={(e) => onChange(e, index, images, attributeMasterId, attributeMasterIndex)}
               // required={!image}
            />
            <div className='position-absolute '>{!image && <UploadCloud />}</div>{' '}
            {image && (
               <img
                  src={image ? image : ''}
                  alt=''
                  style={{
                     width: '80%',
                     height: '98%',
                     objectFit: 'contain',
                     position: 'relative',
                     top: '-105px'
                  }}
               />
            )}
         </div>

         <div className='flex gap-2 justify-content-evenly '>
            {imgAltTextShow !== false && (
               <FormDataFeild
                  label={Alt}
                  name='imgAltText'
                  id='imgAltText'
                  type={TYPE_FELDS.text}
                  placeholder={Alt}
                  value={imgAltText}
                  handleChange={(e) => handleChange(e, index)}
                  disabled={isEdit === false && true}
               />
            )}
            {length > 1 && (
               <div className='flex flex-column justify-content-center' style={{ paddingTop: '28px' }}>
                  <Button
                     icon='pi pi-trash'
                     severity='danger'
                     onClick={() =>
                        handleRemove({
                           id: id,
                           index: index,
                           isImages: images,
                           mainIndex: indexAttribute,
                           attributeMasterId: attributeMasterId,
                           attributeMasterIndex: attributeMasterIndex
                        })
                     }
                     type={TYPE_FELDS.button}
                  />
               </div>
            )}
         </div>
         {/* {!image && <span style={{ color: 'red' }}>Image upload is required</span>} */}
      </>
   );
};

export default ProductImageUpload;

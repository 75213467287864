import React, { Fragment, useContext, useState } from 'react';
import { Rider, Submit, TYPE_FELDS } from '../../../../constants';
import { useMutation, useQuery } from 'react-query';
import { createOrderAssignRiderSchema } from '../../../../utils/validationSchema';
import { IsLoadingResponse, ToastMessage } from '../../../../utils/Helper';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { get_Rider } from '../../../../Apis/riderApi/RiderApi';
import OrderContext from '../../../../context/OrderContext/OrderContext';
import { Create_Order_Assign_Rider } from '../../../../Apis/orderApi/OrderApi';

const OrderAssignToRiderForm = (props) => {
   const { setFormData, setdialogs, formData, errors, setErrors } = useContext(OrderContext);
   const { refetch, emptyField } = props;

   const [searchRider, setSearchRider] = useState('');

   /**
    *
    * @param {event } e
    * create order assign rider & update order assign rider
    */

   const { mutate: createOrderAssignRider, isLoading: createOrderAssignRiderLoader } = useMutation(
      (formData) => Create_Order_Assign_Rider(formData),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               refetch();
               setdialogs(false);
               emptyField();
               setErrors({});
               ToastMessage(response.status, response.data.message);
            }
         }
      }
   );

   const handlePincodeMasterCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createOrderAssignRiderSchema({
            slotOfType: formData.slotOffType?.code
         });
         await schema.validate(formData, { abortEarly: false });
         let newData = {
            ...formData,
            riderId: formData.riderId.code
         };
         createOrderAssignRider(newData);
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);
         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeOrderAssignRider = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => {
         return {
            ...prev,
            [name]: value
         };
      });
   };

   let paramObj = {
      showAll: true,
      search: { name: searchRider }
   };
   let { data: riderDropDown } = useQuery(['getRiderDrop', paramObj], async () => await get_Rider(paramObj), {
      keepPreviousData: true,
      refetchOnWindowFocus: false
   });

   return (
      <Fragment>
         <IsLoadingResponse isLoading={createOrderAssignRiderLoader} />
         <Row>
            <Col sm='12'>
               <Card>
                  <CardBody>
                     <Form className='needs-validation' onSubmit={(e) => handlePincodeMasterCreateAndUpdate(e)}>
                        <Row>
                           <Col md='12 mb-4'>
                              <FormDataFeild
                                 label={Rider}
                                 name='riderId'
                                 id='riderId'
                                 placeholder={Rider}
                                 value={formData.riderId}
                                 handleChange={handleChangeOrderAssignRider}
                                 dropdown={true}
                                 option={riderDropDown?.payload.data?.map((elem) => {
                                    return {
                                       code: elem?.riderId,
                                       name: elem?.userDetail?.name || '--'
                                    };
                                 })}
                                 search={(e) => setSearchRider(e.filter)}
                              />
                              {errors.riderId && <span className='error-validation'>{errors.riderId}</span>}
                           </Col>
                        </Row>
                        <Row>
                           <Col md='12 mb-4' className='btn-style'>
                              <Button
                                 label={Submit}
                                 icon='pi pi-check'
                                 loading={createOrderAssignRiderLoader}
                                 type={TYPE_FELDS.submit}
                              />
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      </Fragment>
   );
};

export default OrderAssignToRiderForm;

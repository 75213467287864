import axios from 'axios';
import {
   CREATE_REPAIRING_ORDER,
   GET_REPAIRING_ORDER,
   GET_REPAIRING_ORDER_DETAILS,
   UPDATE_REPAIRING_ORDER
} from '../../constants/ApiConstant';
import { ToastMessage } from '../../utils/Helper';
import { REDIRECT_PATH } from '../../Routes';
import { get_Products } from '../productsApi/ProductsApi';
import { get_Category_Master } from '../categoryApi/CategoryApi';
import { get_Store } from '../storeApi/StoreApi';
import { DISCOUNT_APPLY_TYPE, DISCOUNT_TYPE } from '../../constants';

export const getRepairOrder = (paramsObj) => {
   const jwt_token = localStorage.getItem('authToken');
   return axios
      .get(`${process.env.REACT_APP_API_URL}${GET_REPAIRING_ORDER}`, {
         headers: { Authorization: jwt_token },
         params: paramsObj
      })
      .then((res) => res.data);
};

export const createRepairOrder = (formData, history, emptyData) => {
   const jwt_token = localStorage.getItem('authToken');
   return axios
      .post(`${process.env.REACT_APP_API_URL}${CREATE_REPAIRING_ORDER}`, formData, {
         headers: { Authorization: jwt_token }
      })
      .then((message) => {
         emptyData();
         ToastMessage(message.status, message.data.message);
         history(`${REDIRECT_PATH.REPAIRING}`);
         return message;
      })
      .catch((error) => {
         const { status, message } = error?.response?.data || error;
         ToastMessage(status, message);
      });
};

export const getDetailsRepairOrder = (repairOrderId, history, setFormData) => {
   const jwt_token = localStorage.getItem('authToken');
   return axios
      .get(`${process.env.REACT_APP_API_URL}${GET_REPAIRING_ORDER_DETAILS}`, {
         headers: { Authorization: jwt_token },
         params: { repairOrderId }
      })
      .then(async (res) => {
         let data = res.data.payload.data;
         let paramObj = {
            showAll: true
         };

         let responseStore = await get_Store(paramObj);
         [responseStore] = responseStore?.payload?.data?.filter((elem) => elem?.storeId === data?.storeId);
         let responseProduct = await get_Products(paramObj);
         [responseProduct] = responseProduct?.payload?.data?.filter((elem) => elem?.productId === data?.productId);
         let responseCategory = await get_Category_Master(paramObj);
         [responseCategory] = responseCategory?.payload?.data?.filter((elem) => elem?.catId === data?.catId);
         let [responseDiscountType] = DISCOUNT_TYPE?.filter((elem) => elem?.code === data?.discountType);
         let [responseApplyType] = DISCOUNT_APPLY_TYPE?.filter((elem) => elem?.code === data?.applyType);
         setFormData({
            storeId: data?.storeId,
            applyType: data
               ? {
                    name: responseApplyType?.name,
                    code: responseApplyType?.code
                 }
               : null,
            discountType: data
               ? {
                    name: responseDiscountType?.name,
                    code: responseDiscountType?.code
                 }
               : null,

            discountValue: data?.discountValue,
            maxDiscountAmount: data?.maxDiscountAmount,
            minOrderAmount: data?.minOrderAmount,

            dealOfTheDay: data ? data?.dealOfTheDay : '',
            assignStores: data
               ? {
                    name: responseStore?.name,
                    code: responseStore?.storeId
                 }
               : null,
            productId: data?.productId
               ? {
                    code: responseProduct?.productId,
                    name: responseProduct?.productName
                 }
               : null,
            catId: data?.catId
               ? {
                    code: responseCategory.catId,
                    name: responseCategory.name
                 }
               : null,
            startDate: data ? [new Date(data?.startDate), new Date(data?.endDate)] : '',
            endDate: data ? data?.endDate : ''
         });
         history(`${REDIRECT_PATH.DEALOFTHEDAY}/${repairOrderId}`);
         return res;
      });
};

export const getDetailsRepairOrderPrint = (repairOrderId) => {
   const jwt_token = localStorage.getItem('authToken');
   return axios
      .get(`${process.env.REACT_APP_API_URL}${GET_REPAIRING_ORDER_DETAILS}`, {
         headers: { Authorization: jwt_token },
         params: { repairOrderId }
      })
      .then((res) => {
         return res;
      });
};

export const updateRepairOrder = (formData, id, history, emptyData) => {
   const jwt_token = localStorage.getItem('authToken');
   return axios
      .put(`${process.env.REACT_APP_API_URL}${UPDATE_REPAIRING_ORDER}`, formData, {
         headers: { Authorization: jwt_token },
         params: { repairOrderId: id }
      })
      .then((message) => {
         ToastMessage(message.status, message.data.message);
         if (history !== null) {
            history(`${REDIRECT_PATH.REPAIRING}`);
         }
         if (emptyData !== null) {
            emptyData();
         }

         return message;
      })
      .catch((error) => {
         const { status, message } = error?.response?.data || error;
         ToastMessage(status, message);
      });
};

import React, { useContext } from 'react';
import CssContext from '../context/css_context/CssContext/CssContext';
import moment from 'moment';

const Footer = (props) => {
   const { maindivSidebar, windowSize } = props;
   const { darkMode } = useContext(CssContext);
   return (
      <footer
         className={`
       ${
          windowSize.innerWidth <= 991.98
             ? 'footer-normal'
             : maindivSidebar === true
             ? 'footer-normal'
             : maindivSidebar === false
             ? 'footer-static'
             : 'footer-normal'
       }
       ${darkMode ? 'dark-footer' : ''}
          `}>
         <div>
            Copyright {moment().format('YYYY')} ©
            <a
               href='https://www.mobileaccessorieshouse.com/'
               className={darkMode ? 'dark-link' : ''}
               style={{ paddingLeft: '5px', color: 'rgb(99, 102, 241) ' }}>
               MA House Ltd
            </a>
         </div>
      </footer>
   );
};

export default Footer;

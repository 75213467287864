import React, { Fragment, useContext, useMemo, useState } from 'react';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useMutation, useQuery } from 'react-query';
import { IsLoadingResponse } from '../../../../utils/Helper';
import {
   Submit,
   TYPE_FELDS,
   Close,
   Store_,
   DialCode,
   optionsDialCode,
   Phone,
   TypeNumber,
   PasswordLabel,
   ConfirmPassword,
   Email,
   DeviceId,
   VehicleNumber,
   VehicleType,
   Address_,
   City,
   Name
} from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { createRiderPasswordSchema, createRiderSchema } from '../../../../utils/validationSchema';
import { RIDER_, RIDER_ADD, SLACE } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import { useNavigate, useParams } from 'react-router-dom';
import { REDIRECT_PATH } from '../../../../Routes';
import CropImagePopup from '../../../CropImagePopup';
import { Upload_Image } from '../../../../Apis/userApi/UserApi';
import RiderContext from '../../../../context/RiderContext/RiderContext';
import { Create_Rider, Get_Details_Rider, Put_Update_Rider } from '../../../../Apis/riderApi/RiderApi';
import { get_Store } from '../../../../Apis/storeApi/StoreApi';
import { BackButton } from '../../../../reuseableComponents/buttons';

const RiderCreateFormPopup = () => {
   const {
      setFormData,
      id,
      isEdit,
      formData,
      errors,
      setErrors,
      setId,
      setIsEdit,

      crop,
      setCrop,
      completedCrop,
      setCompletedCrop,
      scale,
      rotate,
      aspect,
      dialogModal,
      setdialogsModal
   } = useContext(RiderContext);
   const history = useNavigate();
   const [filterDrop, setFilterDrop] = useState({
      categoryFilter: '',
      productFilter: ''
   });

   let { riderId } = useParams();

   const emptyData = () => {
      setFilterDrop((prev) => {
         return {
            categoryFilter: '',
            productFilter: ''
         };
      });
      setErrors({});
      setIsEdit(true);
      setFormData((prev) => {
         return {
            ...prev,
            storeId: null,
            dialCode: null,
            phone: '',
            deviceId: '',
            vehicleNumber: '',
            vehicleType: '',
            email: '',
            address: '',
            city: '',
            password: '',
            confirmPassword: '',
            name: '',
            assignStores: null
         };
      });
   };

   /**
    *
    * @param {event } e
    * create banner slider & update banner slider
    */

   const { mutate: createRider, isLoading: createRiderLoader } = useMutation((formData) =>
      Create_Rider(formData, history, emptyData)
   );

   const { mutate: updateRider, isLoading: updateRiderLoader } = useMutation((formData) =>
      Put_Update_Rider(formData, id, history, emptyData)
   );

   const handleRiderCreateAndUpdate = async (e) => {
      e.preventDefault();

      try {
         let schema = isEdit ? createRiderSchema() : createRiderPasswordSchema();
         await schema.validate(formData, { abortEarly: false });
         if (riderId !== 'add' && riderId !== -1 && isEdit === false) {
            let newData = {
               ...formData,
               storeId: formData?.assignStores?.code ?? null,
               dialCode: formData?.dialCode?.code ?? null
            };
            delete newData.assignStores;
            delete newData.password;
            delete newData.comfirmPassword;
            updateRider(newData);
         } else {
            let newData = {
               ...formData,
               storeId: formData?.assignStores?.code ?? null,
               dialCode: formData?.dialCode?.code ?? null
            };
            delete newData.assignStores;
            createRider(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);
         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   const { mutate: uploadImage } = useMutation((file) => Upload_Image(file, setdialogsModal, setFormData));

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeRider = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => {
         return {
            ...prev,
            [name]: value
         };
      });
   };

   /**
    * if cate id is available to to refresh page to field fill api call
    */
   const { mutate: getRiderDetails } = useMutation((riderId) => Get_Details_Rider(riderId, history, setFormData));

   useMemo(() => {
      if (
         riderId !== undefined &&
         riderId !== 'add' &&
         (formData.assignStores === null || formData.assignStores === '')
      ) {
         getRiderDetails(riderId);
         setId(riderId);
         setIsEdit(false);
      } else if (riderId === 'add') {
         emptyData();
      }
      // eslint-disable-next-line
   }, [riderId]);

   /**
    * go to parent page
    */
   const redirectPrevious = () => {
      history(REDIRECT_PATH.RIDER);
      emptyData();
   };

   const search = (e) => {};

   let paramsObj = {
      showAll: true,
      search: { name: filterDrop.storeFilter }
   };
   let { data: storeDrop } = useQuery(['getStoreDropdown', paramsObj], async () => await get_Store(paramsObj), {
      keepPreviousData: true,
      refetchOnWindowFocus: false
   });

   return (
      <Fragment>
         <Breadcrumb name={RIDER_} slace={SLACE} name1={RIDER_ADD} />
         <IsLoadingResponse isLoading={createRiderLoader || updateRiderLoader} />
         <BackButton history={history} path={REDIRECT_PATH.RIDER} otherFunc={emptyData} />
         <div className='card-div'>
            <Row>
               <Col sm='12'>
                  <Card>
                     <CardBody>
                        <Form className='needs-validation' onSubmit={(e) => handleRiderCreateAndUpdate(e)}>
                           <Row>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={Store_}
                                    name='assignStores'
                                    id='assignStores'
                                    placeholder={Store_}
                                    value={formData.assignStores}
                                    handleChange={handleChangeRider}
                                    dropdown={true}
                                    option={storeDrop?.payload?.data?.map((elem) => {
                                       return {
                                          name: elem?.name,
                                          code: elem?.storeId
                                       };
                                    })}
                                    search={(e) => {
                                       setFilterDrop((prev) => {
                                          return {
                                             ...prev,
                                             storeFilter: e.filter
                                          };
                                       });
                                    }}
                                 />
                                 {errors.assignStores && (
                                    <span className='error-validation'>{errors.assignStores}</span>
                                 )}
                              </Col>
                              <Col md='6 mb-4' className='d-flex gap-4 '>
                                 <div>
                                    <FormDataFeild
                                       label={DialCode}
                                       name='dialCode'
                                       id='dialCode'
                                       placeholder={DialCode}
                                       value={formData.dialCode}
                                       handleChange={handleChangeRider}
                                       dropdown={true}
                                       option={optionsDialCode}
                                       search={search}
                                    />
                                    {errors.dialCode && <span className='error-validation'>{errors.dialCode}</span>}
                                 </div>
                                 <div style={{ width: '100%' }}>
                                    <FormDataFeild
                                       label={Phone}
                                       name='phone'
                                       id='phone'
                                       type={TYPE_FELDS.number}
                                       placeholder={Phone}
                                       value={formData.phone}
                                       handleChange={handleChangeRider}
                                       maxlangth={TypeNumber.PHONE}
                                    />
                                    {errors.phone && <span className='error-validation'>{errors.phone}</span>}
                                 </div>
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={DeviceId}
                                    name='deviceId'
                                    id='deviceId'
                                    type={TYPE_FELDS.text}
                                    placeholder={DeviceId}
                                    value={formData.deviceId}
                                    handleChange={handleChangeRider}
                                 />
                                 {errors.deviceId && <span className='error-validation'>{errors.deviceId}</span>}
                              </Col>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={VehicleNumber}
                                    name='vehicleNumber'
                                    id='vehicleNumber'
                                    type={TYPE_FELDS.text}
                                    placeholder={VehicleNumber}
                                    value={formData.vehicleNumber}
                                    handleChange={handleChangeRider}
                                 />
                                 {errors.vehicleNumber && (
                                    <span className='error-validation'>{errors.vehicleNumber}</span>
                                 )}
                              </Col>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={VehicleType}
                                    name='vehicleType'
                                    id='vehicleType'
                                    type={TYPE_FELDS.text}
                                    placeholder={VehicleType}
                                    value={formData.vehicleType}
                                    handleChange={handleChangeRider}
                                 />
                                 {errors.vehicleType && <span className='error-validation'>{errors.vehicleType}</span>}
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={Email}
                                    name='email'
                                    id='email'
                                    type={TYPE_FELDS.email}
                                    placeholder={Email}
                                    value={formData.email}
                                    handleChange={handleChangeRider}
                                 />
                                 {errors.email && <span className='error-validation'>{errors.email}</span>}
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={Name}
                                    name='name'
                                    id='name'
                                    type={TYPE_FELDS.text}
                                    placeholder={Name}
                                    value={formData.name}
                                    handleChange={handleChangeRider}
                                 />
                                 {errors.name && <span className='error-validation'>{errors.name}</span>}
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={Address_}
                                    name='address'
                                    id='address'
                                    type={TYPE_FELDS.text}
                                    placeholder={Address_}
                                    value={formData.address}
                                    handleChange={handleChangeRider}
                                 />
                                 {errors.address && <span className='error-validation'>{errors.address}</span>}
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={City}
                                    name='city'
                                    id='city'
                                    type={TYPE_FELDS.text}
                                    placeholder={City}
                                    value={formData.city}
                                    handleChange={handleChangeRider}
                                 />
                                 {errors.city && <span className='error-validation'>{errors.city}</span>}
                              </Col>
                           </Row>
                           {isEdit && (
                              <Row>
                                 <Col md='6 mb-4'>
                                    <FormDataFeild
                                       label={PasswordLabel}
                                       name='password'
                                       id='password'
                                       type={TYPE_FELDS.password}
                                       placeholder={PasswordLabel}
                                       value={formData.password}
                                       handleChange={handleChangeRider}
                                    />
                                    {errors.password && <span className='error-validation'>{errors.password}</span>}
                                 </Col>
                                 <Col md='6 mb-4'>
                                    <FormDataFeild
                                       label={ConfirmPassword}
                                       name='confirmPassword'
                                       id='confirmPassword'
                                       type={TYPE_FELDS.password}
                                       placeholder={ConfirmPassword}
                                       value={formData.confirmPassword}
                                       handleChange={handleChangeRider}
                                    />
                                    {errors.confirmPassword && (
                                       <span className='error-validation'>{errors.confirmPassword}</span>
                                    )}
                                 </Col>
                              </Row>
                           )}

                           <Row>
                              <Col md='12 mb-4' className='btn-style flex justify-content-between '>
                                 <Button
                                    label={Close}
                                    severity='danger'
                                    icon='pi pi-times'
                                    onClick={() => redirectPrevious()}
                                 />
                                 <Button
                                    label={Submit}
                                    icon='pi pi-check'
                                    loading={createRiderLoader || updateRiderLoader}
                                    type={TYPE_FELDS.submit}
                                 />
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </div>
         <CropImagePopup
            dialogModal={dialogModal}
            setdialogsModal={setdialogsModal}
            image={formData?.image}
            aspect={aspect}
            crop={crop}
            setCrop={setCrop}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
            scale={scale}
            rotate={rotate}
            setFormData={setFormData}
            uploadImage={uploadImage}
         />
      </Fragment>
   );
};

export default RiderCreateFormPopup;

import axios from 'axios';
import { LOGIN } from '../constants/ApiConstant';
import { ToastMessage } from '../utils/Helper';
import { Role } from '../constants';

export const Login_ = (data, history) => {
   return axios
      .post(`${process.env.REACT_APP_API_URL}${LOGIN}`, data)
      .then((data) => {
         const payload = data.data.payload;
         localStorage.setItem('authToken', `Bearer ${payload.token}`);
         localStorage.setItem('role', payload.data.roleId);
         localStorage.setItem('loginName', payload.data.name);
         localStorage.setItem('user', payload.data.userId);
         payload.data.roleId === Role.Store_Admin || payload.data.roleId === Role.SuperAdmin
            ? history('/')
            : payload.data.roleId === Role.Sales
            ? history('/pos')
            : history('/auth');

         if (payload.data.roleId === Role.Delivery_Boy || payload.data.roleId === Role.Customer) {
            ToastMessage(300, "Sorry, but you don't have the necessary permissions to access this feature");
            history('/auth');
            return { status: 'empty' };
         }
         ToastMessage(data.status, data.data.message);
      })
      .catch((error) => {
         const { status, message } = error?.response?.data || error;
         ToastMessage(status, message);
      });
};

import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React, { Fragment, useContext } from 'react';
import {
   Addnew,
   Currency,
   DateFormate,
   DefaultSortOrder,
   FileType,
   Submit,
   TImeZoneArr,
   TYPE_FELDS,
   optionsDialCode
} from '../../../../constants';
import { useMutation } from 'react-query';
import { truncateHelper } from '../../../../utils/Helper';
import OrderSettingContext from '../../../../context/OrderSettingContext/OrderSettingContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { Col, Row, Form } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { Button } from 'primereact/button';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useState } from 'react';
import { createOrderSettingSchema } from '../../../../utils/validationSchema';
import { CreateButtonOutline } from '../../../../reuseableComponents/buttons';
import { useNavigate } from 'react-router-dom';
import { REDIRECT_PATH } from '../../../../Routes';
import CropImagePopup from '../../../CropImagePopup';
import { Upload_Image_Order_Setting } from '../../../../Apis/userApi/UserApi';

const OrderSettingDataTabelContainer = (props) => {
   const {
      dialog,
      setFormData,
      header,
      key,
      formData,
      setErrors,
      settingId,
      //order setting edit condition state and data object state
      setFilterCurrency,
      setFilterDefaultShort,
      setFilterDefaultTime,
      setFilterDefaultDate,
      setFilterDefaultMobile,

      crop,
      setCrop,
      completedCrop,
      setCompletedCrop,
      scale,
      rotate,
      aspect
   } = useContext(OrderSettingContext);
   const { data, handleOpenandClosePopup, updateOrderSetting } = props;

   const history = useNavigate();
   const [imageDialogModal, setImageDialogsModal] = useState(false);

   const { mutate: uploadImage } = useMutation((file) => {
      Upload_Image_Order_Setting(file, setImageDialogsModal, setFormData, FileType.FEATURES);
   });
   const openImageUploadModal = (e) => {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_event) => {
         setFormData((prev) => {
            return {
               ...prev,
               image: reader.result
            };
         });
      };
      setImageDialogsModal(true);
   };

   const handleChangeOrderSetting = (e) => {
      const { name, value } = e.target;

      if (name === 'nextSlot') {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value > 12 ? 12 : value
            };
         });
      } else {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value
            };
         });
      }
   };

   const handleOrderSettingCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createOrderSettingSchema();
         await schema.validate(formData, { abortEarly: false });

         if (settingId !== -1) {
            let newData = {
               globalSettingId: settingId,
               [key]: formData[key]
            };
            updateOrderSetting(newData);
         } else {
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);
         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   const truncate = (str) => {
      return str.length > 15 ? str.substring(0, 12) + '...' : str;
   };

   const openAddform = () => {
      history(`${REDIRECT_PATH.ORDER_SETTING}/add`);
   };

   return (
      <Fragment>
         <div className='card'>
            <div className='card-div'>
               <Row>
                  <Col md='12 mb-4'>
                     <div
                        style={{
                           display: 'flex',
                           gap: '8px',
                           flexWrap: 'wrap',
                           justifyContent: 'end'
                        }}>
                        {data?.payload?.data?.length !== 1 && (
                           <CreateButtonOutline
                              click={(e) => openAddform()}
                              name={Addnew}
                              variant='primary'
                              icon='pi-user-plus'
                           />
                        )}
                     </div>
                  </Col>
                  <Col sm='12'>
                     <Card>
                        <div className='text-end mb-3'>
                           <Button
                              icon='pi pi-eye'
                              onClick={() =>
                                 history(`/order-setting/detail/${data?.payload?.data[0]?.globalSettingId}`)
                              }
                              severity='info'
                           />
                        </div>
                        <CardBody>
                           <DataTable value={data?.payload?.data} tableStyle={{ minWidth: '50rem' }}>
                              <Column
                                 field='companyLogo'
                                 header='Company Logo'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <img
                                             src={rowData.companyLogo ? rowData.companyLogo : '--'}
                                             alt=''
                                             style={{ width: '75px' }}
                                          />
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Company Logo', 'companyLogo')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='taxLabel'
                                 header='Tax Label'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.taxLabel ? rowData.taxLabel : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Tax Label', 'taxLabel')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='numberOfImagesPerProduct'
                                 header='Img Per Prod...'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncate(
                                                rowData.numberOfImagesPerProduct
                                                   ? rowData.numberOfImagesPerProduct
                                                   : '--'
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Img Per Product',
                                                         'numberOfImagesPerProduct'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='defaultTimeZone'
                                 header='Time Zone'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(
                                                rowData.defaultTimeZone ? rowData.defaultTimeZone : '--',
                                                7
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Time Zone', 'defaultTimeZone')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='defaultDateFormat'
                                 header='Date Format'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(
                                                rowData.defaultDateFormat ? rowData.defaultDateFormat : '--',
                                                6
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Date Format', 'defaultDateFormat')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='companyName'
                                 header='Company N...'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(rowData.companyName ? rowData.companyName : '--', 7)}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Company Name', 'companyName')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='email'
                                 header='Email'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.email ? rowData.email : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'Email', 'email')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='mobile'
                                 header='Mobile'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(
                                                rowData.mobile ? rowData.mobileDialCode + ' ' + rowData.mobile : '--',
                                                7
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'Mobile', 'mobile')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='whatsapp'
                                 header='Whatsapp'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(
                                                rowData.whatsapp
                                                   ? rowData.whatsappDialCode + ' ' + rowData.whatsapp
                                                   : '--',
                                                6
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Whatsapp', 'whatsapp')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='city'
                                 header='City'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.city ? rowData.city : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'City', 'city')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='state'
                                 header='State'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.state ? rowData.state : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'State', 'state')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='country'
                                 header='Country'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.country ? rowData.country : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'Country', 'country')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='pincode'
                                 header='Pincode'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{rowData.pincode ? rowData.pincode : '--'}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'Pincode', 'pincode')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='address'
                                 header='Address'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.address ? rowData.address : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'Address', 'address')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='taxID'
                                 header='TaxId'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.taxID ? rowData.taxID : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'TaxId', 'taxID')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='maxOrderQty'
                                 header='Min Order Qty'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(rowData.maxOrderQty ? rowData.maxOrderQty : '--', 7)}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Min Order Qty', 'maxOrderQty')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='minOrderAmount'
                                 header='Min Order Am...'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(rowData.minOrderAmount ? rowData.minOrderAmount : '--', 7)}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Min Order Amount',
                                                         'minOrderAmount'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='deliveryCharge'
                                 header='Delivery Cha...'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(rowData.deliveryCharge ? rowData.deliveryCharge : '--', 7)}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Delivery Charge', 'deliveryCharge')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='currency'
                                 header='Currency'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.currency ? rowData.currency : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Currency', 'currency')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='adminPercentage'
                                 header='Admin Percen...'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(
                                                rowData.adminPercentage ? rowData.adminPercentage : '--',
                                                7
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Admin Percentage',
                                                         'adminPercentage'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='skipLogin'
                                 header='Skip Login'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             <InputSwitch
                                                checked={rowData.skipLogin}
                                                onChange={(e) => handleChangeOrderSetting(e)}
                                                name='skipLogin'
                                                id='skipLogin'
                                             />
                                             {/* {rowData.skipLogin ? rowData.skipLogin : "--"} */}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Skip Login', 'skipLogin')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='stockShow'
                                 header='Stock Show'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             <InputSwitch
                                                checked={rowData.stockShow}
                                                onChange={(e) => handleChangeOrderSetting(e)}
                                                name='stockShow'
                                                id='stockShow'
                                             />
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Stock Show', 'stockShow')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='defaultSortListing'
                                 header='Default Short'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(
                                                rowData.defaultSortListing ? rowData.defaultSortListing : '--',
                                                7
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Default Short',
                                                         'defaultSortListing'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='androidKey'
                                 header='Android Key'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.androidKey ? rowData.androidKey : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Android Key', 'androidKey')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='iosKey'
                                 header='ios Key'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.iosKey ? rowData.iosKey : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'ios Key', 'iosKey')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='invoiceTerms'
                                 header='Invoice Terms'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(rowData.invoiceTerms ? rowData.invoiceTerms : '--', 7)}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Invoice Terms', 'invoiceTerms')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='orderOnWhatsapp'
                                 header='Order On Wha...'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(
                                                rowData.orderOnWhatsapp ? rowData.orderOnWhatsapp : '--',
                                                6
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Order On Whatsapp',
                                                         'orderOnWhatsapp'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='websiteTitle'
                                 header='Website Title'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(rowData.websiteTitle ? rowData.websiteTitle : '--', 7)}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Website Title', 'websiteTitle')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='seoTitle'
                                 header='seo Title'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.seoTitle ? rowData.seoTitle : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'seo Title', 'seoTitle')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='seoDesc'
                                 header='seo Desc'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.seoDesc ? rowData.seoDesc : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'seo Desc', 'seoDesc')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='seoKeyword'
                                 header='seo Keyword'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.seoKeyword ? rowData.seoKeyword : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'seo Keyword', 'seoKeyword')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='googleTag'
                                 header='Google Tag'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.googleTag ? rowData.googleTag : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Google Tag', 'googleTag')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='facebookTag'
                                 header='Facebook Tag'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(rowData.facebookTag ? rowData.facebookTag : '--', 7)}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Facebook Tag', 'facebookTag')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='schema'
                                 header='Schema'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.schema ? rowData.schema : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'Schema', 'schema')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='facebook'
                                 header='Facebook'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.facebook ? rowData.facebook : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Facebook', 'facebook')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='instagram'
                                 header='Instagram'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.instagram ? rowData.instagram : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Instagram', 'instagram')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='x'
                                 header='X'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.x ? rowData.x : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'X', 'x')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='youtube'
                                 header='Youtube'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>{truncateHelper(rowData.youtube ? rowData.youtube : '--', 7)}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() => handleOpenandClosePopup(true, 'Youtube', 'youtube')}>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='playStoreLink'
                                 header='PlayStore Link'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(rowData.playStoreLink ? rowData.playStoreLink : '--', 7)}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'PlayStore Link', 'playStoreLink')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='appStoreLink'
                                 header='AppStore Link'
                                 className='customeStyleTabel'
                                 body={(rowData) => {
                                    return (
                                       <div className='d-flex align-items-center gap-2 '>
                                          <div>
                                             {truncateHelper(rowData.appStoreLink ? rowData.appStoreLink : '--', 7)}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'AppStore Link', 'appStoreLink')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    );
                                 }}></Column>
                              <Column
                                 field='serviceOrderAmt'
                                 header='Service Order Amount'
                                 body={(rowData) => {
                                    return (
                                       <>
                                          <div>
                                             {truncate(rowData.serviceOrderAmt ? rowData.serviceOrderAmt : '200.00')}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Service Order Amount',
                                                         'serviceOrderAmt'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </>
                                    );
                                 }}></Column>
                              <Column
                                 field='serviceLessThenCharge'
                                 header='Service Less Then Charge'
                                 body={(rowData) => {
                                    return (
                                       <>
                                          <div>
                                             {truncate(
                                                rowData.serviceLessThenCharge ? rowData.serviceLessThenCharge : '10'
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Service Less Then Charge',
                                                         'serviceLessThenCharge'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </>
                                    );
                                 }}></Column>
                              <Column
                                 field='serviceGreaterThenCharge'
                                 header='Service Greater Then Charge'
                                 body={(rowData) => {
                                    return (
                                       <>
                                          <div>
                                             {truncate(
                                                rowData.serviceGreaterThenCharge
                                                   ? rowData.serviceGreaterThenCharge
                                                   : '5'
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Service Greater Then Charge',
                                                         'serviceGreaterThenCharge'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </>
                                    );
                                 }}></Column>
                              <Column
                                 field='codeOnOff'
                                 header='Code On/Off'
                                 body={(rowData) => {
                                    return (
                                       <>
                                          <div>
                                             <InputSwitch
                                                checked={rowData.codeOnOff}
                                                onChange={(e) => handleChangeOrderSetting(e)}
                                                name='codeOnOff'
                                                id='codeOnOff'
                                             />
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Code On/Off', 'codeOnOff')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </>
                                    );
                                 }}></Column>
                              <Column
                                 field='scheduleOnOff'
                                 header='Schedule On/Off'
                                 body={(rowData) => {
                                    return (
                                       <>
                                          <div>
                                             <InputSwitch
                                                checked={rowData.scheduleOnOff}
                                                onChange={(e) => handleChangeOrderSetting(e)}
                                                name='scheduleOnOff'
                                                id='scheduleOnOff'
                                             />
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Schedule On/Off', 'scheduleOnOff')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </>
                                    );
                                 }}></Column>
                              <Column
                                 field='nextSlot'
                                 header='Next Slot'
                                 body={(rowData) => {
                                    return (
                                       <>
                                          <div>{truncate(rowData.nextSlot ? rowData.nextSlot : '8')}</div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(true, 'Next Slot', 'nextSlot')
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </>
                                    );
                                 }}></Column>
                              <Column
                                 field='scheduleEnableDays'
                                 header='Schedule Enable Days'
                                 body={(rowData) => {
                                    return (
                                       <>
                                          <div>
                                             {truncate(rowData.scheduleEnableDays ? rowData.scheduleEnableDays : '7')}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Schedule Enable Days',
                                                         'scheduleEnableDays'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </>
                                    );
                                 }}></Column>
                              <Column
                                 field='scheduledDeliveryAssignBefore'
                                 header='Scheduled Delivery Assign Before'
                                 body={(rowData) => {
                                    return (
                                       <>
                                          <div>
                                             {truncate(
                                                rowData.scheduledDeliveryAssignBefore
                                                   ? rowData.scheduledDeliveryAssignBefore
                                                   : '30'
                                             )}
                                          </div>
                                          <div style={{ paddingRight: '5px' }}>
                                             <Tooltip title='Edit'>
                                                <IconButton
                                                   aria-label='edit'
                                                   onClick={() =>
                                                      handleOpenandClosePopup(
                                                         true,
                                                         'Scheduled Delivery Assign Before',
                                                         'scheduledDeliveryAssignBefore'
                                                      )
                                                   }>
                                                   <Edit style={{ color: '#7366ff' }} />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </>
                                    );
                                 }}></Column>
                           </DataTable>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </div>
         </div>

         <Dialog
            header={header}
            className='modal_style'
            visible={dialog}
            position='top'
            onHide={() => handleOpenandClosePopup(false)}
            draggable={false}
            resizable={false}>
            <Row>
               <Col sm='12'>
                  <Card>
                     <CardBody>
                        <Form className='needs-validation' onSubmit={(e) => handleOrderSettingCreateAndUpdate(e)}>
                           {key === 'companyLogo' && (
                              <>
                                 <Row>
                                    <div className='field mb-4 col-12 md:col-12'>
                                       <div
                                          style={{
                                             display: 'flex',
                                             justifyContent: 'center',
                                             flexWrap: 'wrap',
                                             marginBottom: '15px'
                                          }}>
                                          <div
                                             style={{
                                                height: '150px',
                                                width: '185px',
                                                display: 'flex',
                                                justifyContent: 'center'
                                             }}>
                                             <img
                                                src={formData.image}
                                                alt=''
                                                style={{
                                                   width: '80%',
                                                   height: '98%',
                                                   objectFit: 'cover',
                                                   borderRadius: '50%'
                                                }}
                                             />
                                          </div>
                                       </div>
                                       <div
                                          style={{
                                             display: 'flex',
                                             justifyContent: 'center',
                                             flexWrap: 'wrap'
                                          }}>
                                          <input
                                             className='custom-file-input'
                                             type='file'
                                             onChange={(e) => openImageUploadModal(e)}
                                          />
                                          <Button
                                             className='p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1'
                                             style={{ height: '40px' }}>
                                             <span
                                                className={`p-button-icon p-c p-button-icon-left pi pi-camera `}></span>
                                             <span className='p-button-label p-c' style={{ fontWeight: '100' }}>
                                                {'Choose'}
                                             </span>
                                          </Button>
                                       </div>
                                    </div>
                                 </Row>
                              </>
                           )}

                           {(key === 'taxLabel' ||
                              key === 'numberOfImagesPerProduct' ||
                              key === 'companyName' ||
                              key === 'email' ||
                              key === 'city' ||
                              key === 'state' ||
                              key === 'country' ||
                              key === 'pincode' ||
                              key === 'address' ||
                              key === 'taxID' ||
                              key === 'maxOrderQty' ||
                              key === 'minOrderAmount' ||
                              key === 'deliveryCharge' ||
                              key === 'adminPercentage' ||
                              key === 'androidKey' ||
                              key === 'iosKey' ||
                              key === 'invoiceTerms' ||
                              key === 'orderOnWhatsapp' ||
                              key === 'websiteTitle' ||
                              key === 'seoTitle' ||
                              key === 'seoDesc' ||
                              key === 'seoKeyword' ||
                              key === 'googleTag' ||
                              key === 'facebookTag' ||
                              key === 'schema' ||
                              key === 'facebook' ||
                              key === 'playStoreLink' ||
                              key === 'appStoreLink' ||
                              key === 'serviceOrderAmt' ||
                              key === 'serviceLessThenCharge' ||
                              key === 'serviceGreaterThenCharge' ||
                              key === 'scheduleEnableDays' ||
                              key === 'scheduledDeliveryAssignBefore') && (
                              <>
                                 <Row>
                                    <Col md='6 mb-4'>
                                       <FormDataFeild
                                          name={key}
                                          id={key}
                                          type={TYPE_FELDS.text}
                                          placeholder={header}
                                          value={formData[key]}
                                          handleChange={handleChangeOrderSetting}
                                       />
                                    </Col>
                                 </Row>
                              </>
                           )}

                           {key === 'nextSlot' && (
                              <>
                                 <Row>
                                    <Col md='6 mb-4'>
                                       <FormDataFeild
                                          name={key}
                                          id={key}
                                          type={TYPE_FELDS.number}
                                          placeholder={header}
                                          value={formData[key]}
                                          max={12}
                                          handleChange={handleChangeOrderSetting}
                                       />
                                    </Col>
                                 </Row>
                              </>
                           )}

                           {key === 'defaultTimeZone' && (
                              <Row>
                                 <Col md='6 mb-4'>
                                    <FormDataFeild
                                       name={key}
                                       id={key}
                                       placeholder={header}
                                       value={formData[key]}
                                       handleChange={handleChangeOrderSetting}
                                       dropdown={true}
                                       option={TImeZoneArr}
                                       search={(e) => setFilterDefaultTime(e.filter)}
                                    />
                                 </Col>
                              </Row>
                           )}
                           {key === 'defaultDateFormat' && (
                              <Row>
                                 <Col md='6 mb-4'>
                                    <FormDataFeild
                                       name={key}
                                       id={key}
                                       placeholder={header}
                                       value={formData[key]}
                                       handleChange={handleChangeOrderSetting}
                                       dropdown={true}
                                       option={DateFormate}
                                       search={(e) => setFilterDefaultDate(e.filter)}
                                    />
                                 </Col>
                              </Row>
                           )}
                           {key === 'currency' && (
                              <Row>
                                 <Col md='6 mb-4'>
                                    <FormDataFeild
                                       name={key}
                                       id={key}
                                       placeholder={header}
                                       value={formData[key]}
                                       handleChange={handleChangeOrderSetting}
                                       dropdown={true}
                                       option={Currency}
                                       search={(e) => setFilterCurrency(e.filter)}
                                    />
                                 </Col>
                              </Row>
                           )}
                           {key === 'defaultSortListing' && (
                              <Row>
                                 <Col md='6 mb-4'>
                                    <FormDataFeild
                                       name={key}
                                       id={key}
                                       placeholder={header}
                                       value={formData[key]}
                                       handleChange={handleChangeOrderSetting}
                                       dropdown={true}
                                       option={DefaultSortOrder}
                                       search={(e) => setFilterDefaultShort(e.filter)}
                                    />
                                 </Col>
                              </Row>
                           )}

                           {(key === 'mobile' || key === 'whatsapp') && (
                              <Row>
                                 <Col md='2 mb-4'>
                                    {key === 'mobile' && (
                                       <FormDataFeild
                                          name={'mobileDialCode'}
                                          id={'mobileDialCode'}
                                          placeholder={'Dial Code'}
                                          value={formData.mobileDialCode}
                                          handleChange={handleChangeOrderSetting}
                                          dropdown={true}
                                          option={optionsDialCode}
                                          search={(e) => setFilterDefaultMobile(e.filter)}
                                       />
                                    )}
                                    {key === 'whatsapp' && (
                                       <FormDataFeild
                                          name={'whatsappDialCode'}
                                          id={'whatsappDialCode'}
                                          placeholder={'Dial Code'}
                                          value={formData.whatsappDialCode}
                                          handleChange={handleChangeOrderSetting}
                                          dropdown={true}
                                          option={optionsDialCode}
                                          search={(e) => setFilterDefaultMobile(e.filter)}
                                       />
                                    )}
                                 </Col>
                                 <Col md='4 mb-4'>
                                    <FormDataFeild
                                       name={key}
                                       id={key}
                                       type={TYPE_FELDS.mobile}
                                       placeholder={header}
                                       value={formData[key]}
                                       handleChange={handleChangeOrderSetting}
                                    />
                                 </Col>
                              </Row>
                           )}
                           {(key === 'skipLogin' ||
                              key === 'stockShow' ||
                              key === 'codeOnOff' ||
                              key === 'scheduleOnOff') && (
                              <Row>
                                 <Col md='6 mb-4'>
                                    <div className='flex flex-column gap-2 padding'>
                                       <InputSwitch
                                          checked={formData[key]}
                                          onChange={(e) => handleChangeOrderSetting(e)}
                                          name={key}
                                          id={key}
                                       />
                                    </div>
                                 </Col>
                              </Row>
                           )}
                           {(key === 'instagram' || key === 'x' || key === 'youtube') && (
                              <Row>
                                 <Col md='4 mb-4'>
                                    <FormDataFeild
                                       name={key}
                                       id={key}
                                       type={TYPE_FELDS.text}
                                       placeholder={header}
                                       value={formData[key]}
                                       handleChange={handleChangeOrderSetting}
                                    />
                                 </Col>
                              </Row>
                           )}
                           <Row>
                              <Col md='12 mb-4' className='btn-style'>
                                 <Button label={Submit} icon='pi pi-check' type={TYPE_FELDS.submit} />
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </Dialog>

         <CropImagePopup
            dialogModal={imageDialogModal}
            setdialogsModal={setImageDialogsModal}
            image={formData?.image}
            aspect={aspect}
            crop={crop}
            setCrop={setCrop}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
            scale={scale}
            rotate={rotate}
            setFormData={setFormData}
            uploadImage={uploadImage}
         />
      </Fragment>
   );
};

export default OrderSettingDataTabelContainer;

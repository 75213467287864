import React, { useContext, useState } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  ORDER_,
  ORDER_ITEM,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import OrderContext from "../../../../context/OrderContext/OrderContext";
import OrderDataTabelContainer from "./OrderDataTabelContainer";
import { get_Order } from "../../../../Apis/orderApi/OrderApi";
import Modal from "../../../../reuseableComponents/Modal";
import OrderAssignToRiderForm from "./OrderAssignToRiderForm";
import {
  CreateAssignOrderToRider,
  UpdateAssignOrderToRider,
} from "../../../../constants";
import moment from "moment";
import { BackButton } from "../../../../reuseableComponents/buttons";
import CssContext from "../../../../context/css_context/CssContext/CssContext";

const OrdersList = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //pincode master edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
    setErrors,
  } = useContext(OrderContext);
  const { isDashboardRedirect, setIsDashboardRedirect } =
    useContext(CssContext);

  const [dates, setDates] = useState([]);

  const history = useNavigate();

  const emptyField = () => {
    setFormData({
      orderId: "",
      riderId: null,
    });
  };

  /**
   * get rider List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };

  if (filter.length) {
    paramsObj["search"] = { name: filter };
  }
  if (dates?.length === 2 && dates && dates !== null && dates !== undefined) {
    paramsObj["search"] = {
      ...paramsObj.search,
      startDate: moment(dates[0]).format(),
      endDate: moment(dates[1]).format(),
    };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getOrder", paramsObj],
    async () => await get_Order(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup delivery off day
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyField();
      setErrors({});
    } else {
      setdialogs(condition);
      emptyField();
      setErrors({});
    }
  };

  const prev = () => {
    setIsDashboardRedirect({
      isShow: false,
      redirect: "",
    });
  };
  return (
    <>
      <Breadcrumb name={ORDER_} slace={SLACE} name1={ORDER_ITEM} />
      {isDashboardRedirect?.isShow && (
        <BackButton
          history={history}
          path={isDashboardRedirect.redirect}
          otherFunc={prev}
        />
      )}
      <OrderDataTabelContainer
        isLoading={isLoading}
        data={data}
        refetch={refetch}
        dates={dates}
        setDates={setDates}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateAssignOrderToRider : UpdateAssignOrderToRider}
        Component={
          <OrderAssignToRiderForm refetch={refetch} emptyField={emptyField} />
        }
      />
    </>
  );
};

export default OrdersList;

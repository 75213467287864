import React, { useState } from "react";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  RIDER_,
  RIDER_LOCATION,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../../../reuseableComponents/buttons";
import { REDIRECT_PATH } from "../../../../Routes";
import riderIco from "./delivery.png";

const RiderLocation = () => {
  const { latitude, longitude } = useParams();
  const [address, setAddress] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const history = useNavigate();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAp5NJa0SAKm2pvfb3OaO7Q8asUfV_yGFs",
  });

  const handleAddress = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    // Reverse Geocoding to get the address
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setAddress(results[0].formatted_address);
        }
      }
    });
    setIsOpen(true);
  };

  return (
    <>
      <Breadcrumb name={RIDER_} slace={SLACE} name1={RIDER_LOCATION} />
      <BackButton history={history} path={REDIRECT_PATH.RIDER} />
      <div className="tabel-contant">
        <div className={`tabelContent`}>
          {!isLoaded ? (
            <p>loading ... </p>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              mapContainerStyle={{ width: "100%", height: "70vh" }}
              center={{ lat: +latitude, lng: +longitude }}
              zoom={20}
            >
              <Marker
                position={{
                  lat: +latitude,
                  lng: +longitude,
                }}
                onClick={(e) => handleAddress(e)}
                icon={riderIco}
              >
                {isOpen && (
                  <InfoWindow
                    onCloseClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <p>{address}</p>
                  </InfoWindow>
                )}
              </Marker>
            </GoogleMap>
          )}
        </div>
      </div>
    </>
  );
};

export default RiderLocation;

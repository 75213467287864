export const SLACE = '/';
export const GUEST_HOUSE = 'Guest House';
export const GUEST_HOUSE_MASTER = 'Guest House';
export const DASHBOARD = 'Dashboard';
export const E_COMMERCE = 'E-commerce';
export const USER = 'User';
export const USER_LIST = 'User List';
export const USER_Detail = 'User Detail';
export const BRAND = 'Brand';
export const ATTRIBUTE = 'Attribute Master';
export const ATTRIBUTE_List = 'Attribute Master List';
export const ATTRIBUTE_CHID_ = 'Attribute';
export const ATTRIBUTE_List_ = 'Attribute List';
export const CATEGORY_ATTRIBUTE_MAP = 'Category Attribute Map';
export const CATEGORY_ATTRIBUTE_MAP_List = 'Category Attribute Map List';
export const FEATURE = 'Feature';
export const DELIVERY_OFF_DAY = 'Delivery Off Day';
export const PINCODE = 'Pincode';
export const STORE = 'Store';
export const DELIVERY_SLOT_ = 'Delivery Slot';
export const DETAILS_PAGE = 'Details Page';
export const ORDER_SETTING_ = 'Global Setting';
export const ORDER_SETTING_LIST = 'Global Setting List';
export const ORDER_SETTING_Details = 'Global Setting Details';
export const STORE_LIST = 'Store List';
export const DETAILS_PAGE_LIST = 'Details Page List';
export const DETAILS_MASTER_PAGE_LIST = 'Details Heading List';
export const BRAND_LIST = 'Brand List';
export const FEATURE_LIST = 'Feature List';
export const DELIVERY_OFF_DAY_LIST = 'Delivery Off List';
export const PINCODE_LIST = 'Pincode List';
export const DELIVERY_PINCODE_LIST = 'Delivery Pincode List';
export const PRODUCT = 'Products';
export const PRODUCT_LIST = 'Product List';
export const PRODUCT_TAG = 'Product Tag List';
export const PRODUCT_TAG_ADD = 'Product Tag Add';
export const PRODUCT_STOCK_LIST = 'Stock List';
export const CATEGORY_ = 'Category';
export const BANNER_SLIDER_ = 'Sliders';
export const BANNER_SLIDER_ADD = 'Slider Add';
export const PROMOCODE_ = 'Promo Codes';
export const PROMOCODE_ADD = 'Promo Code Add';
export const DEALOFTHEDAY_ = 'Deal of the Days';
export const DEALOFTHEDAY_ADD = 'Deal of the Day Add';
export const REPAIRING_ADD = 'Repairing Add';
export const RIDER_ADD = 'Rider Add';
export const CATEGORY_LIST = 'Category List';
export const CATEGORY_ADD_ = 'Category Add';
export const PRODUCT_ADD = 'Product Add';
export const ROOM_CATEGORY = 'Room Category';
export const ROOM_CATEGORY_LIST = 'Room Category List';
export const GUEST_MASTER_LIST = 'Guest List';
export const GUEST_MASTER_ADD = 'Guest Add';
export const RIDER_ = 'Riders';
export const ORDER_ = 'Orders';
export const REPAIRING = 'Repairing';

/**
 * sidebar title and items name
 */

//title
export const DASHBOARD_TITLE = 'DASHBOARD';
export const USER_TITLE = 'User';
export const BRAND_TITLE = 'Brand';
export const STORE_TITLE = 'Store';
export const DETAILS_PAGE_TITLE = 'Details Page';
export const ORDER_SETTING = 'Global Setting';
export const FEATURE_ = 'Feature';
export const INQUIRY = 'Inquiry';
export const ORDERS = 'Orders';
export const ATTRIBUTE_ = 'Attributes';
export const PRODUCTS = 'Products';
export const CATEGORY = 'Category';
export const BANNER_SLIDER = 'Banner / Slider';
export const PROMOCODE = 'Promo Code';
export const DEALOFTHEDAY = 'Deal of the Day';
export const GUEST_HOUESE_TITLE = 'GUEST HOUSE';
export const ROOMS_TITLE = 'ROOMS';
export const RIDER = 'Rider';
export const DELIVERY_SLOT = 'Delivery Slot';
export const DELIVERY_OFF_DAY_ = 'Delivery Off Day';

//items
export const DASHBOARD_ITEM = 'Dashboard';
export const USER_ITEM = 'User List';
export const BRAND_ITEM = 'Brand List';
export const STORE_ITEM = 'Store List';
export const DETAILS_PAGE_ITEM = 'Details Page List';
export const DETAILS_MASTER_PAGE_ITEM = 'Details Heading List';
export const ORDERS_ITEM = 'Orders List';
export const ATTRIBUTE_ITEM = 'Attribute Master List';
export const ATTRIBUTE_ITEM_ = 'Attribute List';
export const CATEGORY_ATTRIBUTE_ITEM_ = 'Cat Attribute Map List';
export const PRODUCTS_ITEM = 'Products List';
export const PRODUCTS_ADD = 'Products Add';
export const PRODUCTS_STOCK_ITEM = 'Stock List';
export const PRODUCTS_STOCK__ADD = 'Stock Add';
export const PRODUCTS_TAG_ITEM = 'Tag List';
export const PRODUCTS_TAG_ADD = 'Tag Add';
export const CATEGORY_ITEM = 'Category List';
export const BANNER_SLIDER_ITEM = 'Sliders List';
export const BANNER_BANNER_LIST = 'Banners List';
export const PROMOCODE_LIST = 'Promo Code List';
export const REPAIRING_LIST = 'Repairing List';
export const POS = 'POS';

export const DEALOFTHEDAY_LIST = 'Deal of the Day List';
export const ORDER_sETTING_LIST = 'Global Setting List';
export const FEATURE_ITEM = 'Feature List';
export const INQUIRY_ITEM = 'Inquiry List';
export const CATEGORY_ADD = 'Category Add';
export const GUEST_HOUESE_MASTER_ITEM = 'Guest House';
export const GUEST_ITEM = 'Guest';
export const GUEST_LIST_ITEM = 'Guest List';
export const GUEST_ADD_ITEM = 'Guest Add';
export const ROOM_CATEGORY_ITEM = 'Room Category';
export const ROOM_FACILITY_ITEM = 'Room Facilities';
export const ROOMS_ITEM = 'Rooms';
export const ROOM_ALLOCATION = 'Room Allocat';
export const RIDER_LIST = 'Rider List';
export const DELIVERY_SLOT_LIST = 'Delivery Slot List';
export const DELIVERY_OFF_DAY_LIST_ = 'Delivery Off Day List';
export const PINCODE_LIST_ = 'Pincode List';
export const RIDER_ITEM = 'Riders List';
export const RIDER_LOCATION = 'Riders Location';
export const STORE_CLUSTER = 'Store Cluster';
export const RIDER_DELIVERY_LOCATION = 'Riders Delivery Location';
export const RIDER_ONLINE_OFFLINE_ITEM = 'Riders Online Offline List';
export const STORE_WALLET_HISTORY_ITEM = 'Store Wallet History List';
export const RIDER_DATE_WISE_DELIVERED_ITEM = 'Riders Date Wise Delivered List';
export const ORDER_ITEM = 'Orders List';
export const ORDER_DETAIL = 'Orders Detail';

import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import SweetConfirmation from '../../../../../reuseable/sweetConfirmation';
import { ToastMessage } from '../../../../../utils/Helper';

const CartList = ({ elem, createOrderCart, deleteOrderCart, createOrderLoader, userId }) => {
   return (
      <div className='flex flex-column lg:flex-row flex-wrap lg:align-items-center py-2 mt-3 surface-border'>
         <img
            src={elem.productDetail?.defaultImage?.imagePath}
            className='w-8rem h-8rem flex-shrink-0 mb-3'
            alt='product'
            style={{ maxHeight: '50px', maxWidth: '50px' }}
         />
         <div className='flex-auto lg:ml-3'>
            <div className='flex align-items-center justify-content-between flex-wrap'>
               <span
                  className='text-900 font-bold overflow-hidden text-truncate'
                  style={{ width: '100px' }}
                  id={`UncontrolledTooltipExample${elem.cartId}`}>
                  {elem?.productDetail?.productName}
               </span>
               <UncontrolledTooltip placement='bottom' target={`UncontrolledTooltipExample${elem.cartId}`}>
                  {elem?.productDetail?.productName}
               </UncontrolledTooltip>
               <span className='text-900 font-bold'>£{elem?.productDetail?.sellPrice}</span>
               <div className='flex'>
                  <span
                     className='border-1 surface-border border-round p-inputnumber p-component p-inputwrapper p-inputwrapper-filled p-inputnumber-buttons-horizontal'
                     data-pc-name='inputnumber'
                     data-pc-section='root'>
                     <input
                        className='p-inputtext p-component p-filled p-inputnumber-input w-2rem text-center py-2 px-1 border-transparent outline-none shadow-none p-inputnumber-input w-2rem text-center py-2 px-1 border-transparent outline-none shadow-none'
                        role='spinbutton'
                        inputmode='numeric'
                        min='0'
                        aria-valuemin='0'
                        aria-valuenow='1'
                        data-pc-name='inputtext'
                        data-pc-section='root'
                        type='text'
                        value={elem?.qty}
                     />
                     <button
                        type='button'
                        className='p-button-text text-600 hover:text-primary py-1 px-1 p-inputnumber-button p-inputnumber-button-up p-button p-button-icon-only p-component'
                        tabindex='-1'
                        aria-hidden='true'
                        data-pc-section='incrementbutton'
                        onClick={() => {
                           if (!userId) {
                              ToastMessage(1, 'Please Select user after click to place order !');
                              return;
                           }
                           createOrderCart({
                              productId: elem?.productId,
                              qty: 1,
                              prodType: elem?.prodType
                           });
                        }}
                        disabled={createOrderLoader}>
                        <span className='p-button-icon pi pi-plus' data-pc-section='incrementicon'></span>
                        <span
                           role='presentation'
                           aria-hidden='true'
                           className='p-ink'
                           data-pc-name='ripple'
                           data-pc-section='root'
                           style={{ height: '42px', width: '42px' }}></span>
                     </button>
                     <button
                        type='button'
                        className='p-button-text text-600 hover:text-primary py-1 px-1 p-inputnumber-button p-inputnumber-button-down p-button p-button-icon-only p-component'
                        tabindex='-1'
                        aria-hidden='true'
                        data-pc-section='decrementbutton'
                        onClick={() => {
                           createOrderCart({
                              productId: elem?.productId,
                              qty: -1,
                              prodType: elem?.prodType
                           });
                        }}
                        disabled={createOrderLoader}>
                        <span className='p-button-icon pi pi-minus' data-pc-section='decrementicon'></span>
                        <span
                           role='presentation'
                           aria-hidden='true'
                           className='p-ink'
                           data-pc-name='ripple'
                           data-pc-section='root'
                           style={{ height: '42px', width: '42px' }}></span>
                     </button>
                  </span>
                  <button
                     className='p-button p-component p-button-icon-only p-button-text p-button-rounded'
                     data-pc-name='button'
                     data-pc-section='root'
                     onClick={async () => {
                        let { isConfirmed } = await SweetConfirmation();
                        if (isConfirmed) {
                           deleteOrderCart({ cartId: elem?.cartId });
                        }
                     }}>
                     <span className='p-button-icon p-c pi pi-trash' data-pc-section='icon'></span>
                     <span className='p-button-label p-c' data-pc-section='label'>
                        &nbsp;
                     </span>
                     <span
                        role='presentation'
                        aria-hidden='true'
                        className='p-ink'
                        data-pc-name='ripple'
                        data-pc-section='root'
                        style={{ height: '42px', width: '42px' }}></span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CartList;

import React, { useState } from 'react';
import RepairingContext from './RepairingContext';

const RepairingState = (props) => {
   /**pagination states */
   const [pageSize, setPageSize] = useState(10);
   const [page, setPage] = useState(1);
   const [record, setRecord] = useState(0);
   const [sortOrder, setSortOrder] = useState('');
   const [sortBy, setSortBy] = useState('name');
   const [filter, setFilter] = useState('');

   /**brand master edit time condition and data object state */
   const [dialog, setdialogs] = useState(false);
   const [isEdit, setIsEdit] = useState(true);
   const [id, setId] = useState(null);
   const [dealId, setDealId] = useState('');
   const [formData, setFormData] = useState({
      userId: null,
      productType: null,
      makeModal: null,
      imeiNo: null,
      network: null,
      visibleDamage: null,
      checklist: [],
      estimateCost: null,
      depositPaid: null,
      exactReportedFault: null,
      actualCost: null,
      repairCompletedBy: null
   });
   const [fileType, setFileType] = useState('');
   const [yes, setYes] = useState(false);
   const [errors, setErrors] = useState({});

   const [crop, setCrop] = useState();
   const [completedCrop, setCompletedCrop] = useState();
   const [scale, setScale] = useState(1);
   const [rotate, setRotate] = useState(0);
   const [aspect, setAspect] = useState(2.95 / 1);
   const [base64, setBase64] = useState('');
   const [imageModal, setImageModal] = useState(false);
   const [imageType, setImageType] = useState('');
   const [dialogModal, setdialogsModal] = useState(false);

   return (
      <RepairingContext.Provider
         value={{
            pageSize,
            setPageSize,
            page,
            setPage,
            record,
            setRecord,
            sortOrder,
            setSortOrder,
            sortBy,
            setSortBy,
            filter,
            setFilter,
            dialog,
            setdialogs,
            isEdit,
            setIsEdit,
            id,
            setId,
            formData,
            setFormData,
            yes,
            setYes,
            errors,
            setErrors,

            crop,
            setCrop,
            completedCrop,
            setCompletedCrop,
            scale,
            setScale,
            rotate,
            setRotate,
            aspect,
            setAspect,
            base64,
            setBase64,
            imageModal,
            setImageModal,
            imageType,
            setImageType,
            dialogModal,
            setdialogsModal,
            fileType,
            setFileType,
            dealId,
            setDealId
         }}>
         {props.children}
      </RepairingContext.Provider>
   );
};

export default RepairingState;

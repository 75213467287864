import React, { Fragment, useContext, useMemo, useState } from 'react';
import { FormDataFeild, FormDataFeildTextArea } from '../../../../reuseable/Inputs';
import { useMutation, useQuery } from 'react-query';
import { IsLoadingResponse, setValueNull } from '../../../../utils/Helper';
import {
   Submit,
   TYPE_FELDS,
   Close,
   Product,
   Category_Name,
   Caption,
   BANNER_SLIDER_TYPE,
   TYPE_,
   START_END_DATE,
   Name,
   FileType
} from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { createBannerSliderSchema } from '../../../../utils/validationSchema';
import { BANNER_SLIDER_, BANNER_SLIDER_ADD, SLACE } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import { get_Category_Master } from '../../../../Apis/categoryApi/CategoryApi';
import { useNavigate, useParams } from 'react-router-dom';
import { REDIRECT_PATH } from '../../../../Routes';
import CropImagePopup from '../../../CropImagePopup';
import { Upload_Image } from '../../../../Apis/userApi/UserApi';
import BannerSliderContext from '../../../../context/BannerSliderContext/BannerSliderContext';
import { UploadCloud } from 'react-feather';
import {
   Create_Banner_Slider,
   Get_Details_Banner_Slider,
   Put_Update_Banner_Slider
} from '../../../../Apis/bannerSliderApi/BannerSliderApi';
import { get_Products } from '../../../../Apis/productsApi/ProductsApi';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { BackButton } from '../../../../reuseableComponents/buttons';
import CssContext from '../../../../context/css_context/CssContext/CssContext';

const BannerSliderCreateFormPopup = () => {
   const {
      setFormData,
      id,
      isEdit,
      formData,
      errors,
      setErrors,
      setId,
      setIsEdit,

      crop,
      setCrop,
      completedCrop,
      setCompletedCrop,
      scale,
      rotate,
      aspect,
      dialogModal,
      setdialogsModal,
      setAspect,
      fileType,
      setFileType,
      selectedType,
      setSelectedType
   } = useContext(BannerSliderContext);
   const { isDashboardRedirect, setIsDashboardRedirect } = useContext(CssContext);
   const history = useNavigate();
   const [filterDrop, setFilterDrop] = useState({
      categoryFilter: '',
      productFilter: ''
   });

   let { sliderBannerId } = useParams();

   const emptyData = () => {
      setFilterDrop((prev) => {
         return {
            categoryFilter: '',
            productFilter: ''
         };
      });
      setFormData((prev) => {
         return {
            ...prev,
            productId: '',
            catId: '',
            image: '',
            name: '',
            caption: '',
            type: '',
            startDate: '',
            endDate: '',
            order: ''
         };
      });
      setFileType('');
   };

   let paramsProductObj = {
      showAll: true,
      search: { name: filterDrop.productFilter }
   };
   let { data: productDrop } = useQuery(
      ['getAddBannerProductDrop', paramsProductObj],
      async () => await get_Products(paramsProductObj),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false
      }
   );

   let paramsCategoryObj = {
      showAll: true,
      search: { name: filterDrop.categoryFilter }
   };
   let { data: categoryDrop } = useQuery(
      ['getAddBannerCategoryDrop', paramsCategoryObj],
      async () => await get_Category_Master(paramsCategoryObj),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false
      }
   );

   /**
    *
    * @param {event } e
    * create banner slider & update banner slider
    */

   const { mutate: createBannerSlider, isLoading: createBannerSliderLoader } = useMutation((formData) =>
      Create_Banner_Slider(formData, history, emptyData)
   );

   const { mutate: updateBannerSlider, isLoading: updateBannerSliderLoader } = useMutation((formData) =>
      Put_Update_Banner_Slider(formData, id, history, emptyData)
   );

   const handleBannerSliderCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createBannerSliderSchema(selectedType);
         await schema.validate(formData, { abortEarly: false });
         if (sliderBannerId !== 'add' && sliderBannerId !== -1 && isEdit === false) {
            let newData = {
               ...formData,
               catId: selectedType === '1' ? null : formData?.catId?.code ?? null,
               type: formData?.type?.code,
               productId: selectedType === '1' ? formData?.productId?.code ?? null : null,
               startDate: new Date(formData?.startDate[0]),
               endDate: new Date(formData?.startDate[1]),
               imageUrl: formData?.image ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop() : null
            };

            delete newData.order;
            delete newData.image;
            updateBannerSlider(newData);
         } else {
            let newData = {
               ...formData,
               catId: selectedType === '1' ? setValueNull : formData?.catId?.code ?? null,
               type: formData?.type?.code,
               productId: selectedType === '1' ? formData?.productId?.code ?? null : null,
               startDate: new Date(formData?.startDate[0]),
               endDate: new Date(formData?.startDate[1]),
               imageUrl: formData?.image ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop() : null
            };
            delete newData.order;
            delete newData.image;
            createBannerSlider(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);

         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   const { mutate: uploadImage } = useMutation((file) => Upload_Image(file, setdialogsModal, setFormData, fileType));

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeBannerSlider = (e) => {
      const { name, value } = e.target;
      if (name === 'type') {
         if (value.code === 1) {
            setAspect(2.95 / 1);
            setFileType(FileType.SLIDER);
         } else if (value.code === 2) {
            setAspect(1.71 / 1);
            setFileType(FileType.BANNER);
         }
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value
            };
         });
      } else if (name === 'slugUrl') {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value.replace(/\s+/g, '-')
            };
         });
      } else {
         setFormData((prev) => {
            return {
               ...prev,
               [name]: value
            };
         });
      }
   };

   /**
    * if cate id is available to to refresh page to field fill api call
    */
   const { mutate: getBannerSliderDetails } = useMutation(
      (sliderBannerId) => Get_Details_Banner_Slider(sliderBannerId, history, setFormData, setAspect),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               setId(sliderBannerId);
               setIsEdit(false);
            }
         }
      }
   );

   useMemo(() => {
      if (sliderBannerId !== undefined && sliderBannerId !== 'add' && formData.name !== '') {
         getBannerSliderDetails(sliderBannerId);
      } else if (sliderBannerId === 'add') {
         emptyData();
      }
      // eslint-disable-next-line
   }, [sliderBannerId]);

   /**
    * go to parent page
    */
   const redirectPrevious = () => {
      history(REDIRECT_PATH.SLIDER);
      emptyData();
   };

   const openImageUploadModal = (e) => {
      let reader = new FileReader();
      setFormData((prev) => {
         return {
            ...prev,
            image: e.target.files[0]
         };
      });

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_event) => {
         setFormData((prev) => {
            return {
               ...prev,
               image: reader.result
            };
         });
      };
      setdialogsModal(true);
   };

   const handleChangeType = (e) => {
      const { value } = e.target;
      setSelectedType(value);
   };

   return (
      <Fragment>
         <Breadcrumb name={BANNER_SLIDER_} slace={SLACE} name1={BANNER_SLIDER_ADD} />
         <IsLoadingResponse isLoading={createBannerSliderLoader || updateBannerSliderLoader} />
         <BackButton
            history={history}
            path={isDashboardRedirect.redirect}
            otherFunc={() =>
               setIsDashboardRedirect({
                  isShow: false,
                  redirect: ''
               })
            }
         />
         <div className='card-div'>
            <Row>
               <Col sm='12'>
                  <Card>
                     <CardBody>
                        <Form className='needs-validation' onSubmit={(e) => handleBannerSliderCreateAndUpdate(e)}>
                           <Row>
                              <Col md='4 mb-2'>
                                 <FormDataFeild
                                    label={TYPE_}
                                    name='type'
                                    id='type'
                                    placeholder={TYPE_}
                                    value={formData.type}
                                    handleChange={handleChangeBannerSlider}
                                    dropdown={true}
                                    filter={false}
                                    option={BANNER_SLIDER_TYPE}
                                 />
                                 {errors.type && <span className='error-validation'>{errors.type}</span>}
                              </Col>
                              <Col md='4 mb-4'>
                                 <FormDataFeild
                                    label={Name}
                                    name='name'
                                    id='name'
                                    type={TYPE_FELDS.text}
                                    placeholder={Name}
                                    value={formData.name}
                                    handleChange={handleChangeBannerSlider}
                                 />
                                 {errors.name && <span className='error-validation'>{errors.name}</span>}
                              </Col>
                              <Col md='2 mb-2'>
                                 <div className='flex flex-column gap-2 padding'>
                                    <label
                                       htmlFor='username'
                                       className='font-medium text-900'
                                       style={{ fontSize: '14px' }}>
                                       {'Hyper Type'}
                                    </label>
                                    <div className='flex gap-5   '>
                                       <div className='flex align-items-center'>
                                          <RadioButton
                                             inputId='hyperType'
                                             name='hyperType'
                                             value='1'
                                             onChange={(e) => handleChangeType(e)}
                                             checked={selectedType === '1'}
                                          />
                                          <label htmlFor='ingredientSimple' className='ml-2'>
                                             Product
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              </Col>
                              <Col md='2 mb-2'>
                                 <div className='flex flex-column gap-2 padding'>
                                    <label
                                       htmlFor='username'
                                       className='font-medium text-900'
                                       style={{ fontSize: '14px' }}>
                                       &nbsp;
                                    </label>
                                    <div className='flex gap-5   '>
                                       <div className='flex align-items-center'>
                                          <RadioButton
                                             inputId='hyperType'
                                             name='hyperType'
                                             value='2'
                                             onChange={(e) => handleChangeType(e)}
                                             checked={selectedType === '2'}
                                          />
                                          <label htmlFor='ingredientSimple' className='ml-2'>
                                             Category
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              </Col>
                              {selectedType === '1' ? (
                                 <Col md='4 mb-4'>
                                    <FormDataFeild
                                       label={Product}
                                       name='productId'
                                       id='productId'
                                       placeholder={Product}
                                       value={formData.productId}
                                       handleChange={handleChangeBannerSlider}
                                       dropdown={true}
                                       option={productDrop?.payload?.data?.map((elem) => {
                                          return {
                                             code: elem?.productId,
                                             name: elem?.productName
                                          };
                                       })}
                                       search={(e) =>
                                          setFilterDrop((prev) => {
                                             return {
                                                ...prev,
                                                productFilter: e.filter
                                             };
                                          })
                                       }
                                    />
                                    {errors.productId && <span className='error-validation'>{errors.productId}</span>}
                                 </Col>
                              ) : selectedType === '2' ? (
                                 <Col md='4 mb-4'>
                                    <FormDataFeild
                                       label={Category_Name}
                                       name='catId'
                                       id='catId'
                                       placeholder={Category_Name}
                                       value={formData.catId}
                                       handleChange={handleChangeBannerSlider}
                                       dropdown={true}
                                       option={categoryDrop?.payload?.data?.map((elem) => {
                                          return {
                                             code: elem?.catId,
                                             name: elem?.name
                                          };
                                       })}
                                       search={(e) =>
                                          setFilterDrop((prev) => {
                                             return {
                                                ...prev,
                                                categoryFilter: e.filter
                                             };
                                          })
                                       }
                                    />
                                    {errors.catId && <span className='error-validation'>{errors.catId}</span>}
                                 </Col>
                              ) : null}
                              <Col md='8 mb-4'>
                                 <div className='flex flex-column gap-2 padding'>
                                    <label
                                       htmlFor='username'
                                       className='font-medium text-900'
                                       style={{ fontSize: '14px' }}>
                                       {START_END_DATE}
                                    </label>
                                    <Calendar
                                       value={formData.startDate}
                                       onChange={(e) => handleChangeBannerSlider(e)}
                                       selectionMode='range'
                                       name='startDate'
                                       readOnlyInput
                                       touchUI
                                       placeholder={START_END_DATE}
                                       dateFormat='dd/mm/yy'
                                       style={{ height: '44px' }}
                                    />
                                 </div>
                              </Col>
                              <Col md='12 mb-4'>
                                 <FormDataFeildTextArea
                                    label={Caption}
                                    name='caption'
                                    id='caption'
                                    type={TYPE_FELDS.text}
                                    placeholder={Caption}
                                    value={formData.caption}
                                    handleChange={handleChangeBannerSlider}
                                 />
                              </Col>
                           </Row>
                           {formData.type && (
                              <Row
                                 className='mb-5 justify-content-center '
                                 style={{
                                    background: '#00000008',
                                    padding: '14px 14px 14px 1px'
                                 }}>
                                 <Col md='3'>
                                    <div
                                       className='aj-uploader flex flex-wrap justify-content-center  align-items-center'
                                       style={{ cursor: 'pointer' }}>
                                       <input
                                          className='custom-file-input-product'
                                          id='file'
                                          name='file'
                                          type='file'
                                          onChange={(e) => openImageUploadModal(e)}
                                       />
                                       <div style={{ position: 'relative', top: '-70px' }}>
                                          {!formData.image && <UploadCloud />}
                                       </div>{' '}
                                       {formData.image && (
                                          <img
                                             src={formData?.image ? formData?.image : ''}
                                             alt=''
                                             style={{
                                                width: '80%',
                                                height: '98%',
                                                objectFit: 'contain',
                                                position: 'relative',
                                                top: '-105px'
                                             }}
                                          />
                                       )}
                                    </div>
                                    {errors.image && <span className='error-validation'>{errors.image}</span>}
                                 </Col>
                              </Row>
                           )}

                           <Row>
                              <Col md='12 mb-4' className='btn-style flex justify-content-between '>
                                 <Button
                                    label={Close}
                                    severity='danger'
                                    icon='pi pi-times'
                                    onClick={() => redirectPrevious()}
                                 />
                                 <Button
                                    label={Submit}
                                    icon='pi pi-check'
                                    loading={createBannerSliderLoader || updateBannerSliderLoader}
                                    type={TYPE_FELDS.submit}
                                 />
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </div>
         <CropImagePopup
            dialogModal={dialogModal}
            setdialogsModal={setdialogsModal}
            image={formData?.image}
            aspect={aspect}
            crop={crop}
            setCrop={setCrop}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
            scale={scale}
            rotate={rotate}
            setFormData={setFormData}
            uploadImage={uploadImage}
         />
      </Fragment>
   );
};

export default BannerSliderCreateFormPopup;

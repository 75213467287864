import React, { Fragment, useContext, useState } from 'react';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useMutation, useQuery } from 'react-query';
import { IsLoadingResponse, ToastMessage } from '../../../../utils/Helper';
import {
   Submit,
   TYPE_FELDS,
   ORDER,
   ATTRIBUTE_TYPE,
   ATTRIBUTE_TYPE_DROP,
   ATTRIBUTE_MASTER,
   Value
} from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { createAttributeSchema } from '../../../../utils/validationSchema';
import AttributeContext from '../../../../context/AttributeContext/AttributeContext';
import { Create_Attribute, Put_Update_Attribute } from '../../../../Apis/attributeApi/AttributeApi';
import { get_Attribute_Master } from '../../../../Apis/attributeMasterApi/AttributeMasterApi';

const AttributeCreateFormPopup = (props) => {
   const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } = useContext(AttributeContext);
   const { refetch } = props;
   const [filterDrop, setFilterDrop] = useState({
      attributeMasterFilter: ''
   });

   /**
    *
    * @param {event } e
    * create attribute & update attribute
    */

   const { mutate: createAttribute, isLoading: createAttributeLoader } = useMutation(
      (formData) => Create_Attribute(formData),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               refetch();
               setdialogs(false);
               setFormData({
                  value: '',
                  attributeType: null,
                  attributeMasterId: null,
                  order: ''
               });
               setErrors({});
               ToastMessage(response.status, response.data.message);
            }
         }
      }
   );

   const { mutate: updateAttribute, isLoading: updateAttributeLoader } = useMutation(
      (formData) => Put_Update_Attribute(formData, id),
      {
         onSuccess: (response) => {
            refetch();
            setdialogs(false);
            setFormData({
               value: '',
               attributeType: null,
               attributeMasterId: null,
               order: ''
            });
            setErrors({});
            ToastMessage(response.status, response.data.message);
         }
      }
   );

   const handleAttributeCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createAttributeSchema();
         await schema.validate(formData, { abortEarly: false });
         if (id !== -1 && isEdit === false) {
            let newData = {
               ...formData,
               attributeType: formData?.attributeType ? formData?.attributeType?.code : null,
               attributeMasterId: formData?.attributeMasterId ? formData?.attributeMasterId?.code : null
            };
            updateAttribute(newData);
         } else {
            let newData = {
               ...formData,
               attributeType: formData?.attributeType ? formData?.attributeType?.code : null,
               attributeMasterId: formData?.attributeMasterId ? formData?.attributeMasterId?.code : null
            };
            createAttribute(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);

         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeAttributeMaster = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => {
         return {
            ...prev,
            [name]: value
         };
      });
   };

   let parmaObj = {
      showAll: true,
      search: { name: filterDrop.attributeMasterFilter }
   };

   let { data } = useQuery(['getAttributeMasterDrop', parmaObj], async () => await get_Attribute_Master(parmaObj), {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3
   });

   return (
      <Fragment>
         <IsLoadingResponse isLoading={createAttributeLoader || updateAttributeLoader} />
         <Row>
            <Col sm='12'>
               <Card>
                  <CardBody>
                     <Form className='needs-validation' onSubmit={(e) => handleAttributeCreateAndUpdate(e)}>
                        <Row>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={Value}
                                 name='value'
                                 id='value'
                                 type={TYPE_FELDS.text}
                                 placeholder={Value}
                                 value={formData.value}
                                 handleChange={handleChangeAttributeMaster}
                              />
                              {errors.value && <span className='error-validation'>{errors.value}</span>}
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={ATTRIBUTE_TYPE}
                                 name='attributeType'
                                 id='attributeType'
                                 placeholder={ATTRIBUTE_TYPE}
                                 value={formData.attributeType}
                                 handleChange={handleChangeAttributeMaster}
                                 dropdown={true}
                                 option={ATTRIBUTE_TYPE_DROP}
                                 filter={false}
                              />
                              {errors.attributeType && <span className='error-validation'>{errors.attributeType}</span>}
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={ATTRIBUTE_MASTER}
                                 name='attributeMasterId'
                                 id='attributeMasterId'
                                 placeholder={ATTRIBUTE_MASTER}
                                 value={formData.attributeMasterId}
                                 handleChange={handleChangeAttributeMaster}
                                 dropdown={true}
                                 option={data?.payload?.data?.map((elem) => {
                                    return {
                                       name: elem?.name,
                                       code: elem?.attributeMasterId
                                    };
                                 })}
                                 search={(e) => {
                                    setFilterDrop((prev) => {
                                       return {
                                          ...prev,
                                          attributeMasterFilter: e.filter
                                       };
                                    });
                                 }}
                              />
                              {errors.attributeMasterId && (
                                 <span className='error-validation'>{errors.attributeMasterId}</span>
                              )}
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={ORDER}
                                 name='order'
                                 id='order'
                                 type={TYPE_FELDS.number}
                                 placeholder={ORDER}
                                 value={formData.order}
                                 handleChange={handleChangeAttributeMaster}
                              />
                              {errors.order && <span className='error-validation'>{errors.order}</span>}
                           </Col>
                        </Row>
                        <Row>
                           <Col md='12 mb-4' className='btn-style'>
                              <Button
                                 label={Submit}
                                 icon='pi pi-check'
                                 loading={createAttributeLoader || updateAttributeLoader}
                                 type={TYPE_FELDS.submit}
                              />
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      </Fragment>
   );
};

export default AttributeCreateFormPopup;

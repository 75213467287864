import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React, { Fragment, useContext, useState } from 'react';
import { DISCOUNT_TYPE, REPAIR_ORDER_STATUS_DROPDOWN } from '../../../../constants';
import { useMutation } from 'react-query';
import { IsLoadingResponse } from '../../../../utils/Helper';
import DataTabel from '../../../../reuseable/DataTabel';
import { TABEL_UNIQUE_ID } from '../../../../constants/RoutingConstants/RoutingConstants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { REDIRECT_PATH } from '../../../../Routes';
import { Get_Details_Deal_Of_The_Day } from '../../../../Apis/dealOfTheDayApi/DealOfTheDayApi';
import RepairingContext from '../../../../context/RepairingContext/RepairingContext';
import { Eye, Printer } from 'react-feather';
import { getDetailsRepairOrderPrint, updateRepairOrder } from '../../../../Apis/repairingApi/RepairingApi';
import { repairingPrint } from './RepairingPrint';
import moment from 'moment';
import ReceptModal from './ReceptModal';
import { Dropdown } from 'primereact/dropdown';

const RepairingTableContainer = (props) => {
   const {
      filter,
      setFilter,
      record,
      pageSize,
      page,
      setPageSize,
      setPage,
      setFormData,
      setId,
      setIsEdit,
      setSortBy,
      setSortOrder,
      sortOrder,
      setAspect,
      setFileType
   } = useContext(RepairingContext);
   const { isLoading, data, refetch } = props;
   const history = useNavigate();
   const [searchParams, setSearchParams] = useSearchParams();
   const [repairingOrderDetails, setRepairingOrderDetails] = useState({});

   /**
    * get repairing Details
    */
   const { mutate: dealOfTheDayDetails, isLoading: isLoadingDealOfTheDayDetails } = useMutation((repairOrderId) =>
      Get_Details_Deal_Of_The_Day(repairOrderId, history, setFormData, setAspect, setFileType)
   );
   const { mutate: repairingForPrint, isLoading: repairingLoader } = useMutation(
      (repairOrderId) => getDetailsRepairOrderPrint(repairOrderId),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               const payload = response.data.payload.data;
               payload.date = moment(payload.createdAt).format('DD MMM, YYYY');
               payload.time = moment(payload.createdAt).format('hh:mm A');
               document.getElementById('donationReceipt').innerHTML = `${repairingPrint(payload)}`;
               window.print();
            }
         }
      }
   );

   const { mutate: repairingForModal, isLoading: repairingModalLoader } = useMutation(
      (repairOrderId) => getDetailsRepairOrderPrint(repairOrderId),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               const payload = response.data.payload.data;
               payload.date = moment(payload.createdAt).format('DD MMM, YYYY');
               payload.time = moment(payload.createdAt).format('hh:mm A');
               setSearchParams({ openModal: true });
               setRepairingOrderDetails(payload);
               document.getElementById('receptModal').innerHTML = `${repairingPrint(payload)}`;
            }
         }
      }
   );

   /**
    *
    * @param {Sorting obj} col
    * Table Sorting
    */

   const sorting = (col) => {
      if (sortOrder === '') {
         let found = col.find((obj) => {
            return obj.field;
         });
         setSortBy(found.field);
         setSortOrder('ASC');
      }
      if (sortOrder === 'ASC') {
         let found = col.find((obj) => {
            return obj.field;
         });
         setSortBy(found.field);
         setSortOrder('DSC');
      }
      if (sortOrder === 'DSC') {
         setSortBy();
         setSortOrder('');
      }
   };

   const handleOpenandClosePopup = () => {
      history(`${REDIRECT_PATH.REPAIRING}/add`);
   };

   const { mutate: updateRepairingOrder, isLoading: updateRepairingLoader } = useMutation(
      (formData) => updateRepairOrder(formData, formData.repairingId, null, null),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               refetch();
            }
         }
      }
   );

   const defaultColumns = [
      {
         flex: 0.05,
         minWidth: 80,
         field: 'index',
         headerName: '#',
         sortable: false,
         renderCell: (params) => <div>{params.row.index}</div>
      },
      {
         flex: 0.1,
         minWidth: 180,
         field: 'customer',
         headerName: 'Customer',
         sortable: true,
         renderCell: (params) => params.row.customer.name
      },
      {
         flex: 0.1,
         minWidth: 180,
         field: 'postCode',
         headerName: 'Post Code',
         sortable: true,
         renderCell: (params) => params.row.customer?.defaultAddress?.pincode
      },
      {
         flex: 0.1,
         minWidth: 180,
         field: 'productType',
         headerName: 'Product Type',
         sortable: true
      },
      {
         flex: 0.1,
         minWidth: 180,
         field: 'depositPaid',
         headerName: 'Deposit Paid',
         sortable: true
      },
      {
         flex: 0.1,
         minWidth: 180,
         field: 'estimateCost',
         headerName: 'Estimate Cost',
         sortable: true
      },
      {
         flex: 0.1,
         minWidth: 180,
         field: 'createdAt',
         headerName: 'Date Time',
         sortable: true,
         renderCell: (params) => moment(params.row.createdAt).format('DD MMM, YYYY hh:mm A')
      },
      {
         flex: 0.1,
         minWidth: 180,
         field: 'status',
         headerName: 'Status',
         sortable: true,
         renderCell: (params) => (
            <Dropdown
               value={params.row.status}
               onChange={(e) => updateRepairingOrder({ repairingId: params.row.repairOrderId, status: e.target.value })}
               options={REPAIR_ORDER_STATUS_DROPDOWN}
               optionLabel='name'
               placeholder='Select a City'
               className='w-full md:w-14rem'
            />
         )
      },
      {
         flex: 0.1,
         minWidth: 100,
         field: 'actions',
         headerName: 'Actions',
         sortable: false,

         renderCell: (params) => (
            <>
               <div style={{ paddingRight: '5px' }}>
                  <Tooltip title='Edit'>
                     <IconButton
                        aria-label='edit'
                        onClick={() => history(REDIRECT_PATH.REPAIRING + `/${params.row.repairOrderId}`)}>
                        <Edit style={{ color: '#7366ff' }} />
                     </IconButton>
                  </Tooltip>
               </div>
               <div style={{ paddingRight: '5px' }}>
                  <Tooltip title='Print'>
                     <IconButton aria-label='edit' onClick={() => repairingForPrint(params.row.repairOrderId)}>
                        <Printer style={{ color: '#F16647' }} />
                     </IconButton>
                  </Tooltip>
               </div>
               <div style={{ paddingRight: '5px' }}>
                  <Tooltip title='View'>
                     <IconButton aria-label='edit' onClick={() => repairingForModal(params.row.repairOrderId)}>
                        <Eye style={{ color: '#7366ff' }} />
                     </IconButton>
                  </Tooltip>
               </div>
            </>
         )
      }
   ];
   return (
      <Fragment>
         <div id='donationReceipt'></div>
         <IsLoadingResponse
            isLoading={isLoadingDealOfTheDayDetails || repairingLoader || repairingModalLoader || updateRepairingLoader}
         />
         <DataTabel
            filter={filter}
            setFilter={setFilter}
            handleOpenandClosePopup={handleOpenandClosePopup}
            data={data}
            defaultColumns={defaultColumns}
            record={record}
            pageSize={pageSize}
            page={page}
            setPageSize={setPageSize}
            setPage={setPage}
            sorting={sorting}
            isLoading={isLoading}
            sortOrder={sortOrder}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            id={TABEL_UNIQUE_ID.repairOrderId}
            addButton={true}
            options={DISCOUNT_TYPE}
         />
         {searchParams.get('openModal') === 'true' && (
            <ReceptModal
               open={searchParams.get('openModal') === 'true'}
               searchParams={searchParams}
               setSearchParams={setSearchParams}
               repairingOrderDetails={repairingOrderDetails}
            />
         )}
      </Fragment>
   );
};

export default RepairingTableContainer;

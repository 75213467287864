import React, { Fragment, useContext, useState } from 'react';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useMutation, useQuery } from 'react-query';
import { IsLoadingResponse } from '../../../../utils/Helper';
import { Submit, TYPE_FELDS, Store_, SlotName, ORDER, START_Time, END_Time } from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { createDeliverySlotSchema } from '../../../../utils/validationSchema';
import { Create_Delivery_Slot, Put_Update_Delivery_Slot } from '../../../../Apis/deliverySlotApi/DeliverySlotApi';
import { get_Store } from '../../../../Apis/storeApi/StoreApi';
import DeliverySlotContext from '../../../../context/DeliverySlotContext/DeliverySlotContext';
import moment from 'moment';

const DeliverySlotCreateFormPopup = (props) => {
   const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } = useContext(DeliverySlotContext);
   const { refetch, emptyFormData } = props;
   const [filterDrop, setFilterDrop] = useState({
      storeFilter: ''
   });

   /**
    *
    * @param {event } e
    * create delivery slot & update delivery slot
    */

   const { mutate: createDeliverySlot, isLoading: createDeliverySlotLoader } = useMutation((formData) =>
      Create_Delivery_Slot(formData, refetch, setdialogs, emptyFormData)
   );

   const { mutate: updateDeliverySlot, isLoading: updateDeliverySlotLoader } = useMutation((formData) =>
      Put_Update_Delivery_Slot(formData, id, refetch, setdialogs, emptyFormData)
   );

   const handleDeliverySlotCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createDeliverySlotSchema();
         await schema.validate(formData, { abortEarly: false });
         if (id !== -1 && isEdit === false) {
            let newData = {
               ...formData,
               storeId: formData?.storeId?.code || '',
               startTime: moment(formData?.startTime).format('HH:mm:ss') || '',
               endTime: moment(formData?.endTime).format('HH:mm:ss') || ''
            };
            updateDeliverySlot(newData);
         } else {
            let newData = {
               ...formData,
               storeId: formData?.storeId?.code || '',
               startTime: moment(formData?.startTime).format('HH:mm:ss') || '',
               endTime: moment(formData?.endTime).format('HH:mm:ss') || ''
            };
            createDeliverySlot(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);
         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeDeliverySlot = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => {
         return {
            ...prev,
            [name]: value
         };
      });
   };

   let paramsObj = {
      showAll: true,
      search: { name: filterDrop.storeFilter }
   };
   let { data: storeDrop } = useQuery(['getStoreDropdown', paramsObj], async () => await get_Store(paramsObj), {
      keepPreviousData: true,
      refetchOnWindowFocus: false
   });

   return (
      <Fragment>
         <IsLoadingResponse isLoading={createDeliverySlotLoader || updateDeliverySlotLoader} />
         <Row>
            <Col sm='12'>
               <Card>
                  <CardBody>
                     <Form className='needs-validation' onSubmit={(e) => handleDeliverySlotCreateAndUpdate(e)}>
                        <Row>
                           <Col md='4 mb-4'>
                              <FormDataFeild
                                 label={Store_}
                                 name='storeId'
                                 id='storeId'
                                 placeholder={Store_}
                                 value={formData.storeId}
                                 handleChange={handleChangeDeliverySlot}
                                 option={storeDrop?.payload?.data?.map((elem) => {
                                    return {
                                       name: elem?.name,
                                       code: elem?.storeId
                                    };
                                 })}
                                 dropdown={true}
                                 search={(e) => {
                                    setFilterDrop((prev) => {
                                       return {
                                          ...prev,
                                          storeFilter: e.filter
                                       };
                                    });
                                 }}
                              />
                              {errors.storeId && <span className='error-validation'>{errors.storeId}</span>}
                           </Col>
                           <Col md='4 mb-4'>
                              <FormDataFeild
                                 label={SlotName}
                                 name='slotName'
                                 id='slotName'
                                 type={TYPE_FELDS.text}
                                 placeholder={SlotName}
                                 value={formData.slotName}
                                 handleChange={handleChangeDeliverySlot}
                              />
                              {errors.slotName && <span className='error-validation'>{errors.slotName}</span>}
                           </Col>
                           <Col md='4 mb-4'>
                              <FormDataFeild
                                 label={ORDER}
                                 name='order'
                                 id='order'
                                 type={TYPE_FELDS.number}
                                 placeholder={ORDER}
                                 value={formData.order}
                                 handleChange={handleChangeDeliverySlot}
                              />
                              {errors.order && <span className='error-validation'>{errors.order}</span>}
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={START_Time}
                                 name='startTime'
                                 id='startTime'
                                 placeholder={START_Time}
                                 value={formData.startTime}
                                 handleChange={handleChangeDeliverySlot}
                                 time={true}
                              />
                              {errors.startTime && <span className='error-validation'>{errors.startTime}</span>}
                           </Col>
                           <Col md='6 mb-4'>
                              <FormDataFeild
                                 label={END_Time}
                                 name='endTime'
                                 id='endTime'
                                 placeholder={END_Time}
                                 value={formData.endTime}
                                 handleChange={handleChangeDeliverySlot}
                                 time={true}
                              />
                              {errors.endTime && <span className='error-validation'>{errors.endTime}</span>}
                           </Col>
                        </Row>
                        <Row>
                           <Col md='12 mb-4' className='btn-style'>
                              <Button
                                 label={Submit}
                                 icon='pi pi-check'
                                 loading={createDeliverySlotLoader || updateDeliverySlotLoader}
                                 type={TYPE_FELDS.submit}
                              />
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      </Fragment>
   );
};

export default DeliverySlotCreateFormPopup;
